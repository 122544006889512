import './JiraTicketTable.scss';
import React from 'react';
import { DynamicTable } from '../DynamicTable/DynamicTable';
import { JiraIssue } from './types';


export type JiraTicketTableProps = { 
  releaseName?: string;
  title?: string;
  ticketKey?: string;
}

export function JiraTicketTable(props: JiraTicketTableProps) {
 
  const releaseNameEncoded = props.releaseName ? encodeURIComponent(props.releaseName) : null;
  const titleEncoded = props.title ? encodeURIComponent(props.title) : null;
  const ticketKeyEncoded = props.ticketKey ? encodeURIComponent(props.ticketKey) : null;

  const url = releaseNameEncoded != null ? `api/jira/tickets/release/${releaseNameEncoded}`
    : titleEncoded != null ? `api/jira/tickets/title?search=${titleEncoded}`
    : ticketKeyEncoded != null ? `api/jira/tickets/key?search=${ticketKeyEncoded}`
    : null;


  const onColumnClick = (row: JiraIssue, columnIndex: number) => {
    if(columnIndex !== 0) { 
      return;
    }
    window.open(`https://goformz.atlassian.net/browse/${row.key}`, '_blank');
  }

  return (
    <div className="jira-ticket-table">
      {url && <DynamicTable header={'Jira Tickets'} pageSize={50} url={url} hyperLinkColumns={[0]} onColumnClick={onColumnClick} /> }
    </div>
  );
}