import React, { useLayoutEffect, useRef } from 'react';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import './FeatureFlagStats.scss';
import { FeatureFlag, FeatureFlagAnalyticsByDay, FeatureFlagExperiment } from './types';
import Chart from 'chart.js';

interface FeatureFlagStatsProps { 
  featureFlag: FeatureFlag;
  featureFlagExperiments: FeatureFlagExperiment[];
}
export function FeatureFlagStats(props: FeatureFlagStatsProps) {
  const { featureFlag, featureFlagExperiments }  = props;
  const [analytics, _analyticsLoading, analyticsLoaded] 
    = useApi<FeatureFlagAnalyticsByDay>(`api/featureflags/${featureFlag?.featureFlagId}/analytics/bydate`, featureFlag == null);


  const featureFlagExperimentQuantityByDayCanvasEl = useRef(null);
  const featureFlagExperimentQuantityByDayChart = useRef(null);

  const featureFlagOverridesQuantityByDayCanvasEl = useRef(null);
  const featureFlagOverridesQuantityByDayChart = useRef(null);

  const unique = (value, index, array) => array.indexOf(value) === index;

  const updateFeatureFlagOverridesQuantityByDayChart = () => { 
    const featureFlagOverridesQuantityByDay = [...(analytics?.featureFlagOverridesQuantityByDay || [])].map(value => ({
      ...value,
      epochDate: new Date(`${value.date}Z`).toTimeString(),
      date: new Date(`${value.date}Z`).toDateString(),
    }));
    const featureValues = featureFlagOverridesQuantityByDay.map(override => override?.value).filter(unique);
    const labels = featureFlagOverridesQuantityByDay.sort((a, b) => (a.epochDate > b.epochDate) ? -1 : 1)
      .map(value => value.date).filter(unique);

    const getDataByFeatureValue = (value) => labels.map(date => 
        featureFlagOverridesQuantityByDay.find(override => override.date === date && override.value === value)?.quantity ?? 0);
    

    featureFlagOverridesQuantityByDayChart.current = new Chart(featureFlagOverridesQuantityByDayCanvasEl.current, {
      type: 'bar',
      options: {
        interaction: {
          intersect: false,
        },
        scales: {
          x: { 
            stacked: true,
          }, 
          y: { 
            stacked: true
          }
        },
        responsive: true
      },
      data: { 
        labels: labels,
        datasets: featureValues.map((fv) => ({
          label: fv  == null ? 'null' : fv.toString(),
          data: getDataByFeatureValue(fv),
          stack: 'Stack',
        }))
      }
    });
  
    featureFlagOverridesQuantityByDayChart.current.update();
  }

  const updateFeatureFlagExperimentQuantityByDayChart = () => { 
    const featureFlagExperimentQuantityByDay = [...(analytics?.featureFlagExperimentQuantityByDay || [])].map(value => ({
      ...value,
      epochDate: new Date(`${value.date}Z`).toTimeString(),
      date: new Date(`${value.date}Z`).toDateString(),
    }));
    const experimentNames = featureFlagExperimentQuantityByDay
      .map(experiment => featureFlagExperiments.find(ffe => ffe.featureFlagExperimentId === experiment?.featureFlagExperimentId)?.experimentName)
      .filter(unique);

    const labels = featureFlagExperimentQuantityByDay.sort((a, b) => (a.epochDate > b.epochDate) ? -1 : 1)
      .map(experiment => experiment?.date).filter(unique);

    const getDataByExperimentName = (name) => labels
      .map(date => featureFlagExperimentQuantityByDay.find(experiment => experiment.date === date 
          && featureFlagExperiments.find(ffe => ffe.featureFlagExperimentId === experiment?.featureFlagExperimentId)?.experimentName === name)?.quantity ?? 0);
    

    featureFlagExperimentQuantityByDayChart.current = new Chart(featureFlagExperimentQuantityByDayCanvasEl.current, {
      type: 'bar',
      options: {
        interaction: {
          intersect: false,
        },
        scales: {
          x: { 
            stacked: true,
          }, 
          y: { 
            stacked: true
          }
        },
        responsive: true
      },
      data: { 
        labels: labels,
        datasets: experimentNames.map(name => ({
          label: name,
          data: getDataByExperimentName(name),
          stack: name,
        }))
      }
    });
  
    featureFlagOverridesQuantityByDayChart.current.update();
  }

  useLayoutEffect(() => {
    updateFeatureFlagOverridesQuantityByDayChart();
    updateFeatureFlagExperimentQuantityByDayChart();
  }, [analyticsLoaded]);
  

  const loading = !analyticsLoaded;
  
  return (
    <div className="feature-flag-stats">
      <Loader isLoading={loading} />
      <div className="feature-chart chart">
        <div className="title">Feature Flag Overrides By Day</div>
        <canvas key="canvas" ref={featureFlagOverridesQuantityByDayCanvasEl}></canvas>
      </div>
      <div className="feature-chart chart">
        <div className="title">Feature Flag Experiment Participants By Day</div>
        <canvas key="canvas" ref={featureFlagExperimentQuantityByDayCanvasEl}></canvas>
      </div>
    </div>
  );
}