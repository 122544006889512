import { useApim } from '../../../../../hooks/useApi';
import { OnboardingState, OnboardingMetadata } from '../../uses-types';

export function useOnboardingStatus(clientId: string, userId: string) {
  const [stateResponse, stateLoading] = useApim<OnboardingState>('/profile/onboarding', clientId, userId, false, 'GET');
  const [stepsResponse, stepsLoading] = useApim<OnboardingMetadata>('/profile/onboardingsteps', clientId, userId, false, 'GET');
  const loading = stateLoading || stepsLoading;

  return { loading, steps: stepsResponse?.data, state: stateResponse };
}
