import React, {useState } from 'react';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import './FeatureFlagOverrides.scss';
import { FeatureFlag, FeatureFlagDataType } from './types';
import { DynamicTable } from '../DynamicTable/DynamicTable';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown } from 'semantic-ui-react';
import { apiCall } from '../../utility/api';
import ConfirmationModal from '../ConfirmModal/ConfirmationModal';
import { FeatureFlagValue } from './FeatureFlagValue';
import { getDefaultValueFromFeatureObject, setFeatureFlagValueByType } from './utilities';

interface FeatureFlagOverridesProps { 
  featureFlag: FeatureFlag;
  onFeatureOverridesUpdated: () => void;
}
export function FeatureFlagOverrides(props: FeatureFlagOverridesProps) {
  const { featureFlag }  = props;
  const [isApiBeingCalled, setIsApiBeingCalled] = useState(false);
  const [isConfirmDeleteOverridesModalOpen, setIsConfirmDeleteOverridesModalOpen] = useState(false);
  const [isCreateOverridesForInternalModalOpen, setIsCreateOverridesForInternalModalOpen] = useState(false);
  const [skipQAPromoCodes, setSkipQAPromoCodes] = useState(true);
  const [featureFlagOverideForInternalAccounts, setFeatureFlagOverideForInternalAccounts] = useState(getDefaultValueFromFeatureObject(featureFlag, featureFlag.dataType));
  const navigate = useNavigate();
  const [featureFlagOverrides, _featureFlagOverridesLoading, featureFlagOverridesLoaded, refreshFeatureFlagOverrides] 
    = useApi(`/api/featureflags/${featureFlag.featureFlagId}/overrides/clients`);

  const onClientOverrideRowClick = (row) => { 
    navigate(`/account/${row.clientId}`);
  }

  
  const featureFlagType = props.featureFlag?.dataType;
  const featureFlagOverridesSanitized = (featureFlagOverrides as any[] || []).map(override => {
    const sanitizedValue = { ...override };
    delete sanitizedValue['dataType'];
    if(featureFlagType !== FeatureFlagDataType.BoolValue) {
      delete sanitizedValue.boolValue;
    }
    if(featureFlagType !== FeatureFlagDataType.NumberValue) {
      delete sanitizedValue.numberValue;
    }
    if(featureFlagType !== FeatureFlagDataType.DecimalValue) {
      delete sanitizedValue.decimalValue;
    }
    if(featureFlagType !== FeatureFlagDataType.JsonValue) {
      delete sanitizedValue.jsonValue;
    }
    if(featureFlagType !== FeatureFlagDataType.StringValue) {
      delete sanitizedValue.stringValue;
    }
    return sanitizedValue;
  });


  const onDeleteOverridesClick = async () => { 
    setIsConfirmDeleteOverridesModalOpen(true);
  }

  const onDeleteOverridesConfirmClick = async () => { 
    setIsConfirmDeleteOverridesModalOpen(false);
    setIsApiBeingCalled(true);
    await apiCall(`/api/featureflags/${featureFlag.featureFlagId}/overrides`, 'DELETE');
    
    setIsApiBeingCalled(false);
    refreshFeatureFlagOverrides();
    props.onFeatureOverridesUpdated?.();
  }

  const onCreateOverridesForInternalClick = async () => { 
    setIsCreateOverridesForInternalModalOpen(true);
    
  }

  const onCreateOverridesForInternalConfirmClick = async () => { 
    setIsCreateOverridesForInternalModalOpen(false);
    setIsApiBeingCalled(true);
    const requestBody = {
      featureFlagId: featureFlag.featureFlagId,
      updateInternalAccounts: true,
      skipQAPromoCodes: skipQAPromoCodes
    }
    setFeatureFlagValueByType(featureFlag, featureFlagOverideForInternalAccounts, (key, value) => { requestBody[key] = value });
    await apiCall(`/api/featureflags/${featureFlag.featureFlagId}/overrides/bulk`, 'POST',requestBody);
    setIsApiBeingCalled(false);
    refreshFeatureFlagOverrides();
    props.onFeatureOverridesUpdated?.();
  }

  const loading = !featureFlagOverridesLoaded || isApiBeingCalled;
  
  return (
    <div className="feature-flag-overrides">
      <Loader isLoading={loading} />
      <div className="feature-overrides-clients">
        {featureFlagOverridesLoaded && <DynamicTable
            header='Clients With Overrides'
            subHeader='*View is limited to latest 5000 clients. Click on a row to view client details.'
            data={featureFlagOverridesSanitized}
            hyperLinkColumns={[2, 3]}
            onColumnClick={onClientOverrideRowClick} />
        }
         <div className='form-buttons'> 
            {featureFlagOverridesSanitized.length > 0 && <Button className="tertiary" negative onClick={onDeleteOverridesClick}>Delete All Non Promo Code Overrides</Button>}
            <Button className="primary" onClick={onCreateOverridesForInternalClick}>Create Overrides For Internal Accounts </Button>
          </div>
      </div>
      <ConfirmationModal 
        isOpen={isConfirmDeleteOverridesModalOpen} 
        headerText='Confirm Delete Overrides'
        confirmationText='Are you sure you want to delete all overrides except for ones created from promo codes?' 
        closeModal={() => setIsConfirmDeleteOverridesModalOpen(false)} 
        onConfirm={onDeleteOverridesConfirmClick} />

      <ConfirmationModal 
        isOpen={isCreateOverridesForInternalModalOpen} 
        headerText='Confirm Override Creation'
        body={
          <div className='feature-flag-overrides-for-internal'>
            <div className='override-value'>
              <h4>Override Value:</h4>
              <FeatureFlagValue dataType={featureFlag.dataType} value={featureFlagOverideForInternalAccounts} onChange={setFeatureFlagOverideForInternalAccounts} disabled={false}/>
            </div>

            <div className='skip-qa'>
              <Checkbox className='' label='Skip QA Promo Codes' checked={skipQAPromoCodes} onChange={(_e, data) => setSkipQAPromoCodes(data.checked)} />
            </div>
            
          </div>
        }
        closeModal={() => setIsCreateOverridesForInternalModalOpen(false)} 
        onConfirm={onCreateOverridesForInternalConfirmClick} />
    </div>
  );
}