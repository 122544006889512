export class RecentSearchStore {
  private readonly storageKey = 'gf-tools-recent-searches';
  private readonly maxRecentSearches = 20;

  private recentSearches = new Set<string>();

  constructor(private storage: Storage) {
    this.initialize();
  }

  /**
   * Gets all of the recent searches in descending order.
   * @param ascending True to show recent terms last, false otherwise.
   */
  get(ascending = false) {
    const searches = Array.from(this.recentSearches).slice(0, this.maxRecentSearches);

    return ascending ? searches : searches.reverse();
  }

  /**
   * Saves the recent search term.
   * @param term The search term to save.
   */
  save(term: string) {
    // append the new term to the set
    this.recentSearches.add(term);
    // persist to storage in ascending order
    this.storage.setItem(this.storageKey, JSON.stringify(this.get(true)));
  }

  private initialize() {
    // should be an array of recent search terms in a json string
    const rawSearches = this.storage.getItem(this.storageKey);

    if (!rawSearches) {
      return;
    }

    try {
      const searches: string[] = JSON.parse(rawSearches);

      // check to make sure the searches are in an array format
      if (!Array.isArray(searches)) {
        this.storage.removeItem(this.storageKey);

        return;
      }

      // trim and remove any empty search terms
      const trimmed = searches.map((term) => term.trim()).filter((term) => !!term);

      this.recentSearches = new Set(trimmed.slice(0, this.maxRecentSearches));
    } catch (error) {
      // remove the bad entry from storage
      this.storage.removeItem(this.storageKey);
    }
  }
}
