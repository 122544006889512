import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Search, Table } from 'semantic-ui-react';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import './FeatureFlagExperimentsList.scss';
import { FeatureFlag, FeatureFlagExperimentAnalyticsTotals, FeatureFlagExperiment } from './types';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';

interface FeatureFlagExperimentsListProps { 
  featureFlag: FeatureFlag;
  featureFlagExperiments: FeatureFlagExperiment[];
}
interface FeatureFlagExperimentSearchResults {
  id?: string; 
  title?: string;
  description?: string
}
export function FeatureFlagExperimentsList(props: FeatureFlagExperimentsListProps) {
  const { featureFlag, featureFlagExperiments } = props;
  const [featureFlagTotals, _featureFlagTotalsLoading, featureFlagTotalsLoaded] 
    = useApi<FeatureFlagExperimentAnalyticsTotals>(`api/featureflags/${featureFlag?.featureFlagId}/experiments/analytics/totals`);

  const navigate = useNavigate();
  const readOnly = !canIUse(AppFeatures.EditFeatureFlags);
  const [searchResults, setSearchResults] = useState<FeatureFlagExperimentSearchResults[]>([]);

  const handleSearchChange = React.useCallback((e, data) => {
    const escapeRegExp = (regVal :string) => {
      return (regVal ?? '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    const reg = new RegExp(escapeRegExp(data.value ??  ''), 'i')
    const isMatch = (result: FeatureFlagExperiment) => reg.test(result.experimentName || '') || reg.test(result.description || '');
  
    const searchResults = featureFlagExperiments.filter(isMatch).map<FeatureFlagExperimentSearchResults>(featureFlag => ({
      id: featureFlag.featureFlagExperimentId,
      title: `${featureFlag.experimentName}`,
      description: featureFlag.description,
    }))
    setSearchResults(searchResults);
  }, [featureFlagExperiments])

  const getFeatureFlagExperimentParticipants =  (featureFlagExperiment: FeatureFlagExperiment) => {
    const currentParticipants =  featureFlagExperiment.featureFlagExperimentId ?
      (featureFlagTotals?.totalFeatureFlagExperimentParticipantsByExperiment[featureFlagExperiment.featureFlagExperimentId] ?? 0) 
      : 0;

    return `${currentParticipants}`;
  }

  const getFeatureFlagExperimentControlGroupParticipants =  (featureFlagExperiment: FeatureFlagExperiment) => {
    const currentParticipants =  featureFlagExperiment.featureFlagExperimentId ?
      (featureFlagTotals?.totalFeatureFlagExperimentControlParticipantsByExperiment[featureFlagExperiment.featureFlagExperimentId] ?? 0) 
      : 0;

    return `${currentParticipants}`;
  }

  const getFeatureFlagExperimentCompletion =  (featureFlagExperiment: FeatureFlagExperiment) => {
    const total = featureFlagExperiment?.targetingDetails?.experimentSize ?? 0;
    if(!total) { 
      return '--';
    }

    const currentParticipants =  featureFlagExperiment.featureFlagExperimentId ?
      (featureFlagTotals?.totalFeatureFlagExperimentParticipantsByExperiment[featureFlagExperiment.featureFlagExperimentId] ?? 0) 
      : 0;

    return `${(currentParticipants/total) * 100}%`
  }

  const getFeatureFlagExperimentStatus = (featureFlagExperiment: FeatureFlagExperiment) => {
    return featureFlagExperiment.startDate == null ? 'NOT STARTED'
      : featureFlagExperiment.endDate ? new Date(`${featureFlagExperiment.endDate}Z`).toDateString() : 'ACTIVE'
  }
  const onSearchClick = (_, data) => { 
    const match = featureFlagExperiments.find(ffe => ffe.featureFlagExperimentId === data.id);
    match && navigate(`./${match.featureFlagExperimentId}`);
  }

  const onRowClick = (featureFlagExperiment: FeatureFlagExperiment) => { 
    navigate(`./${featureFlagExperiment.featureFlagExperimentId}`);
  }

  const onAddClick = () => { 
    navigate(`./add`);
  }

  const loading = !featureFlagTotalsLoaded;

  return (
    <div className="feature-flag-experiments-list">
      <Loader isLoading={loading} />
      <div className="header center">
        <Search
          placeholder='Search...'
          onResultSelect={onSearchClick}
          onSearchChange={handleSearchChange}
          results={searchResults} 
        />
      </div>
      <Table singleLine selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Completion</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Experiment Group Size</Table.HeaderCell>
                <Table.HeaderCell>Control Group Size</Table.HeaderCell>
                <Table.HeaderCell>Ended On</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
          <Table.Body>
            {(featureFlagExperiments || []).map(featureFlagExperiment => (
              <Table.Row key={featureFlagExperiment.featureFlagExperimentId} onClick={() => onRowClick(featureFlagExperiment)}>
                <Table.Cell collapsing>{getFeatureFlagExperimentCompletion(featureFlagExperiment)}</Table.Cell>
                <Table.Cell collapsing>{featureFlagExperiment.experimentName}</Table.Cell>
                <Table.Cell>{featureFlagExperiment.description}</Table.Cell>
                <Table.Cell collapsing>{getFeatureFlagExperimentParticipants(featureFlagExperiment)}</Table.Cell>
                <Table.Cell collapsing>{getFeatureFlagExperimentControlGroupParticipants(featureFlagExperiment)}</Table.Cell>
                <Table.Cell collapsing>{getFeatureFlagExperimentStatus(featureFlagExperiment)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan='6'>
                {!readOnly && <Button onClick={onAddClick} className="primary" floated='right'>
                  <Icon name='plus circle' /> Add Experiment
                </Button>}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
      </Table>
    </div>
  );
}