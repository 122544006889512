const concurrentCalls = 4;

export const PromiseStatus = {
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

export async function Parallel(promises, preCallback = null) {
  let results = [];
  for (let i = 0; i < promises.length; i += concurrentCalls) {
    if (preCallback) {
      preCallback(i, concurrentCalls);
    }
    await allSettled(promises.slice(i, i + concurrentCalls)).then((x) => {
      results = results.concat(x);
    });
  }
  return results;
}

// Promise.allSettled not supported in node v10 - this mimics it
// TODO: we're on node 14 now
function allSettled(promises) {
  const mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: PromiseStatus.fulfilled,
          value,
        };
      })
      .catch((reason) => {
        return {
          status: PromiseStatus.rejected,
          reason,
        };
      });
  });
  return Promise.all(mappedPromises);
}
