import React, { useState } from 'react';
import { Input, Modal, Dropdown, Checkbox } from 'semantic-ui-react';
import { useApi } from '../../../hooks/useApi';
import { copyTextToClipboard } from '../../../utility/clipboard';
import Loader from '../../Loader';
import './AddPublicFormsSubscriptionModal.scss';

export type AddPublicFormsSubscriptionModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};


const subscriptionPackageConfigurations = {
  '500Annual25POff': {
    coupon: 'gfaddon-public-forms-annual-500-25-off',
    quantity: 500,
    subscription: 'gfaddon-public-forms-annual'
  },
  '360Annual20POff': {
    coupon: 'gfaddon-public-forms-annual-360-20-off',
    quantity: 360 ,
    subscription: 'gfaddon-public-forms-annual'
  },
  '30Monthly0Off': {
    coupon: null,
    quantity: 30,
    subscription: 'gfaddon-public-forms-monthly'
  }
};

const subscriptionPackages = [
  { 
    key: '500Annual25POff',
    text: 'Annual - 500 credits for $375 (25% discount)',
    value: '500Annual25POff'
  },
  { 
    key: '360Annual20POff',
    text: 'Annual - 360 credits for $288 (20% discount)',
    value: '360Annual20POff'
  },
  { 
    key: '30Monthly0Off',
    text: 'Monthly - 30 credits for $30 (no discount)',
    value: '30Monthly0Off'
  }
];

const subscriptionOptions = [
  {
    key: 'gfaddon-public-forms-monthly',
    text: 'Monthly',
    value: 'gfaddon-public-forms-monthly',
  },
  {
    key: 'gfaddon-public-forms-annual',
    text: 'Annual',
    value: 'gfaddon-public-forms-annual',
  },    
]


export default function AddPublicFormsSubscriptionModal(props: AddPublicFormsSubscriptionModalProps) {
  const defaultPackage = '500Annual25POff';
  const [subscriptionPackage, setSubscriptionPackage] = useState<string>(defaultPackage);
  const [subscription, setSubscription] = useState<string>(subscriptionPackageConfigurations[defaultPackage].subscription);
  const [quantity, setQuantity] = useState(subscriptionPackageConfigurations[defaultPackage].quantity);
  const [coupon, setCoupon] = useState<string>(subscriptionPackageConfigurations[defaultPackage].coupon);
  const [showAdanced, setShowAdvanced] = useState(false);
  const [recurlySubdomain, creditsLoading] = useApi<string>('/api/account/recurly/subdomain');

  const couponQueryParam = coupon ? `&subscription[coupon_code]=${coupon}` : '';
  const link = `https://${recurlySubdomain}.recurly.com/subscribe/${subscription}?account_code=${props.id}&quantity=${quantity}${couponQueryParam}`;

  const copyClientIdToClipboard = () => {
    copyTextToClipboard(link);
  };

  const onSubscriptionPackageChange = (newSubscriptionPackage: string) => { 
    const configuration = subscriptionPackageConfigurations[newSubscriptionPackage];
    setSubscription(configuration.subscription);
    setQuantity(configuration.quantity);
    setCoupon(configuration.coupon || null);
    setSubscriptionPackage(newSubscriptionPackage);
  }

  const onQuantityChange = (value: string) => { 
    const newQuantity = Number.parseInt(value);
    setQuantity(newQuantity);
  }

  return (
    <Modal
      className='add-public-forms-subscription-modal'
      open={props.isOpen}
      dimmer="inverted"
      size="tiny"
      closeIcon={{ color: 'black', name: 'close' }}
      onClose={props.onClose}
    >
      <Modal.Header>{`Public Forms Checkout Link Generator`}</Modal.Header>
      <Modal.Content>
        <div className='pfs-body' >
          <Loader isLoading={creditsLoading} />
          <div className='pfs-field-group'>
              <label>Package</label>
              <Dropdown
              className="dropdown"
              placeholder="Select Package"
              fluid
              clearable
              selection
              options={subscriptionPackages}
              onChange={(e, d) => onSubscriptionPackageChange(d.value as string)}
              value={subscriptionPackage}
            />
          </div>

          <div className='pfs-field-group'>
              <label>Show Advanced Configuration</label>
              <Checkbox
              checked={showAdanced}
              toggle
              onChange={(e, data) => setShowAdvanced(data.checked)}/>
          </div>
         
          {showAdanced && <div className='pfs-field-group'>
              <label>Renewal Interval</label>
              <Dropdown
              className="dropdown"
              placeholder="Select Subscription"
              fluid
              clearable
              selection
              options={subscriptionOptions}
              onChange={(e, d) => setSubscription(d.value as string)}
              value={subscription}
            />
          </div>}
          {showAdanced && <div className='pfs-field-group'>
            <label>Quantity</label>
            <Input fluid value={quantity} type="number"
                onChange={(_, data) => onQuantityChange(data.value)} />
          </div>}
          {showAdanced && <div className='pfs-field-group'>
            <label>Coupon</label>
            <Input fluid placeholder='Recurly Coupon Code' value={coupon}
                onChange={(_, data) => setCoupon(data.value)} />
          </div>}
          <div className='pfs-field-group'>
            <label>Checkout Link</label>
            <div className="purchase-link" >
              <button className="ui compact mini icon button" onClick={copyClientIdToClipboard}>
                <i className="copy outline icon"></i>
              </button>
              <Input fluid value={link} />
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

