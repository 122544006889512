import Axios, { Method, AxiosRequestConfig, AxiosError } from 'axios';
import { ErrorResponse } from 'gf-types';

function getAxiosApiOptions(method: Method = 'GET', body = undefined, cancellationToken = undefined): AxiosRequestConfig {
  const options: AxiosRequestConfig = {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    data: body ? JSON.stringify(body) : '',
    method,
    cancelToken: cancellationToken,
  };

  return options;
}

export function apiCall(url: string, method: Method = 'GET', body = undefined, cancellationToken = undefined) {
  const options = getAxiosApiOptions(method, body, cancellationToken);
  return Axios(url, options);
}

export function apimCall(url: string, method: Method = 'GET', body = undefined, clientId = undefined, userId = undefined, comments = undefined) {
  const options = getAxiosApiOptions(method, body);
  if (clientId) {
    options.headers['X-Client-Id'] = clientId;
  }
  if (userId) {
    options.headers['X-User-Id'] = userId;
  }

  if (comments) {
    options.headers['X-Comment'] = comments;
  }
  return Axios(getApimUrl(url), options);
}

export function getApimUrl(url: string) {
  return `/apim?uri=${encodeURIComponent(url)}`;
}

export function getApiErrorMessage(error: AxiosError<ErrorResponse>) {
  if (!error || !error.response) {
    return 'Error calling server.';
  }

  if (error.response.status === 400) {
    if (error.response.data && error.response.data.error) {
      return error.response.data.error;
    }
    if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    return 'Invalid input';
  }
  return getMessageFromStatusCode(error.response.status);
}

export function getMessageFromStatusCode(code: number) {
  if (code === 404) {
    return 'Not found';
  }
  if (code === 500) {
    return 'Server error';
  }
  if (code === 400) {
    return 'Bad Request';
  }
  if (code === 401) {
    return 'Unuthorized';
  }
  if (code === 403) {
    return 'Forbidden';
  }
  if (code > 400) {
    return `Error Code: ${code}`;
  }
  if (code >= 200) {
    return null;
  }
  return 'Unknown error';
}
