import React, { useEffect, useState } from 'react';
import { apimCall } from '../../../utility/api';
import { Button, Table, Loader } from 'semantic-ui-react';
import CommandPermissionDropdown from './CommandPermissionDropdown';
import { AppItemPermission, PermissionType } from '../apps-types.d';

export interface PermissionsProps {
  appInfo: any;
  setAppInfo: (property: string, value: any) => void;
  onSave: () => void;
  isSaving: boolean;
}

export default function Permissions(props) {
  const [permissions, setPermissions] = useState<AppItemPermission[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.appInfo.id) {
      setLoading(true);
      apimCall(`/publishedapps?onlyActive=true&appId=${props.appInfo.id}`).then((res) => {
        const metadata = res.data.data.items[0].package.metadata;
        if (metadata) {
          const initialPermissions: AppItemPermission[] = [];
          metadata.commands?.forEach((command) => {
            const currentPermission = props.appInfo.permissions?.find((x) => x.name === command.name);
              initialPermissions.push({ name: command.name, displayName: command.displayName || command.name, type: PermissionType.Command, level: currentPermission?.level || 'unlisted' });
          });
          metadata.webhooks?.forEach((webhook) => {
            const currentPermission = props.appInfo.permissions?.find((x) => x.name === webhook.id);
              initialPermissions.push({ name: webhook.id, displayName: webhook.name || webhook.id, type: PermissionType.Webhook, level: currentPermission?.level || 'unlisted' });
          });
          metadata.events?.forEach((event) => {
            const currentPermission = props.appInfo.permissions?.find((x) => x.name === event.type);
              initialPermissions.push({ name: event.type, displayName: event.name || event.type, type: PermissionType.Event, level: currentPermission?.level || 'unlisted' });
          });
          setPermissions(initialPermissions);
          props.setAppInfo('permissions', initialPermissions);
        }
        setLoading(false);
      });
    }
  }, [props.appInfo.id]);

  const onPermissionChange = (name: string, value: any) => {
    const newPermissions = permissions;
    const permission = newPermissions.find((x) => x.name === name);
    permission.level = value;
    props.setAppInfo('permissions', newPermissions);
    setPermissions(newPermissions);
  };

  let body = null;
  if (loading) {
    return <Loader active />;
  } else if (permissions) {
    body = permissions.map((permission) => {
      return (
          <Table.Row key={permission.name}>
          <Table.Cell>{permission.displayName}</Table.Cell>
          <Table.Cell>
            <CommandPermissionDropdown commandName={permission.name} value={permission.level} onPermissionChange={onPermissionChange} />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Command</Table.HeaderCell>
            <Table.HeaderCell>Tier</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{body}</Table.Body>
      </Table>
      <br />
      <br />
      <Button className="primary" onClick={props.onSave} loading={props.isSaving} disabled={props.isSaving}>
        Save
      </Button>
    </>
  );
}
