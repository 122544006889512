import React from 'react';

export interface NavbarProps {
    nextMonth: TypeMePlease;
    previousMonth: TypeMePlease;
    onPreviousClick?: AnyFunc;
    onNextClick?: AnyFunc;
    onChange?: AnyFunc;
    className?: string;
    localeUtils?: {
        formatMonthTitle?: AnyFunc;
        formatWeekdayShort?: AnyFunc;
        formatWeekdayLong?: AnyFunc;
        getFirstDayOfWeek?: AnyFunc;
        getMonths?: AnyFunc;
    };
}

export default function Navbar({ nextMonth, previousMonth, onPreviousClick, onNextClick, className, localeUtils }: NavbarProps) {
    const months = localeUtils.getMonths();
    const prev = months[previousMonth.getMonth()];
    const next = months[nextMonth.getMonth()];

    return (
        <div className={className}>
            <button
                style={{
                    float: 'left'
                }}
                onClick={() => onPreviousClick()}
            >
                <i className="arrow circle left icon" /> {prev.slice(0, 3)}
            </button>
            <button
                style={{
                    float: 'right'
                }}
                onClick={() => onNextClick()}
            >
                {next.slice(0, 3)} <i className="arrow circle right icon" />
            </button>
        </div>
    );
}
