// Get a label for a Form Event Type
export function getLabelForEventType(eventType) {
  switch (eventType) {
    case 'created':
      return 'Created';
    case 'createdCurrent':
      return 'Cummulative Created';
    case 'publicFormSubmitted':
      return 'Public Forms';
    case 'completed':
      return 'Completed';
    case 'transfered':
      return 'Transferred';
    default:
      return '';
  }
}

//---------------------------------------------------------------------------------\\
// these 2 chart.js mouse events are from this link:
// https://stackoverflow.com/questions/39283177/programmatically-open-and-close-chart-js-tooltip
// (the last comment has a solution with a more attractive display, but not accurate on thin pie slices)
export function chartJsOpenTooltip(chart, datasetIndex, pointIndex) {
  if (chart.tooltip._active == undefined) chart.tooltip._active = [];
  const activeElements = chart.tooltip._active;
  const requestedElem = chart.getDatasetMeta(datasetIndex).data[pointIndex];
  for (let i = 0; i < activeElements.length; i++) {
    if (requestedElem._index == activeElements[i]._index) return;
  }
  activeElements.push(requestedElem);
  chart.tooltip._active = activeElements;
  chart.tooltip.update(true);
  chart.draw();
}

export function chartJsCloseTooltip(chart, datasetIndex, pointIndex) {
  const activeElements = chart.tooltip._active;
  if (activeElements == undefined || activeElements.length == 0) return;
  const requestedElem = chart.getDatasetMeta(datasetIndex).data[pointIndex];
  for (let i = 0; i < activeElements.length; i++) {
    if (requestedElem._index == activeElements[i]._index) {
      activeElements.splice(i, 1);
      break;
    }
  }
  chart.tooltip._active = activeElements;
  chart.tooltip.update(true);
  chart.draw();
}
//---------------------------------------------------------------------------------\\

// TODO - move to generic or array utils file
Array.prototype.distinct = function () {
  const a = this.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

Array.prototype.sum = function (key: string): number {
  return this.reduce((a, b) => a + (b[key] || 0), 0);
};
