import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Groups } from '../../../utility/SecurityHelper'

export interface GroupSelectProps { 
  value: string;
  onChange: (newValue: string) => void;
  disabled: boolean;
}
export function GroupSelect(props: GroupSelectProps) {
  const { value, onChange, disabled } = props;
  const options = [
    {
      text: 'All',
      value: 'all',
    },
  ];
  for (const key in Groups) {
    if (Groups.hasOwnProperty(key)) {
      options.push({
        text: key,
        value: Groups[key],
      });
    }
  }
  const selectedValue = value ? value.split(',') : [];
  if (selectedValue.length === 0) {
    selectedValue.push('all');
  }
  return (
    <Dropdown
      fluid
      multiple
      value={selectedValue}
      onChange={(e, { value }: { value: string[] }) => {
        const newValue = value.indexOf('all') === value.length - 1 ? null : value.filter((x) => x !== 'all').join(',');
        onChange(newValue);
      }}
      options={options}
      disabled={disabled}
    />
  );
}
