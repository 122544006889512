import React from 'react';

export interface YearMonthFormProps {
    date?: Date;
    localeUtils?: {
        formatMonthTitle?: AnyFunc;
        formatWeekdayShort?: AnyFunc;
        formatWeekdayLong?: AnyFunc;
        getFirstDayOfWeek?: AnyFunc;
        getMonths?: AnyFunc;
    };
    onChange?: AnyFunc;
}

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 150, 0);
const toMonth = new Date(currentYear + 100, 11);

export default function YearMonthForm({ date, localeUtils, onChange }: YearMonthFormProps) {
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
        years.push(i);
    }

    const handleChange = (e) => {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };
    const wrapperLeft = { width: '102px', display: 'inline-block', paddingRight: '5px' };
    const wrapperRight = { width: '62px', display: 'inline-block' };
    return (
        <form className="DayPicker-Caption">
            <div style={wrapperLeft}>
                <select name="month" className="ui fluid dropdown DayPicker-Dropdown" onChange={handleChange} value={date.getMonth()}>
                    {months.map((month, i) => (
                        <option key={month} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
            </div>
            <div style={wrapperRight}>
                <select name="year" className="ui fluid dropdown DayPicker-Dropdown" onChange={handleChange} value={date.getFullYear()}>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        </form>
    );
}
