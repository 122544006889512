import React from 'react';
import './Error.scss';

export type EmbeddedErrorProps = {
  errorCode: number;
};

export default function EmbeddedError(props: EmbeddedErrorProps) {
  const { errorCode } = props;
  return (
    <div className="error-wrapper">
      <div className="error-content">
        {errorCode === 404 ? <div className="errorguy" /> : null}
        <div className="footer">
          {errorCode === 404 ? (
            <div className="text">The page you have requested cannot be found.</div>
          ) : (
            <div className="text">An unexpected error has occured</div>
          )}
        </div>
      </div>
    </div>
  );
}
