import React, { useState } from 'react';
import { Menu, MenuItem, Segment } from 'semantic-ui-react';
import { AccountAuditLog } from './AccountAuditLog';

export type AuditProps = {
  id: string;
  account: TypeMePlease;
};

export function Audit(props: AuditProps) {
  const { id, account } = props;
  const [activeMenuItem, setActiveMenuItem] = useState('auditLog');
  return (
    <div>
      <Menu attached="top" tabular>
        <MenuItem name="auditLog" active={activeMenuItem === 'auditLog'} onClick={() => setActiveMenuItem('auditLog')} />
        <MenuItem
          name="loginReport"
          active={activeMenuItem === 'loginReport'}
          onClick={() => window.open(`/Reports/Authentication?clientId=${id}`, '_blank')}
        />
        <MenuItem name="ssoMapping" active={activeMenuItem === 'ssoMapping'} onClick={() => window.open(`/Reports/SSO?clientId=${id}`, '_blank')} />
        <MenuItem
          name="publicFormReport"
          active={activeMenuItem === 'publicFormReport'}
          onClick={() => window.open(`/Reports/PublicShare?clientId=${id}`, '_blank')}
        />
      </Menu>
      <Segment attached="bottom">{activeMenuItem === 'auditLog' && <AccountAuditLog clientId={id} name={account.name} />}</Segment>
    </div>
  );
}
