import './ReleaseEnvironmentPill.scss';
import React from 'react';
import moment from 'moment';
import { AzureDevOpsEnvironmentRelease } from './types';
import { Loader } from 'semantic-ui-react';


export type ReleaseEnvironmentPillProps = {
  releaseEnvironment: AzureDevOpsEnvironmentRelease;
}
const DevOpsReleaseNames = [
  'Microsoft.VisualStudio.Services.ReleaseManagement',
  'Microsoft.VisualStudio.Services.TFS'
]
export function ReleaseEnvironmentPill(props: ReleaseEnvironmentPillProps) {
  const releaseEnvironment = props.releaseEnvironment;
  const deployedBy = releaseEnvironment?.deployedBy == null ?  '--'
    : DevOpsReleaseNames.includes(releaseEnvironment.deployedBy) ? 'Azure DevOps'
    : releaseEnvironment.deployedBy;

  const isDeploying = releaseEnvironment?.status === 'inProgress' ||  releaseEnvironment?.status === 'queued';
  const isFailed = releaseEnvironment?.status === 'failed';
  return (
    <div className={`release-environment-pill ${isFailed && 'failed'} ${isDeploying && 'deploying'}`}>
      <span>
        {isDeploying && <span className='deploying-loader'><Loader active inline size='mini' /></span> }
        <a className='version' href={releaseEnvironment?.url}>{releaseEnvironment?.releaseVersion ?? 'Not Deployed'}</a>
        {releaseEnvironment?.releaseVersion && <span className='deployed-by'>By {deployedBy}</span>}
      </span>
      {releaseEnvironment?.releaseVersion && 
        <span className='deployed-on'>{releaseEnvironment?.deploymentDate == null ? '--' : moment.utc(releaseEnvironment.deploymentDate).local().format('MM/DD/YYYY h:mm:ss a')}</span>
      }
    </div>
  );
}
