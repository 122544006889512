import './Users.scss';

import React from 'react';
import { useApi } from '../../../hooks/useApi';
import Loader from '../../Loader';
import { NavLink, useParams } from 'react-router-dom';
import { DeviceTable } from './components/DeviceTable';
import UserInfoTable from './components/UserInfoTable';
import { UserDetailsResponse } from './uses-types';
import OnboardingStatus from './components/OnboardingStatus/OnboardingStatus';

export type UserDetailsProps = {
  /** The Client Id */
  id: string;
};

export function UserDetails(props: UserDetailsProps) {
  const { id: clientId } = props;
  const { id: userId } = useParams();
  const [userResult, userLoading] = useApi<UserDetailsResponse>(`api/account/${clientId}/users/${userId}`);

  if (userLoading) {
    return <Loader isLoading />;
  }

  return (
    <div>
      <NavLink to={`/account/${clientId}/users`} end>
        Back to Users List
      </NavLink>
      <h3>User Info</h3>
      <UserInfoTable user={userResult.user} />
      <h3>Devices</h3>
      <DeviceTable devices={userResult.devices} />
      <h3>Onboarding Status</h3>
      <OnboardingStatus clientId={clientId} userId={userId} />
    </div>
  );
}
