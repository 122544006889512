import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import Pagination from '../../paging/Pagination';
import { copyTextToClipboard } from '../../../utility/clipboard';
import './Workflows.scss';
import SearchInput from '../../../helpers/SearchInput';
import { apimCall } from '../../../utility/api';
import { WorkflowsProps, WorkflowsResponseBody } from './workflow-types';

export default function Workflows(props: WorkflowsProps) {
  const { id, account } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [showOnlyDeleted, setShowOnlyDeleted] = useState(false);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [showOnlyInactive, setShowOnlyInactive] = useState(false);
  const [loading, setLoading] = useState();

  const params = getWorkflowFilterParams(pageSize, search, page, showOnlyDeleted, showOnlyActive, showOnlyInactive);
  const [workflowsResult, workflowsLoading, workflowsLoaded, refreshWorkflows] = useApim<WorkflowsResponseBody>(`/workflows?${params.join('&')}`, id);

  useEffect(() => {
    refreshWorkflows();
  }, [pageSize, page, search]);

  const restoreWorkflow = async (workflowId) => {
    setLoading(workflowId);
    try {
      await apimCall(`/workflows/${workflowId}/restore`, 'POST', '', account.clientId);
      setLoading(null);
      refreshWorkflows();
    } catch (err) {
      alert('Error recovering workflow');
      setLoading(null);
    }
  };

  const handleStatusChange = (status: string) => {
    if (status == 'active') {
      if (showOnlyInactive) {
        setShowOnlyInactive(!showOnlyInactive);
      }
      setShowOnlyActive(!showOnlyActive);
    }
    if (status == 'inactive') {
      if (showOnlyActive) {
        setShowOnlyActive(!showOnlyActive);
      }
      setShowOnlyInactive(!showOnlyInactive);
    }
  };

  return (
    <div className="workflows">
      <SearchInput placeholder="Search workflows" searchFilter={search} updateSearchFilter={setSearch} />
      <div className="toggle-wrapper">
        <Checkbox toggle label="Show Only Deleted Workflows" checked={showOnlyDeleted} onChange={() => setShowOnlyDeleted(!showOnlyDeleted)} />
      </div>
      <div className="toggle-wrapper">
        <Checkbox toggle label="Show Only Active Workflows" checked={showOnlyActive} onChange={() => handleStatusChange('active')} />
      </div>
      <div className="toggle-wrapper">
        <Checkbox toggle label="Show Only Inactive Workflows" checked={showOnlyInactive} onChange={() => handleStatusChange('inactive')} />
      </div>
      <table className="ui table">
        <thead>
          <tr>
            <th>Workflow Name</th>
            <th>WorkflowId</th>
            <th>Created Date</th>
            <th>Last Updated By</th>
            <th>Last Updated Date</th>
            <th>Active</th>
            <th>Auto Deactivated</th>
            <th>Deleted Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {workflowsLoaded
            ? workflowsResult.data.items.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>
                      {row.id}{' '}
                      <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(row.id)}>
                        <i className="copy outline icon"></i>
                      </button>
                    </td>
                    <td>{moment.utc(row.createdDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
                    <td>{row.lastUpdatedBy == '00000000-0000-0000-0000-000000000000' ? '' : row.lastUpdatedBy}</td>
                    <td>{row.lastUpdatedDate ? moment.utc(row.lastUpdatedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
                    <td>{row.active ? 'true' : 'false'}</td>
                    <td>{row.autoDeactivated ? 'true' : 'false'}</td>
                    <td>{row.deletedDate ? moment.utc(row.deletedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
                    <td>
                      {row.deletedDate ? (
                        <Dropdown trigger={<Button icon="ellipsis horizontal" loading={row.id === loading} />} pointing="right" icon={null}>
                          <Dropdown.Menu>
                            <Dropdown.Item text="Restore" icon="configure" onClick={() => restoreWorkflow(row.id)} />
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!workflowsLoading ? (
        <Pagination
          pageSize={pageSize}
          page={page}
          totalRows={workflowsResult ? workflowsResult.data.totalCount : 0}
          changePageSize={setPageSize}
          changePage={setPage}
        />
      ) : null}
      <Loader isLoading={workflowsLoading} />
    </div>
  );
}

export function getWorkflowFilterParams(
  pageSize: number,
  search: string,
  page: number,
  showOnlyDeleted: boolean,
  showOnlyActive: boolean,
  showOnlyInactive: boolean
) {
  const params = [];
  const skip = pageSize * (page - 1);
  if (showOnlyDeleted) {
    params.push('onlyDeleted=true');
  }
  if (showOnlyActive) {
    params.push('active=true');
  }
  if (showOnlyInactive) {
    params.push('active=false');
  }
  params.push('includeDeleted=true');
  params.push('sortBy=lastUpdatedDate');
  params.push('sortDir=desc');
  params.push(`take=${pageSize}`);
  params.push(`skip=${skip}`);
  params.push(`search=${search}`);
  return params;
}
