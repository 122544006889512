import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { FormEventsAllClients } from '../charts/FormEventsAllClients';
import { FormEventTotals } from '../charts/FormEventTotals';
import { PublicApiAllClients } from '../charts/PublicApiAllClients';
import { PublicApiAuth } from '../charts/PublicApiAuth';
import { WorkflowsAllClients } from '../charts/WorkflowsAllClients';
import { Module } from '../Module';
import './DashboardMetrics.scss';
import { Button, Icon } from 'semantic-ui-react';
import { formatDateRangeLabel } from '../../utility/formatting';
import DateRangePickerModal from "../DateRangePicker/DateRangePickerModal";
import {RangeModifier} from "react-day-picker";

export function DashboardMetrics() {
  const [startDate, setStartDate] = useState(moment().days(-7).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDateChange = (range: RangeModifier) => {
    setStartDate(moment(range.from).format('YYYY-MM-DD'));
    setEndDate(moment(range.to).format('YYYY-MM-DD'));
    handleClose();
  }

  return (
    <div className="account-metrics">
      <Helmet>
        <title>Account Metrics</title>
      </Helmet>
      <div className="control-bar">
        <div className="controls">
          <div className="control">
            <Button onClick={handleOpen}>
                <Icon name="calendar outline" />
                {formatDateRangeLabel(startDate, endDate)}
              </Button>
            <DateRangePickerModal open={open} onClose={handleClose} onDoneClick={handleDateChange} />
          </div>
        </div>
      </div>
      <div className="charts">
        <Module className="module">
          <FormEventsAllClients title="Top Clients - Created Forms" eventType="created" width={500} height={325} startDate={startDate} endDate={endDate} />
        </Module>
        <Module className="module">
          <FormEventsAllClients title="Top Clients - Completed Forms" eventType="completed" width={500} height={325} startDate={startDate} endDate={endDate} />
        </Module>
        <Module className="module">
          <FormEventTotals title="Form Totals" eventTypes={['created', 'completed', 'transfered']} startDate={startDate} endDate={endDate} width={500} height={325} />
        </Module>
        <Module className="module">
          <FormEventTotals
            title="Public Form Submissions"
            eventTypes={['publicFormSubmitted']}
            startDate={startDate}
            endDate={endDate}
            width={500}
            height={325}
            useLegend={false}
          />
        </Module>
        <Module className="module">
          <WorkflowsAllClients title="Workflow TopClients" width={500} height={325} startDate={startDate} endDate={endDate} />
        </Module>
        <Module className="module">
          <PublicApiAllClients title="API Top Clients" width={500} height={325} startDate={startDate} endDate={endDate} />
        </Module>
        <Module className="module">
          <PublicApiAuth title="API Authorization Type" width={500} height={325} startDate={startDate} endDate={endDate} />
        </Module>
      </div>
    </div>
  );
}
