import React, { useState } from 'react';
import { Input, Button } from 'semantic-ui-react';
import { apiCall } from '../../../utility/api';
import { useApim } from '../../../hooks/useApi';
import { Module } from '../../Module';
import { canIUse, AppFeatures } from '../../../utility/SecurityHelper';
import { CommentInput } from '../../commentinput/CommentInput';

export type PublicFormPricingProps = {
  id: string;
  account: TypeMePlease;
  history?: TypeMePlease;
};

export default function PublicFormPricing(props: PublicFormPricingProps) {
  const { id, history } = props;
  const [saving, setSaving] = useState(false);
  const [comment, setComments] = useState('');
  const [creditPrice, setCreditPrice] = useState('');
  const [creditPriceResult, creditPriceLoading, creditPriceLoaded] = useApim<TypeMePlease>('/billing/creditprice', id);

  const oldCreditPrice = creditPriceLoaded ? creditPriceResult.creditPrice : '';

  const save = async () => {
    setSaving(true);
    await apiCall(`/api/account/${id}/creditPrice?creditPrice=${creditPrice}&comment=${comment}`, 'PUT', { creditPrice, comment });
    setComments('');
    setSaving(false);
    history.push(`/account/${id}/publicforms`);
  };

  return (
    <div className="adjustCredits">
      <div className="modules">
        <Module title="Update Credit Price" className="fullwidth-module">
          <table className="ui very basic collapsing celled table">
            <thead>
              <tr>
                <th>Current Price: {oldCreditPrice}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="field">
                  <Input
                    type="number"
                    value={creditPrice === null ? '' : creditPrice}
                    onChange={(e) => setCreditPrice(e.target.value)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="ui form">
            <br />
            <CommentInput placeholder="Comment."
                  value={comment} onChange={setComments} disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}/>
            <br />
            <Button className="primary" onClick={save} loading={saving} disabled={!comment}>
              Save
            </Button>
          </div>
        </Module>
      </div>
    </div>
  );
}
