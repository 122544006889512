import React from 'react';
import { Table } from 'semantic-ui-react';
import { ApiThrottlingScope } from '../../apiConfigs/apiConfigs-types';
import { ApiThrottlingLimitsDropdown } from '../../apiConfigs/ApiThrottlingLimitsDropdown';

type ApiThrottlingProps = {
  supportedLimits: any;
  selectedThrottlingLimits: any;
  updateThrottlingLimits: (scope: ApiThrottlingScope, value: any) => void;
  isReadOnly: boolean;
  editionApiThrottlingDefaults: any;
  effectiveThrottlingLimits: any;
};

const ApiThrottling: React.FC<ApiThrottlingProps> = ({
  supportedLimits,
  selectedThrottlingLimits,
  updateThrottlingLimits,
  isReadOnly,
  editionApiThrottlingDefaults,
  effectiveThrottlingLimits,
}) => {
  return (
    <div className="form-wrapper form-width">
      <div className="ui header header-cell">API Throttling Limits</div>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Throttling Limits</Table.Cell>
            <Table.Cell>Client Overrides</Table.Cell>
            <Table.Cell>Edition Defaults</Table.Cell>
            <Table.Cell>Effective Setting</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Per Day</Table.Cell>
            <Table.Cell>
              <ApiThrottlingLimitsDropdown
                supportedLimits={supportedLimits}
                scope={ApiThrottlingScope.day}
                value={selectedThrottlingLimits?.day}
                onChange={(x) => updateThrottlingLimits(ApiThrottlingScope.day, x)}
                readOnly={isReadOnly}
              />
            </Table.Cell>
            <Table.Cell>{editionApiThrottlingDefaults?.day}</Table.Cell>
            <Table.Cell>{effectiveThrottlingLimits?.day}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Per Hour</Table.Cell>
            <Table.Cell>
              <ApiThrottlingLimitsDropdown
                supportedLimits={supportedLimits}
                scope={ApiThrottlingScope.hour}
                value={selectedThrottlingLimits?.hour}
                onChange={(x) => updateThrottlingLimits(ApiThrottlingScope.hour, x)}
                readOnly={isReadOnly}
              />
            </Table.Cell>
            <Table.Cell>{editionApiThrottlingDefaults?.hour}</Table.Cell>
            <Table.Cell>{effectiveThrottlingLimits?.hour}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Per Minute</Table.Cell>
            <Table.Cell>
              <ApiThrottlingLimitsDropdown
                supportedLimits={supportedLimits}
                scope={ApiThrottlingScope.minute}
                value={selectedThrottlingLimits?.minute}
                onChange={(x) => updateThrottlingLimits(ApiThrottlingScope.minute, x)}
                readOnly={isReadOnly}
              />
            </Table.Cell>
            <Table.Cell>{editionApiThrottlingDefaults?.minute}</Table.Cell>
            <Table.Cell>{effectiveThrottlingLimits?.minute}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default ApiThrottling;
