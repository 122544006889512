import React, { useState } from 'react';
import { Table, Loader, Button, Modal } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { useApim } from '../../../hooks/useApi';
import { ClientDto } from '../account-types';
import { NavLink } from 'react-router-dom';
import { getFriendlyPermissionLevelName, hasCommandPermission } from './helpers';
import { AppItemPermissionLevel, AppsResponseBody, ClientAppPermissions, PublishedAppsResponseBody } from '../../apps/apps-types.d';
import { apimCall } from '../../../utility/api';
import { Parallel } from '../../../utility/parallel';

export type ConnectedAppsProps = {
  id: string;
  account: ClientDto;
};

export default function ConnectedApps(props: ConnectedAppsProps) {
  const { id, account } = props;
  const [appsResult, appsLoading, appsLoaded] = useApim<AppsResponseBody>('/apps');
  const [publishedAppsResult, publishedAppsLoading, publishedAppsLoaded] = useApim<PublishedAppsResponseBody>('/publishedapps?onlyActive=true');
  const [clientPermissionsResult, clientPermissionsLoading, clientPermissionsLoaded] = useApim<ClientAppPermissions[]>('/clientapppermissions', id);
  const [bulkPermissionsLevel, setBulkPermissionsLevel] = useState<AppItemPermissionLevel>(null);
  const [saving, setSaving] = useState<string>(null);

  if (appsLoading || publishedAppsLoading || clientPermissionsLoading) {
    return <Loader active />;
  }

  const onBulkPermissionChange = (permissionLevel: AppItemPermissionLevel, activate: boolean) => {
    const apps = appsResult.data.items;
    setSaving(activate ? 'activate' : 'deactivate');
    const promises = [];
    for (let i = 0; i < apps.length; i++) {
      if (!apps[i].permissions || !apps[i].permissions.some((x) => x.level === permissionLevel)) {
        continue;
      }

      let clientPermissions = clientPermissionsResult.find((x) => x.appId === apps[i].id);
      if (!clientPermissions) {
        clientPermissions = { id: '00000000-0000-0000-0000-000000000000', clientId: id, appId: apps[i].id, permissions: [] };
      }

      let updated = false;
      if (activate) {
        for (const permission of apps[i].permissions.filter((x) => x.level === permissionLevel)) {
          if (!clientPermissions.permissions.some((x) => x.name === permission.name)) {
            clientPermissions.permissions.push({ name: permission.name, type: permission.type });
            updated = true;
          }
        }
      } else {
        for (const permission of apps[i].permissions.filter((x) => x.level === permissionLevel)) {
          const index = clientPermissions.permissions.findIndex((x) => x.name === permission.name);
          if (index > -1) {
            clientPermissions.permissions.splice(index, 1);
            updated = true;
          }
        }
      }

      if (updated) {
        if (clientPermissions.id === '00000000-0000-0000-0000-000000000000') {
          promises.push(apimCall('/clientapppermissions', 'POST', clientPermissions, id));
        } else {
          promises.push(apimCall(`/clientapppermissions/${clientPermissions.id}`, 'PUT', clientPermissions, id));
        }
      }
    }

    Parallel(promises).then(() => location.reload());
  };

  const rows = [];
  if (appsLoaded && publishedAppsLoaded && clientPermissionsLoaded) {
    // Row for GF triggers
    // rows.push(
    //   <Table.Row>
    //     <Table.Cell textAlign="center">{/* <img style={{ width: '40px', height: '40px' }} src={app.icon} /> */}</Table.Cell>
    //     <Table.Cell>
    //       <NavLink className="nav-link" to={`/account/${id}/connectedapps/00000000-0000-0000-0000-000000000000`}>
    //         GoFormz Triggers
    //       </NavLink>
    //     </Table.Cell>
    //     <Table.Cell>{`${clientPermissionsResult.find((x) => x.appId === '00000000-0000-0000-0000-000000000000')?.permissions?.length || 0}/1`}</Table.Cell>
    //   </Table.Row>
    // );

    appsResult.data.items.map((app) => {
      const publishedApp = publishedAppsResult.data.items.find((x) => x.appId === app.id);
      const clientAppPermissions = clientPermissionsResult.find((x) => x.appId === app.id);
      let totalCommandCount = 0;
      let permittedCommandCount = 0;
      if (publishedApp?.package?.metadata) {
        const metadata = publishedApp.package.metadata;
        metadata.commands?.forEach((command) => {
          const currentPermission = app.permissions?.find((x) => x.name === command.name)?.level || 'unlisted';
          if (currentPermission !== 'deprecated') {
            if (hasCommandPermission(command.name, currentPermission, clientAppPermissions, account.subscriptionCode)) {
              permittedCommandCount++;
            }
            totalCommandCount++;
          }
        });
        metadata.webhooks?.forEach((webhook) => {
          const currentPermission = app.permissions?.find((x) => x.name === webhook.id);
          if (currentPermission?.level !== 'deprecated') {
            if (hasCommandPermission(webhook.id, currentPermission?.level, clientAppPermissions, account.subscriptionCode)) {
              permittedCommandCount++;
            }
            totalCommandCount++;
          }
        });
        metadata.events?.forEach((event) => {
          const currentPermission = app.permissions?.find((x) => x.name === event.type);
          if (currentPermission?.level !== 'deprecated') {
            if (hasCommandPermission(event.type, currentPermission?.level, clientAppPermissions, account.subscriptionCode)) {
              permittedCommandCount++;
            }
            totalCommandCount++;
          }
        });
      }

      rows.push(
        <Table.Row>
          <Table.Cell textAlign="center">
            <img style={{ width: '40px', height: '40px' }} src={app.icon} />
          </Table.Cell>
          <Table.Cell>
            <NavLink className="nav-link" to={`/account/${id}/connectedapps/${app.id}`}>
              {app.appName}
            </NavLink>
          </Table.Cell>
          <Table.Cell>{`${permittedCommandCount}/${totalCommandCount}`}</Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div className="connected-apps">
      <Helmet>
        <title>{account.name} - Connected Apps</title>
      </Helmet>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>App</Table.HeaderCell>
            <Table.HeaderCell>App Name</Table.HeaderCell>
            <Table.HeaderCell>Number of Commands Available</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
      <Button color="blue" onClick={() => setBulkPermissionsLevel(AppItemPermissionLevel.Team)}>
        All Team Commands
      </Button>
      <Button color="blue" onClick={() => setBulkPermissionsLevel(AppItemPermissionLevel.Advanced)}>
        All Advanced Commands
      </Button>
      <Modal
        open={bulkPermissionsLevel !== null}
        dimmer="inverted"
        size="mini"
        closeIcon={{ color: 'black', name: 'close' }}
        onClose={() => setBulkPermissionsLevel(null)}
      >
        <Modal.Header>{`All ${getFriendlyPermissionLevelName(bulkPermissionsLevel)} Commands`}</Modal.Header>
        <Modal.Content>
          <Button color="blue" onClick={() => onBulkPermissionChange(bulkPermissionsLevel, true)} loading={saving === 'activate'} disabled={!!saving}>
            Activate
          </Button>
          <Button color="red" onClick={() => onBulkPermissionChange(bulkPermissionsLevel, false)} loading={saving === 'deactivate'} disabled={!!saving}>
            Deactivate
          </Button>
        </Modal.Content>
      </Modal>
    </div>
  );
}
