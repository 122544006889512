import React, { useState } from 'react';
import { useApim } from '../../hooks/useApi';
import moment from 'moment';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
import SearchInput from '../../helpers/SearchInput';
import Pagination from '../paging/Pagination';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { apimCall } from '../../utility/api';
import { useMessages } from '../../context/MessagesContext';

type ReportsProps = {
  clientId: string;
  name: string;
};

type ReportResponseData = {
  items: Report[];
  totalCount: number;
};

type Report = {
  name: string;
  id: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  createdBy: string;
  createdDate: string;
  deletedDate: string;
  folderId: string;
};

export default function Reports(props: ReportsProps) {
  const { clientId, name } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const { showMessage } = useMessages();

  const params = getReportFilterParams(pageSize, search, page, includeDeleted);
  const [reportsResult, reportsLoading, reportsLoaded, refreshReports] = useApim<ReportResponseData>(`/reportviews?${params.join('&')}`, clientId, null);
  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  const restore = async (reportViewId) => {
    setActionLoading(true);

    try {
      const result = await apimCall(`/reportviews/${reportViewId}/recover`, 'POST', null, clientId);

      if (result.statusText === 'OK') {
        refreshReports();
      } else {
        showMessage(`Unable to restore Report.`);
      }
    } catch (error) {
      console.log(error);
    }
    setActionLoading(false);
  };

  return (
    <div className="reports">
      <Helmet>
        <title>{name} - Reports</title>
      </Helmet>
      <Checkbox toggle label="Include Deleted Reports" checked={includeDeleted} onChange={() => setIncludeDeleted(!includeDeleted)} />
      <br />
      <br />
      <SearchInput placeholder="Search reports" searchFilter={search} updateSearchFilter={setSearch} />
      <table className="ui table">
        <thead>
          <tr>
            <th>Report Name</th>
            <th>Created Date</th>
            <th>Last Updated Date</th>
            <th>Deleted Date</th>
            <th>Report Id</th>
            <th>Folder Id</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reportsLoaded &&
            reportsResult.items.map((report) => {
              return (
                <tr>
                  <td>{report.name}</td>
                  <td>{moment.utc(report.createdDate).local().calendar(null, calendarOptions)}</td>
                  <td>{moment.utc(report.lastUpdatedDate).local().calendar(null, calendarOptions)}</td>
                  <td>{report.deletedDate && moment.utc(report.deletedDate).local().calendar(null, calendarOptions)}</td>
                  <td>{report.id}</td>
                  <td>{report.folderId}</td>
                  <td>
                    {report.deletedDate && (
                      <Dropdown trigger={<Button icon="ellipsis horizontal" loading={actionLoading} />} pointing="right" icon={null}>
                        <Dropdown.Menu>
                          <Dropdown.Item text="Restore" icon="configure" onClick={() => restore(report.id)} />
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {reportsLoaded && (
        <Pagination
          pageSize={pageSize}
          page={page}
          totalRows={reportsResult ? reportsResult.totalCount : 0}
          changePageSize={setPageSize}
          changePage={setPage}
        />
      )}
      <Loader isLoading={reportsLoading} />
    </div>
  );
}

function getReportFilterParams(pageSize: number, search: string, page: number, includeDeleted: boolean) {
  const params = [];

  const skip = pageSize * (page - 1);
  params.push('sortBy=lastUpdatedDate');
  params.push('sortDir=desc');
  params.push(`take=${pageSize}`);
  params.push(`skip=${skip}`);
  params.push(`name=${search}`);
  if (includeDeleted) {
    params.push('includeDeleted=true');
  }
  return params;
}
