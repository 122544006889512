import React, { useState } from 'react';
import './SubscriptionApiThrottling.scss';
import { Button, Table } from 'semantic-ui-react';
import { ApiConfigsDto, ApiThrottlingScope, ApiThrottlingSupportedLimits } from '../apiConfigs/apiConfigs-types';
import { deepClone } from '../../utility/deepClone';
import { ApiThrottlingLimitsDropdown } from '../apiConfigs/ApiThrottlingLimitsDropdown';
import { apimCall } from '../../utility/api';
import { useApi, useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';


export type EditionDto = {
  name: string;
  code: string;
  features: Record<string, object>;
  apiConfigs: ApiConfigsDto;
};

export type SubscriptionApiThrottlingProps = { 
  subscription: string;
}
export function SubscriptionApiThrottling(props: SubscriptionApiThrottlingProps) {
  const [editions, editionsLoading, _editionsLoaded, _refreshEditions, setEditions] = useApim<EditionDto[]>('/editions');
  const [throttlingLimits, throttlingLimitsLoading] = useApi<ApiThrottlingSupportedLimits>('api/account/apiconfigs/limits');
  const [isSaving, setIsSaving] = useState(false);
  
  const readOnly = !canIUse(AppFeatures.EditSubscriptions);
  
  const save = async () => {
    setIsSaving(true);
    await apimCall('/editions', 'PUT', editions);
    setIsSaving(false);
  };
  
  const setApiThrottlingEdition = (edition: EditionDto, scope: ApiThrottlingScope, value: number) => {
    const newEdition = deepClone(edition);
    switch (scope) {
      case ApiThrottlingScope.day:
        newEdition.apiConfigs.apiLimitDay = value;
        break;
      case ApiThrottlingScope.hour:
        newEdition.apiConfigs.apiLimitHour = value;
        break;
      case ApiThrottlingScope.minute:
        newEdition.apiConfigs.apiLimitMinute = value;
        break;
    }
    const newEditions = deepClone(editions);
    newEditions[editions.indexOf(edition)] = newEdition;
    setEditions(newEditions);
  };

  
  const apiThrottlingTableRow = (edition: EditionDto, limits: ApiThrottlingSupportedLimits, label: string, scope: ApiThrottlingScope, value: number) => {
    console.log(edition);
    console.log(limits);
    console.log(value);
    return (
      <Table.Row>
        <Table.Cell className="table-cell">
          <span style={{ marginRight: '2px' }}>{label}</span>
        </Table.Cell>
        <Table.Cell>
          <ApiThrottlingLimitsDropdown
            readOnly={readOnly}
            supportedLimits={limits}
            scope={scope}
            value={value}
            onChange={(x) => setApiThrottlingEdition(edition, scope, x)}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const loading = editionsLoading || isSaving || throttlingLimitsLoading;
  const renderTable = !editionsLoading && !throttlingLimitsLoading;
  const selectedEdition = (editions || []).find(edition => edition.code === props.subscription);
  return (
    <div className='subscription-api-throttling'>
      <Loader isLoading={loading} />
      <Table celled>
        {renderTable && 
        <Table.Body>
          <Table.Row>
            <Table.Cell className="table-cell-header">API Throttling Limits</Table.Cell>
          </Table.Row>
          {apiThrottlingTableRow(selectedEdition, throttlingLimits, 'Per Day', ApiThrottlingScope.day, selectedEdition.apiConfigs.apiLimitDay)}
          {apiThrottlingTableRow(selectedEdition, throttlingLimits, 'Per Hour', ApiThrottlingScope.hour, selectedEdition.apiConfigs.apiLimitHour)}
          {apiThrottlingTableRow(selectedEdition, throttlingLimits, 'Per Minute', ApiThrottlingScope.minute, selectedEdition.apiConfigs.apiLimitMinute)}
        </Table.Body>
        }
      </Table>
      <div className='save-container'>
        <Button primary className={'save-button'} onClick={save} loading={isSaving}>Save</Button>
      </div>
    </div>
  );
}
