import React from 'react';
import './DeleteAddOnButton.scss';
import { Button } from 'semantic-ui-react';


export type DeleteAddOnButtonProps = { 
  onClick: () => void;
}

export function DeleteAddOnButton(props: DeleteAddOnButtonProps) {

  return <Button className='delete-add-on-button' circular color='red' icon='minus' onClick={props.onClick} />
}