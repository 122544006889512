import React from 'react';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
import { Button, Icon, Statistic } from 'semantic-ui-react';
import './EditFeatureFlag.scss';
import { NavLink, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { copyTextToClipboard } from '../../utility/clipboard';
import { FeatureFlagDetails } from './FeatureFlagDetails';
import { EditFeatureFlagExperiment } from './EditFeatureFlagExperiment';
import { useApi } from '../../hooks/useApi';
import { FeatureFlag, FeatureFlagAnalyticsTotals, FeatureFlagExperiment } from './types';
import { FeatureFlagStats } from './FeatureFlagStats';
import { FeatureFlagExperimentsList } from './FeatureFlagExperimentsList';
import { FeatureFlagExperimentStats } from './FeatureFlagExperimentStats';
import { FeatureFlagOverrides } from './FeatureFlagOverrides';

export function EditFeatureFlag() {
  const { featureFlagId } = useParams();
  const isAddFeatureFlag = featureFlagId === 'add';
  const navigate = useNavigate();
  const [featureFlags, _featureFlagsloading, featureFlagsLoaded, refreshFeatureFlags] 
    = useApi<FeatureFlag[]>(`api/featureflags`);
  const [featureFlag, _featureFlagLoading, featureFlagLoaded, refreshFeatureFlag] 
    = useApi<FeatureFlag>(`api/featureflags/${featureFlagId}`, isAddFeatureFlag);
  const [featureFlagExperiments, _FeatureFlagExperimentsLoading, featureFlagExperimentsLoaded, refreshFeatureFlagExperiments] 
    = useApi<FeatureFlagExperiment[]>(`api/featureflags/${featureFlagId}/featureflagexperiments`, isAddFeatureFlag);
  const [featureFlagTotals, _featureFlagTotalsLoading, featureFlagTotalsLoaded, refreshFeatureFlagTotals] = useApi<FeatureFlagAnalyticsTotals>(`api/featureflags/${featureFlagId}/analytics/totals`, isAddFeatureFlag);

  if (!isAddFeatureFlag 
    && (!featureFlagLoaded || !featureFlagsLoaded || !featureFlagExperimentsLoaded)) {
    return <Loader isLoading />;
  }

  const lastUpdatedDate = featureFlag?.lastUpdatedDate ? new Date(`${featureFlag.lastUpdatedDate}Z`) : null;
  
  
  const copyfeatureIdToClipboard = () => {
    copyTextToClipboard(featureFlag.featureFlagId);
  };

  const navigateTolist = () => { 
    navigate('/featureflags');
  }

  const getHeaderText = () => { 
    return featureFlag?.flagName ?? `${featureFlagId == null ? 'Add' : 'Edit'} Feature Flag`;
  }

  const onBackClick = () => { 
    navigateTolist();
  }

  const onFeatureFlagOverridesUpdates = () => {
    refreshFeatureFlagTotals();
  }

  const onFeatureFlagSaved = (newFeatureFlag: FeatureFlag) => { 
    if(isAddFeatureFlag) { 
      return navigate(`/featureflags/${newFeatureFlag.featureFlagId}`);
    }
    
    refreshFeatureFlag();
    refreshFeatureFlags();
    refreshFeatureFlagExperiments();
  }

  const onFetaureFlagDeleted = () => { 
    navigateTolist();
  }

  const onFeatureFlagExperimentSaved = () => {
    refreshFeatureFlagExperiments();
  }

  return (
    <div className="edit-feature-flag">
      <div className="page-header">
        <Helmet>
          <title>{getHeaderText()}</title>
        </Helmet>
        <div className="feature-info">
          <Button className="back-button" onClick={onBackClick} circular icon='arrow alternate circle left outline'></Button>
          <h2 className="feature-title">{getHeaderText()}</h2>
          <div className='last-update'><label>Last Updated: {lastUpdatedDate ?  `${lastUpdatedDate.toLocaleDateString()} ${lastUpdatedDate.toLocaleTimeString() }` : '--'}</label></div>
          { featureFlag?.featureFlagId && 
            <div className="featureId">
              <label>{featureFlag?.featureFlagId}&nbsp;</label>
              <button className="ui compact mini icon button" onClick={copyfeatureIdToClipboard}>
                <i className="copy outline icon"></i>
              </button>
          </div>
          }
          <div className="stats">
            <Statistic className='stat'>
              <Statistic.Value>
                <Icon name='user' />{!featureFlagTotalsLoaded ? '...' : featureFlagTotals?.totalFeatureFlagClientOverrides ?? '--'}
              </Statistic.Value>
              <Statistic.Label>Client Overrides</Statistic.Label>
            </Statistic>
            <Statistic className='stat'>
              <Statistic.Value>
                <Icon name='balance scale' />{!featureFlagTotalsLoaded ? '...' : featureFlagTotals?.totalFeatureFlagExperiments ?? '--'}
              </Statistic.Value>
              <Statistic.Label>Expirements</Statistic.Label>
            </Statistic>
            <Statistic className='stat'>
              <Statistic.Value>
                <Icon name='group' />{!featureFlagTotalsLoaded ? '...' : featureFlagTotals?.totalFeatureFlagExperimentParticipants ?? '--'}
              </Statistic.Value>
              <Statistic.Label>Experiment Participants</Statistic.Label>
            </Statistic>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="menu-view">
          <NavLink className="nav-link" to={`/featureflags/${featureFlagId}`} end>
            Details
          </NavLink>
          <NavLink className="nav-link" to={`/featureflags/${featureFlagId}/experiments`} end>
            Experiments
          </NavLink>
          <NavLink className="nav-link" to={`/featureflags/${featureFlagId}/stats`} end>
            Stats
          </NavLink>
          <NavLink className="nav-link" to={`/featureflags/${featureFlagId}/overrides`} end>
            Overrides
          </NavLink>
        </div>
        <div className='content-view'>
          <Routes>
            <Route index 
              element={<FeatureFlagDetails featureFlag={featureFlag} featureFlags={featureFlags} onFeatureFlagSaved={onFeatureFlagSaved} onFeatureFlagDeleted={onFetaureFlagDeleted} />} />
            {!isAddFeatureFlag && <Route path="experiments" 
              element={<FeatureFlagExperimentsList featureFlag={featureFlag} featureFlagExperiments={featureFlagExperiments}  />} /> }
            {!isAddFeatureFlag && <Route path="experiments/:featureFlagExperimentId" 
              element={<EditFeatureFlagExperiment featureFlag={featureFlag}
                featureFlags={featureFlags}
                featureFlagExperiments={(featureFlagExperiments || [])}
                onFeatureFlagExperimentSaved={onFeatureFlagExperimentSaved} />} /> }
            {!isAddFeatureFlag && <Route path="experiments/:featureFlagExperimentId/stats" 
              element={<FeatureFlagExperimentStats />} /> }
            {!isAddFeatureFlag && <Route path="stats" 
              element={<FeatureFlagStats featureFlag={featureFlag}
                featureFlagExperiments={(featureFlagExperiments || [])} />} /> }
            {!isAddFeatureFlag && <Route path="overrides" 
              element={<FeatureFlagOverrides featureFlag={featureFlag} onFeatureOverridesUpdated={onFeatureFlagOverridesUpdates}/>} /> }
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
}