import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import Chart from 'chart.js';
import { chartJsOpenTooltip, chartJsCloseTooltip } from './Charts';
import './Charts.scss';

export type PublicApiAllClientsProps = {
  title?: string;
  startDate?: string;
  endDate?: string;
  topCount?: number;
  width?: number;
  height?: number;
  topSelector?: boolean;
  colorScheme?: string;
};

export function PublicApiAllClients(props: PublicApiAllClientsProps) {
  const defaultColorScheme = useRef<TypeMePlease>(Chart['colorschemes'].tableau.Classic20);
  const defaultStartDate = useRef<TypeMePlease>(moment().days(-7).format('YYYY-MM-DD'));

  const [topCount, setTopCount] = useState(props.topCount || 20);
  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate || '';
  const topSelector = props.topSelector === undefined ? true : props.topSelector;
  const colorScheme = getColorScheme();
  const [total, setTotal] = useState(0);
  const [redirecting, setRedirecting] = useState(null);

  // set dimensions - account for optional header displays
  const width = props.width || 500;
  const height = props.height || 400;
  const canvasSquare = height - 75;
  const legendHeight = height - (props.title ? 20 : 0) - (topSelector ? 30 : 0) - 5;

  const [data, dataLoading, dataLoaded] = useApim<TypeMePlease[]>(
    `/metrics/publicapi/requests/clients?startDate=${startDate}&endDate=${endDate}&topCount=${topCount}`
  );

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);
  const [legend, setLegend] = useState([]);
  const handleSetTopCount = (e) => setTopCount(e.target.value);

  useEffect(() => {
    // Make the chart canvas once only
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'pie',
        options: {
          aspectRatio: 1,
          legend: {
            display: false,
          },
        },
      });
    }

    if (dataLoaded) {
      // Aggregate
      const chartData = data.map((client) => {
        const entry = { clientId: client.clientId, clientName: client.clientName, count: 0 };
        client.items.forEach((item) => item.dates.forEach((i) => (entry.count += i.count)));
        return entry;
      });
      chartData.sort((a, b) => (a.count < b.count ? 1 : -1));
      setTotal(chartData.sum('count'));

      // Set the chart properties and update
      chart.current.data.labels = chartData.map((x) => x.clientName);
      chart.current.data.datasets = [
        {
          data: chartData.map((x) => x.count),
          backgroundColor: colorScheme,
        },
      ];
      canvasEl.current.onclick = function (evt) {
        const activePoints = chart.current.getElementsAtEvent(evt);
        gotoClient(chartData[activePoints[0]._index].clientId);
      };
      chart.current.update();

      // Make the legend
      const legendItems = [];
      const meta = chart.current.getDatasetMeta(0);
      for (let i = 0; i < chartData.length; i++) {
        const color = meta.data[i]._model.backgroundColor;
        legendItems.push(legendRow(chartData[i].clientId, chart.current, i, chartData[i].clientName, color));
      }
      setLegend(legendItems);
    }
  }, [data, dataLoaded]);

  function getColorScheme() {
    let scheme;
    if (props.colorScheme) {
      const parts = props.colorScheme.split('.');
      if (parts.length > 1) {
        scheme = Chart['colorschemes'][parts[0]][parts[1]];
      }
    }
    if (!scheme) {
      scheme = defaultColorScheme.current;
    }
    return scheme;
  }

  const legendRow = (clientId, chart, index, label, color) => {
    return (
      <div
        className="legend-row"
        key={index}
        onMouseOver={() => chartJsOpenTooltip(chart, 0, index)}
        onMouseOut={() => chartJsCloseTooltip(chart, 0, index)}
        onClick={() => gotoClient(clientId)}
      >
        <div className="legend-dot-wrapper">
          <div className="legend-dot" style={{ backgroundColor: `${color}` }}></div>
        </div>
        <div className="legend-text">{label}</div>
      </div>
    );
  };

  const gotoClient = (clientId) => {
    setRedirecting(true);
    window.location.href = `/account/${clientId}/metrics`;
  };

  return (
    <div className="chart pie-chart" style={{ width: `${width}px`, height: `${height}px` }}>
      <Loader isLoading={dataLoading || redirecting} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <div className="chart-with-legend">
        <div>
          {topSelector ? (
            <div>
              <span className="top-label">Top # </span>
              <input className="top-input" type="number" value={topCount} onChange={handleSetTopCount} min="1" />
            </div>
          ) : (
            ''
          )}
          <div className="legend" style={{ height: `${legendHeight}px` }}>
            {legend}
          </div>
        </div>
        <div>
          <div className="total">Total: {total}</div>
          <div style={{ width: `${canvasSquare}px`, height: `${canvasSquare}px` }}>
            <canvas className="chart-canvas" key="canvas" ref={canvasEl}></canvas>
          </div>
        </div>
      </div>
    </div>
  );
}
