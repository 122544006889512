import moment from 'moment';
import React from 'react';
import { Checkbox, Dropdown, Icon, Input, Popup, TableCell, TableRow } from 'semantic-ui-react';
import { CalendarOptions, PricingTypeDropdownValues } from './AddOnConstants';
import './AddOnsClientConfigurationTableRow.scss';
import { DeleteAddOnButton } from './DeleteAddOnButton';
import { UndoAddOnDeleteButton } from './UndoAddOnDeleteButton';
import { AddOnClientConfigurationDto, AddOnConfigurationDto } from './types';

export type AddOnsClientConfigurationTableRowProps = { 
  addOnClientConfiguration: AddOnClientConfigurationDto;
  addOns: AddOnConfigurationDto[];
  readOnly?: boolean;
  onChange: (addOnClientConfiguration: AddOnClientConfigurationDto) => void;
  onDelete: (addOnClientConfiguration: AddOnClientConfigurationDto) => void;
  onUndoDelete?: (addOnClientConfiguration: AddOnClientConfigurationDto) => void;
  addOnClientDeletedConfigurations?: AddOnClientConfigurationDto[];
}

export function AddOnsClientConfigurationTableRow(props: AddOnsClientConfigurationTableRowProps) {
  const { addOnClientConfiguration, addOns, readOnly, addOnClientDeletedConfigurations } = props;
  const isDeleted = addOnClientDeletedConfigurations?.some(x => x.addOnConfigurationId === addOnClientConfiguration.addOnConfigurationId);
  
  const addOnDropDownValues = (addOns || []).map(addOn =>({
    key: `client-add-on-config-dropdown-${addOn.addOnConfigurationId}`,
    text: `${addOn.title} (${addOn.code})`,
    value: addOn.addOnConfigurationId,
  }));
  

  const onPropertyChange = (property: keyof AddOnClientConfigurationDto, value: any) => {
    props.onChange({ ...props.addOnClientConfiguration, [property]: value });
  }

  const onDelete = () => { 
    props.onDelete(props.addOnClientConfiguration);
  }

  const onUndoDelete = () => { 
    props.onUndoDelete(props.addOnClientConfiguration);
  }

  return (
    <TableRow className={`add-ons-client-configuration-table-row ${isDeleted ? 'add-ons-deleted' : ''}`}>
      <TableCell className='add-ons-client-configuration-table-row-value add-on-id'>
        {!readOnly && (!isDeleted ? <DeleteAddOnButton onClick={onDelete} /> : <UndoAddOnDeleteButton onClick={onUndoDelete} />)}
        <Dropdown 
          search
          selection
          value={addOnClientConfiguration.addOnConfigurationId} 
          options={addOnDropDownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('addOnConfigurationId', data.value)} />         
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-disabled'>
        <Checkbox fluid
          disabled={readOnly}
          checked={addOnClientConfiguration.isDisabled ?? false} 
          onChange={(_, data) => onPropertyChange('isDisabled', data.checked)} /> 
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-pricing-type'>
        <Dropdown 
          value={addOnClientConfiguration.pricingType} 
          options={PricingTypeDropdownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('pricingType', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-price'>
        <Input fluid placeholder='Price when billed monthly'
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnClientConfiguration.monthlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('monthlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-price'>
        <Input fluid placeholder='Price when billed annually' 
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnClientConfiguration.yearlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('yearlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-user-limit'>
        <Input fluid 
          type='number'
          step='1'
          min='0' 
          disabled={readOnly}
          value={addOnClientConfiguration.userLimit ?? ''} 
          onChange={(_, data) => onPropertyChange('userLimit', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-client-configuration-table-row-value add-on-metadata'>
        <Popup
          on='click'
          trigger={<Icon size='small' circular name='info' />}
          content= { 
            <div className='add-ons-dates'>
              <div className='add-ons-dates-section'>
                <label>Created Date</label>
                <span>{addOnClientConfiguration.createdDate == null ? '--' : moment.utc(addOnClientConfiguration.createdDate).local().calendar(null, CalendarOptions)}</span>
              </div>
              <div className='add-ons-dates-section'>
                <label>Last Updated Date</label>
                <span>{addOnClientConfiguration.lastUpdatedDate == null ? '--' : moment.utc(addOnClientConfiguration.lastUpdatedDate).local().calendar(null, CalendarOptions)}</span>
              </div>
            </div>
          }
        />
      </TableCell>
    </TableRow>
  );
}