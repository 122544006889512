import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from '../../hooks/useForm';
import './Apps.scss';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';

export type AppCreateProps = {
  history?: TypeMePlease;
};
export function AppCreate(props: AppCreateProps) {
  const { history } = props;
  const [saving, setSaving] = useState(false);
  const [appInfo, setAppInfo] = useForm({
    appName: '',
    description: '',
    author: '',
  });

  const handleSubmit = async () => {
    setSaving(true);
    const res = await Axios.post(`/api/app`, appInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setSaving(false);

    history.push(`/Apps/Edit/${res.data}`);
  };

  return (
    <div className="apps">
      <Helmet>
        <title>App Create</title>
      </Helmet>
      <div className="main">
        <div className="ui form">
          <div className="two fields">
            <div className="field">
              <label>Name</label>
              <input type="text" value={appInfo.appName} onChange={(e) => setAppInfo('appName', e.target.value)} />
            </div>

            <div className="field">
              <label>Description</label>
              <input type="text" value={appInfo.description} onChange={(e) => setAppInfo('description', e.target.value)} />
            </div>
          </div>

          <div className="two fields">
            <div className="field">
              <label>Author</label>
              <input type="text" value={appInfo.author} onChange={(e) => setAppInfo('author', e.target.value)} />
            </div>

            <br />
            <br />
          </div>
          <Button className="primary" onClick={handleSubmit} loading={saving}>
            Create
          </Button>
        </div>
      </div>
    </div>
  );
}
