import React, { useState } from 'react';
import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';
import './EmailActivity.scss';
import Pagination from '../../paging/Pagination';
import moment from 'moment';
import SearchInput from '../../../helpers/SearchInput';
import EmailActivityEvents from './EmailActivityEvents';

export type EmailActivityProps = {
  id: string;
  account: TypeMePlease;
};

export default function EmailActivity(props: EmailActivityProps) {
  const { id, account } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');

  const params = getFilterParams(pageSize, page, search);
  const [result, loading, loaded] = useApim<CombinedMessageRecipientType[]>(`/emailactivity/combinedmessagerecipients?${params.join('&')}`, id);

  return (
    <div className="email-activity">
      <Helmet>
        <title>{account.name} - Email Activity</title>
      </Helmet>
      <SearchInput placeholder="Search Subject, FormId, CorrelationId, MessageId" searchFilter={search} updateSearchFilter={setSearch} />

      <table className="ui table">
        <thead>
          <tr>
            <th>Message Type</th>
            <th>Status</th>
            <th>To</th>
            <th>Message</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {loaded
            ? result.slice(0, pageSize).map((row, i) => {
                return <EmailActivityRow key={row.messageRecipientId} row={row} clientId={id} />;
              })
            : null}
        </tbody>
      </table>
      {loaded && result ? (
        <Pagination pageSize={pageSize} page={page} changePageSize={setPageSize} changePage={setPage} infinitePaging hasMorePages={result.length > pageSize} />
      ) : null}
      <Loader isLoading={loading} />
    </div>
  );
}

type EmailActivityRowProps = {
  clientId: string;
  row: CombinedMessageRecipientType;
};

function EmailActivityRow(props: EmailActivityRowProps) {
  const { row, clientId } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <tr onClick={() => setOpen(!open)} className="activity-row">
        <td>{getMessageType(row)}</td>
        <td>
          <div className="email-status-wrapper">
            <div className={`status-circle ${row.status.toLowerCase()}`}></div>
            <span>{capitalizeFirstLetter(row.status)}</span>
          </div>
        </td>
        <td>{row.emailAddress}</td>
        <td>{row.subject}</td>
        <td>{moment.utc(row.timestamp).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      </tr>
      {open ? <EmailActivityEvents combinedMessageRecipient={row} clientId={clientId} onClose={() => setOpen(false)} /> : null}
    </>
  );
}

export function getFilterParams(pageSize: number, page: number, search: string) {
  const params = [];
  const skip = pageSize * (page - 1);
  params.push(`take=${pageSize + 1}`);
  params.push(`skip=${skip}`);
  params.push(`search=${encodeURIComponent(search)}`);
  return params;
}

export function getMessageType(row: CombinedMessageRecipientType): string {
  switch (row.emailProvider.toLowerCase()) {
    case 'sendgrid':
    case 'smtp':
    case 'azureemail':
      return 'Email';
    case 'twilio':
      return 'Text';
    default:
      return 'Unknown';
  }
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type CombinedMessageRecipientType = {
  messageRecipientId: string;
  messageId: string;
  timestamp: string;
  subject: string;
  formId: string;
  correlationId: string;
  emailProvider: string;
  emailAddress: string;
  status: string;
};
