import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import html2canvas from 'html2canvas';
import Picker from 'react-month-picker';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { formatCurrency, formatPercentage } from '../../utility/formatting';
import './Dashboard.scss';
import { Module } from '../Module';
import { MonthlyGrowthChart } from './MonthlyGrowthChart';
import { MonthlySignupsChart } from './MonthlySignupsChart';
import { MonthlyCTPsChart } from './MonthlyCTPsChart';
import '../../../node_modules/react-month-picker/scss/month-picker.scss';

const pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  from: 'From',
  to: 'To',
};

export function Dashboard() {
  const [dateRange, setDateRange] = useState({ startDate: moment().startOf('month').format(), endDate: moment().endOf('month').format() });
  const [stats, statsLoading, statsLoaded] = useApi<TypeMePlease>(`/api/stats?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
  const currentMonth = moment(dateRange.endDate).format('MMMM');
  const currentYear = moment(dateRange.endDate).format('YYYY');

  const showElastic = moment(dateRange.endDate) < moment('2022-02-01');
  const showAddOnACV = moment(dateRange.endDate) > moment('2024-05-01');

  const monthPicker = useRef<TypeMePlease>();
  const modulesElRef = useRef<TypeMePlease>();

  const exportToCSV = (data: { [key: string]: any }[], dataType: string) => {
    if (data.length === 0) return;

    const headers = Object.keys(data[0]);
    const csvRows = [headers, ...data.map((row) => headers.map((header) => row[header]))];

    const csvContent = csvRows.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${dataType}_${currentMonth}_${currentYear}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Revoke the object URL after the download
  };

  const saveScreenshot = () => {
    html2canvas(modulesElRef.current, {
      backgroundColor: '#f9f9f9',
      width: 1912,
      height: 673,
      windowWidth: 1912,
      windowHeight: 673,
    }).then((canvas) => {
      let screenshotDate = moment(dateRange.endDate);
      if (screenshotDate > moment()) {
        screenshotDate = moment();
      }
      const link = document.createElement('a');
      link.download = `Dashboard-${screenshotDate.format('YYYY-MM-DD')}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className="dashboard">
      <Helmet>
        <title>GoFormz Tools</title>
      </Helmet>
      <div className="dashboard-header">
        <h3>Revenue Dashboard</h3>
        <div className="button-wrapper">
          <Picker
            years={{ min: 2012 }}
            ref={monthPicker}
            value={{ month: moment(dateRange.startDate).month() + 1, year: moment(dateRange.startDate).year() }}
            lang={pickerLang.months}
            onChange={(year, month) => {
              const monthDate = moment([year, month - 1, 1]);
              setDateRange({ startDate: monthDate.startOf('month').format(), endDate: monthDate.endOf('month').format() });
              monthPicker.current.dismiss();
            }}
          >
            <div style={{ width: '296px', textAlign: 'right' }}>
              <Button onClick={() => monthPicker.current.show()}>{moment(dateRange.startDate).format('MMMM YYYY')}</Button>
            </div>
          </Picker>

          <Button icon="camera retro" title="Save Screenshot" onClick={() => saveScreenshot()} />
        </div>
      </div>
      <div className="modules-wrapper" ref={modulesElRef}>
        <div className="modules">
          <Module title="Monthly Growth">
            {statsLoaded && !statsLoading ? (
              <MonthlyGrowthChart
                width={268}
                height={210}
                coreNew={stats.core.monthly.newACV}
                coreNet={stats.core.monthly.net}
                coreChurn={stats.core.monthly.churn}
                elasticNew={stats.elastic.monthly.newACV}
                elasticNet={stats.elastic.monthly.net}
                elasticChurn={stats.elastic.monthly.churn}
                showElastic={showElastic}
              />
            ) : (
              <Loader isLoading />
            )}
          </Module>
          <Module title={showElastic ? 'Core Revenue' : 'Revenue'}>
            {statsLoaded && !statsLoading ? (
              <>
                <h4>Monthly Growth</h4>
                New:{' '}
                <span style={{ color: 'green' }}>
                  <b>{formatCurrency(stats.core.monthly.newACV)}</b> ({formatPercentage(stats.core.monthly.newPercentage)})
                </span>
                <br />
                Churn:{' '}
                <span style={{ color: 'red' }}>
                  <b>{formatCurrency(stats.core.monthly.churn)}</b> ({formatPercentage(stats.core.monthly.churnPercentage)})
                </span>
                <br />
                <div>
                  Net:{' '}
                  <span style={{ color: stats.core.monthly.net < 0 ? 'red' : 'green' }}>
                    <b>{formatCurrency(stats.core.monthly.net)}</b> ({formatPercentage(stats.core.monthly.netPercentage)})
                  </span>
                  <br />
                </div>
                Total:{' '}
                <span style={{ color: 'green' }}>
                  <b>{formatCurrency(stats.core.monthly.acv)}</b>
                </span>
                <h4>Per Billing Period</h4>
                Monthly: {/* @ts-ignore */}
                <font color="green">
                  <b>{formatCurrency(stats.core.monthlyTotalACV)}</b> ({formatPercentage(stats.core.monthlyTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                </font>
                <br />
                Annual: {/* @ts-ignore */}
                <font color="green">
                  <b>{formatCurrency(stats.core.annualTotalACV)}</b> ({formatPercentage(stats.core.annualTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                </font>
                <br />
                Other: {/* @ts-ignore */}
                <font color="green">
                  <b>{formatCurrency(stats.core.otherTotalACV)}</b> ({formatPercentage(stats.core.otherTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                </font>
              </>
            ) : (
              <Loader isLoading />
            )}
          </Module>
          <Module title={showElastic ? 'Core Account Stats' : 'Account Stats'}>
            {statsLoaded && !statsLoading ? (
              <>
                Total Paid Accounts: {stats.core.totalAccounts.toLocaleString()}
                <br />
                Total Paid Users: {stats.core.totalUsers.toLocaleString()}
                <br />
                Avg User Price: {formatCurrency(stats.core.totalACV / 12 / stats.core.totalUsers)}
                <br />
                Monthly Subscriptions: {stats.core.monthlyTotalAccounts.toLocaleString()}{' '}
                <span style={{ color: '#666' }}>({formatPercentage(stats.core.monthlyTotalAccounts / stats.core.totalAccounts)})</span>
                <br />
                Annual Subscriptions: {stats.core.annualTotalAccounts.toLocaleString()}{' '}
                <span style={{ color: '#666' }}>({formatPercentage(stats.core.annualTotalAccounts / stats.core.totalAccounts)})</span>
                <br />
                Other Subscriptions: {stats.core.otherTotalAccounts.toLocaleString()}{' '}
                <span style={{ color: '#666' }}>({formatPercentage(stats.core.otherTotalAccounts / stats.core.totalAccounts)})</span>
              </>
            ) : (
              <Loader isLoading />
            )}
          </Module>
          {showElastic ? (
            <Module title="Elastic Revenue">
              {statsLoaded && !statsLoading ? (
                <>
                  <h4>Monthly Growth</h4>
                  New:{' '}
                  <span style={{ color: 'green' }}>
                    <b>{formatCurrency(stats.elastic.monthly.newACV)}</b> ({formatPercentage(stats.elastic.monthly.newPercentage)})
                  </span>
                  <br />
                  Churn:{' '}
                  <span style={{ color: 'red' }}>
                    <b>{formatCurrency(stats.elastic.monthly.churn)}</b> ({formatPercentage(stats.elastic.monthly.churnPercentage)})
                  </span>
                  <br />
                  <div>
                    Net:{' '}
                    <span style={{ color: stats.elastic.monthly.net < 0 ? 'red' : 'green' }}>
                      <b>{formatCurrency(stats.elastic.monthly.net)}</b> ({formatPercentage(stats.elastic.monthly.netPercentage)})
                    </span>
                    <br />
                  </div>
                  Total:{' '}
                  <span style={{ color: 'green' }}>
                    <b>{formatCurrency(stats.elastic.monthly.acv)}</b>
                  </span>
                  <h4>Per Billing Period</h4>
                  Monthly: {/* @ts-ignore */}
                  <font color="green">
                    <b>{formatCurrency(stats.core.monthlyTotalACV)}</b> ({formatPercentage(stats.core.monthlyTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                  </font>
                  <br />
                  Annual: {/* @ts-ignore */}
                  <font color="green">
                    <b>{formatCurrency(stats.core.annualTotalACV)}</b> ({formatPercentage(stats.core.annualTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                  </font>
                  <br />
                  Other: {/* @ts-ignore */}
                  <font color="green">
                    <b>{formatCurrency(stats.core.otherTotalACV)}</b> ({formatPercentage(stats.core.otherTotalACV / stats.core.totalACV)}){/* @ts-ignore */}
                  </font>
                </>
              ) : (
                <Loader isLoading />
              )}
            </Module>
          ) : null}
          {showElastic ? (
            <Module title="Elastic Account Stats">
              {statsLoaded && !statsLoading ? (
                <>
                  Total Paid Accounts: {stats.elastic.totalAccounts.toLocaleString()}
                  <br />
                  Total Paid Users: {stats.elastic.totalUsers.toLocaleString()}
                  <br />
                  Avg User Price: {formatCurrency(stats.elastic.totalACV / 12 / stats.elastic.totalUsers)}
                  <br />
                  Monthly Subscriptions: {stats.elastic.monthlyTotalAccounts.toLocaleString()}{' '}
                  <span style={{ color: '#666' }}>({formatPercentage(stats.elastic.monthlyTotalAccounts / stats.elastic.totalAccounts)})</span>
                  <br />
                  Annual Subscriptions: {stats.elastic.annualTotalAccounts.toLocaleString()}{' '}
                  <span style={{ color: '#666' }}>({formatPercentage(stats.elastic.annualTotalAccounts / stats.elastic.totalAccounts)})</span>
                  <br />
                  Other Subscriptions: {stats.elastic.otherTotalAccounts.toLocaleString()}{' '}
                  <span style={{ color: '#666' }}>({formatPercentage(stats.elastic.otherTotalAccounts / stats.elastic.totalAccounts)})</span>
                </>
              ) : (
                <Loader isLoading />
              )}
            </Module>
          ) : null}
          {showAddOnACV && (
            <Module title="Add On Revenue">
              {statsLoaded && !statsLoading ? (
                <>
                  <h4>Monthly Growth</h4>
                  New:{' '}
                  <span style={{ color: 'green' }}>
                    <b>{formatCurrency(stats.addOn.monthly.newACV)}</b> ({formatPercentage(stats.addOn.monthly.newPercentage)})
                  </span>
                  <br />
                  Churn:{' '}
                  <span style={{ color: 'red' }}>
                    <b>{formatCurrency(stats.addOn.monthly.churn)}</b> ({formatPercentage(stats.addOn.monthly.churnPercentage)})
                  </span>
                  <br />
                  <div>
                    Net:{' '}
                    <span style={{ color: stats.addOn.monthly.net < 0 ? 'red' : 'green' }}>
                      <b>{formatCurrency(stats.addOn.monthly.net)}</b> ({formatPercentage(stats.addOn.monthly.netPercentage)})
                    </span>
                    <br />
                  </div>
                  Total:{' '}
                  <span style={{ color: 'green' }}>
                    <b>{formatCurrency(stats.addOn.monthly.acv)}</b>
                  </span>
                </>
              ) : (
                <Loader isLoading />
              )}
            </Module>
          )}
          <Module title="Other Revenue">
            {statsLoaded && !statsLoading ? (
              <>
                <div className="section">
                  <h4>Public Form Credits</h4>
                  Revenue: {formatCurrency(stats.publicFormsStats.currentMonthCreditRevenue)} ({formatCurrency(stats.publicFormsStats.priorMonthCreditRevenue)})
                  <br />
                  Credits: {stats.publicFormsStats.currentMonthCreditsPurchased.toLocaleString()} (
                  {stats.publicFormsStats.priorMonthCreditsPurchased.toLocaleString()})
                  <br />
                  Average Price:{' '}
                  {formatCurrency(
                    stats.publicFormsStats.currentMonthCreditRevenue && stats.publicFormsStats.currentMonthCreditsPurchased
                      ? stats.publicFormsStats.currentMonthCreditRevenue / stats.publicFormsStats.currentMonthCreditsPurchased
                      : 0
                  )}{' '}
                  (
                  {formatCurrency(
                    stats.publicFormsStats.priorMonthCreditRevenue && stats.publicFormsStats.priorMonthCreditsPurchased
                      ? stats.publicFormsStats.priorMonthCreditRevenue / stats.publicFormsStats.priorMonthCreditsPurchased
                      : 0
                  )}
                  )
                </div>
                <div className="section">
                  <h4>Professional Services Revenue</h4>
                  Revenue: {formatCurrency(stats.proServiceStats.currentMonthAmount)} ({formatCurrency(stats.proServiceStats.priorMonthAmount)})
                </div>
              </>
            ) : (
              <Loader isLoading />
            )}
          </Module>
          <Module title="New Monthly Signups (Trials)">
            {statsLoaded && !statsLoading ? (
              <>
                <div style={{ marginBottom: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <b>Monthly Running Total</b>: {stats.accountStats.currentMonthNewSignups.toLocaleString()} (
                  {stats.accountStats.priorMonthNewSignups.toLocaleString()})
                  <Button
                    icon="download"
                    title="Export to CSV"
                    onClick={() => exportToCSV(stats.accountStats.currentSignupsByClient, 'Signups')}
                    style={{ marginLeft: 'auto' }}
                  />
                </div>
                <MonthlySignupsChart dailySignups={stats.accountStats.dailySignups} width={900} height={220} />
              </>
            ) : (
              <Loader isLoading />
            )}
          </Module>
          <Module title="New Monthly CTPs (Convert-to-Paid)">
            {statsLoaded && !statsLoading ? (
              <>
                <div style={{ marginBottom: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <b>Monthly Running Total</b>: {stats.accountStats.currentMonthConvertToPaid} ({stats.accountStats.priorMonthConvertToPaid})
                  <Button
                    icon="download"
                    title="Export to CSV"
                    onClick={() => exportToCSV(stats.accountStats.currentCTPsByClient, 'CTPs')}
                    style={{ marginLeft: 'auto' }}
                  />
                </div>
                <MonthlyCTPsChart dailyCTPs={stats.accountStats.dailyCTPs} width={900} height={220} />
              </>
            ) : (
              <Loader isLoading />
            )}
          </Module>
        </div>
      </div>
    </div>
  );
}
