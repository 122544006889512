import React, { useState, useCallback } from 'react';
import DayPicker, { RangeModifier } from 'react-day-picker';
import Navbar from './NavBar';
import YearMonthForm from './YearMonthForm';
import ErrorBoundary from './ErrorBoundary';
import './DayPicker.scss';

export interface DateRangePickerProps {
    initialStartDate: Date;
    initialEndDate: Date;
    onRangeChange?: (range: RangeModifier) => void;
}

export default function DateRangePicker(props: DateRangePickerProps) {
    const { initialStartDate, initialEndDate, onRangeChange } = props;
    const [from, setFrom] = useState<Date | null>(initialStartDate);
    const [to, setTo] = useState<Date | null>(initialEndDate);
    const [month, setMonth] = useState<Date | undefined>(undefined);

    const handleDayClicked = useCallback(
        (day) => {
            const range = DayPicker.DateUtils.addDayToRange(day, { from, to });
            setFrom(range.from);
            setTo(range.to);
            onRangeChange?.(range);
        },
        [from, to, onRangeChange]
    );

    const handleResetClick = useCallback(() => {
        setFrom(null);
        setTo(null);
    }, []);

    const handleYearMonthChange = useCallback((month) => {
        setMonth(month);
    }, []);

    const modifiers = { start: from, end: to };

    return (
        <ErrorBoundary>
            <p>
                {!from && !to && 'Please select the first day.'}
                {from && !to && 'Please select the last day.'}
                {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                  ${to.toLocaleDateString()}`}{' '}
                {from && to && (
                    <button className="link-button" onClick={handleResetClick}>
                        Reset
                    </button>
                )}
            </p>
            <DayPicker
                modifiers={modifiers}
                month={month}
                initialMonth={to || new Date()}
                selectedDays={[from, { from, to }]}
                onDayClick={handleDayClicked}
                navbarElement={({ nextMonth, previousMonth, onPreviousClick, onNextClick, className, localeUtils }) => (
                    <Navbar
                        nextMonth={nextMonth}
                        previousMonth={previousMonth}
                        onPreviousClick={onPreviousClick}
                        onNextClick={onNextClick}
                        className={className}
                        localeUtils={localeUtils}
                        onChange={handleYearMonthChange}
                    />
                )}
                captionElement={({ date, localeUtils }) => <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />}
            />
        </ErrorBoundary>
    );
}