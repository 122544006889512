import React from 'react';
import { ActionMenuItemProps } from "./actionMenu-types";

export default function ActionMenuItem({ text, action, disabled } : ActionMenuItemProps)  {
  return (
    <div 
      className={`action-menu-item item ${disabled ? 'disabled' : ''}`} 
      onClick={disabled ? undefined : action}
    >
      {text}
    </div>
  )
}