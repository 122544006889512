import React, { useState, useEffect, useRef } from 'react';
import SearchInput from '../../helpers/SearchInput';
import { useApim } from '../../hooks/useApi';
import { getApiErrorMessage } from '../../utility/api';
import './WorkflowCopy.scss';
import { Input } from 'semantic-ui-react';
import axios, { AxiosResponse } from 'axios';

export type UserSelectProps = {
  label: string;
  onChange: AnyFunc;
  disabled: boolean;
  empty?: boolean;
  inputRef: TypeMePlease;
  isPendingUser: AnyFunc;
  updatePendingUser: (isPending: boolean) => void;
};

export type UserMapping = {
  username: string;
  clientId: string;
  userId: string;
  independentUser: boolean;
};

export type UserDetails = {
  devices: object[];
  user: {
    id: string; //clientId
    username: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    groupName: string;
    createdDate: Date;
    deactivatedDate: Date;
    pending: boolean;
  };
};

export function UserSelect(props: UserSelectProps) {
  const { label, onChange, disabled, empty, inputRef, isPendingUser, updatePendingUser } = props;
  const [username, setUsername] = useState('');
  const [clientId, setClientId] = useState('');
  const [name, setName] = useState('');
  const [independentUser, setIndependentUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userMappings, mappingsLoading, mappingsLoaded, refresh, setResult, error] = useApim<UserMapping[]>(
    `/usermappings?username=${encodeURIComponent(username)}`,
    null,
    null,
    !username,
    'GET'
  );
  const [clientIdLoading, setClientIdLoading] = useState(false);

  const defaultInputRef = useRef<TypeMePlease>();
  const innerInputRef = inputRef || defaultInputRef;

  useEffect(() => {
    if (empty) {
      reset();
      // if never searched, setting to empty string doesn't trigger id change - force that by resetting again to null
      setUsername(null);
      setIndependentUser(false);
    }
  }, [empty]);

  useEffect(() => {
    if (error) {
      setName('');
      setErrorMessage(getApiErrorMessage(error));
      onChange(null);
    } else if (userMappings) {
      setErrorMessage('');
      const userMapping = userMappings.find((x) => x.username === username);
      if (userMapping) {
        if (userMapping.independentUser) {
          setIndependentUser(true);
        } else {
          setName(userMapping.clientId);
          onChange(userMapping);
          setIndependentUser(false);
          isPendingUser(userMapping.userId, userMapping.clientId).then((pending: boolean) => {
            setErrorMessage(pending ? 'Failed: this is a pending user' : '');
            updatePendingUser(pending);
          });
        }
      } else {
        setErrorMessage('Not found');
      }
    }
  }, [userMappings, error]);

  useEffect(() => {
    if (!username) {
      reset();
    }
  }, [username]);

  const reset = () => {
    setUsername('');
    setClientId('');
    setIndependentUser(false);
    setName('');
    setErrorMessage('');
    innerInputRef.current.focus();
  };

  const lookupUserId = () => {
    if (clientId && username) {
      setClientIdLoading(true);
      getUserId(clientId, username).then((userId: string) => {
        if (userId) {
          setErrorMessage('');
          const mapping = {
            clientId: clientId,
            userId: userId,
            username: username,
          };
          onChange(mapping);
        } else {
          setErrorMessage('Unable to find User Id from that Email Address and Client Id');
        }
        setClientIdLoading(false);
      });
    }
  };

  const getUserId = async (clientId, username) => {
    const userDetails = await searchUsers(clientId, username);
    if (userDetails && userDetails?.users[0]?.id) {
      return userDetails.users[0].id;
    }
    return null;
  };

  const searchUsers = async (clientId: string, username: string) => {
    try {
      const userDetails: AxiosResponse = await axios.get(`apim?uri=${encodeURIComponent(`/users/search?q=${username}`)}`, {
        headers: {
          'X-Client-Id': clientId,
        },
      });
      if (userDetails.data) {
        return userDetails.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="client-container">
      <div className={`client-label ${disabled ? 'disabled-label' : ''}`}>{label}</div>
      <SearchInput
        placeholder="Username"
        width="client-width"
        searchFilter={username}
        updateSearchFilter={setUsername}
        onBlur={setUsername}
        searching={mappingsLoading}
        disabled={disabled}
        inputRef={innerInputRef}
      />
      <br />
      <br />
      {independentUser ? (
        <>
          <Input
            placeholder="Client Id"
            className="client-width"
            onChange={(e) => setClientId(e.target.value)}
            onBlur={() => lookupUserId()}
            loading={clientIdLoading}
          />
          {!clientId ? <div className="error">This is an Independent User. Please include the ClientId of the account</div> : null}
        </>
      ) : (
        ''
      )}
      {name && !independentUser ? <div className={`client-name ${disabled ? 'disabled-label' : ''}`}>{name}</div> : ''}
      {errorMessage ? <div className={`error ${disabled ? 'disabled-label' : ''}`}>{errorMessage}</div> : ''}
    </div>
  );
}
