import React from 'react';
import { Checkbox, Dropdown, Input, TextArea } from 'semantic-ui-react';
import { FeatureFlagDataType } from './types';
import './FeatureFlagValue.scss';

interface FeatureFlagValueProps { 
  dataType: FeatureFlagDataType;
  value: string | boolean | number;
  disabled: boolean;
  onChange: (value: string | boolean | number) => void
}

export function FeatureFlagValue(props: FeatureFlagValueProps) {

  const booleanOptions = [
    { key: 'null', text: 'NULL', value: 'null'},
    { key: 'true', text: 'TRUE', value: 'true'},
    { key: 'false', text: 'FALSE', value: 'false' }
  ]

  const getBooleanValue = (value: string) => { 
    return value === 'null' ? null 
      : value === 'true' ? true
      : false;
  }

  return (
    <div className="feature-flag-value">
      {props.dataType == null || props.dataType === FeatureFlagDataType.BoolValue 
        && <Dropdown className="data-type-bool-value" title="Bool Value"
                selection
                fluid
                value={props.value?.toString()?.toLowerCase() ?? 'null'}
                onChange={(_, data) => props.onChange(getBooleanValue(data.value?.toString()))}
                options={booleanOptions}
                disabled={props.disabled}/>
      }
      {props.dataType === FeatureFlagDataType.NumberValue 
        && <Input className="data-type-int-value" title="Int Value"
          fluid type="number" step={1} value={props.value ?? ''} onChange={(_, data) => props.onChange(data.value === "" ? null : data.value === "-"  ? data.value : Number.parseInt(data.value))} disabled={props.disabled} />}
      {props.dataType === FeatureFlagDataType.DecimalValue 
        && <Input type="number" className="data-type-decimal-value" title="Decimal Value"
          fluid step={0.01} value={props.value ?? ''} onChange={(_, data) => props.onChange(data.value === "" ? null: data.value.endsWith(".") || data.value === "-" ? data.value : Number.parseFloat(data.value))} disabled={props.disabled} />}
      {props.dataType === FeatureFlagDataType.JsonValue 
        && <TextArea className="data-type-json-value" title="JSON Value"
        value={props.value?.toString() ?? ''} onChange={(_, data) => props.onChange(data.value)} disabled={props.disabled} />}
      {props.dataType === FeatureFlagDataType.StringValue
          && <TextArea className="data-type-string-value" title="String Value"
       value={props.value?.toString() ?? ''} onChange={(_, data) => props.onChange(data.value)} disabled={props.disabled} />}
    </div>
  );
}