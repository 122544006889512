import React, { useLayoutEffect, useRef } from 'react';
import Chart from 'chart.js';
import { formatCurrency } from '../../utility/formatting';

export type MonthlyGrowthChartProps = {
  coreNew: number;
  coreNet: number;
  coreChurn: number;
  elasticNew: number;
  elasticNet: number;
  elasticChurn: number;
  showElastic: boolean;

  height?: number;
  width?: number;
};

export function MonthlyGrowthChart(props: MonthlyGrowthChartProps) {
  const { coreNew, coreNet, coreChurn, elasticNew, elasticNet, elasticChurn, showElastic } = props;
  const chartEl = useRef<TypeMePlease>(null);
  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'bar',
        options: {
          aspectRatio: chartEl.current.offsetWidth / chartEl.current.offsetHeight,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  callback: function (value) {
                    return formatCurrency(value);
                  },
                },
              },
            ],
          },
        },
      });
    }

    // Reset chart to empty
    chart.current.data.labels = showElastic ? ['Core', 'Elastic'] : [''];
    chart.current.data.datasets = [];

    chart.current.data.datasets.push({
      stack: 'Stack 0',
      label: 'New',
      backgroundColor: '#7fb56d',
      data: showElastic ? [coreNew, elasticNew] : [coreNew],
    });

    chart.current.data.datasets.push({
      stack: 'Stack 0',
      label: 'Churn',
      backgroundColor: '#d06973',
      data: showElastic ? [coreChurn, elasticChurn] : [coreChurn],
    });

    chart.current.data.datasets.push({
      stack: 'Stack 1',
      label: 'Net',
      backgroundColor: '#54a1e5',
      data: showElastic ? [coreNet, elasticNet] : [coreNet],
    });

    // Redraw the chart
    chart.current.update();
  }, [coreNew, coreNet, coreChurn, elasticNew, elasticNet, elasticChurn]);

  return (
    <div className="chart" ref={chartEl} style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
