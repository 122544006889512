import React from 'react';
import { ChooseJourneyStep, CompletedItem } from '../../uses-types';
import { CompleteMarker } from './CompleteMarker';

export type StatusNodeProps = {
  completedItems: CompletedItem;
  step: ChooseJourneyStep;
};

export function StatusNode({ step, completedItems }: StatusNodeProps) {
  return (
    <div className="status-node">
      <h4>{step.text}</h4>
      {Object.entries(step.checklist.items).map(([step, item]) => (
        <div key={step} className="status-node-marker">
          {/* completed items can be undefined if they have not gone through any steps */}
          <CompleteMarker completed={!!(completedItems && completedItems[step])} />
          &nbsp;{item.text}
        </div>
      ))}
    </div>
  );
}
