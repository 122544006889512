import React, { useState } from 'react';
import { useApim } from '../../hooks/useApi';
import { Dropdown, Button, Checkbox } from 'semantic-ui-react';
import { useMessages } from '../../context/MessagesContext';
import moment from 'moment';
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
import { apimCall } from '../../utility/api';
import { deepClone } from '../../utility/deepClone';

export type DataSourcesProps = {
  id: string;
  account: TypeMePlease;
};

export default function DataSources(props: DataSourcesProps) {
  const { id, account } = props;
  const [showDeleted, setShowDeleted] = useState(false);
  const [dataSourceResult, dataSourceLoading, dataSourceLoaded, refreshDataSources, setDataSources] = useApim<TypeMePlease>(
    `/datasources?includeDeleted=${showDeleted}`,
    id,
    null
  );

  const setDataSourceDeletedDate = (id, value = null) => {
    const newDataSourceResult = deepClone(dataSourceResult);
    const dataSource = newDataSourceResult.find((x) => x.id === id);
    dataSource.deletedDate = value;
    setDataSources(newDataSourceResult);
  };

  return (
    <div className="datasources">
      <Helmet>
        <title>{account.name} - DataSources</title>
      </Helmet>

      <Checkbox toggle label="Show Deleted DataSources" checked={showDeleted} onChange={(e, data) => setShowDeleted(!showDeleted)} />
      <table className="ui table">
        <thead>
          <tr>
            <th>DataSource Name</th>
            <th>Created Date</th>
            <th>Last Updated Date</th>
            <th>Deleted Date</th>
            <th>DataSource Id</th>
            <th>Offline</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dataSourceLoaded
            ? dataSourceResult.map((dataSource) => {
                return <DataSource key={dataSource.id} dataSource={dataSource} clientId={id} setDataSourceDeletedDate={setDataSourceDeletedDate} />;
              })
            : null}
        </tbody>
      </table>
      <Loader isLoading={dataSourceLoading} />
    </div>
  );
}

function DataSource(props) {
  const { clientId, dataSource, setDataSourceDeletedDate } = props;
  const { name, createdDate, lastUpdatedDate, deletedDate, id, copyLocal } = dataSource;
  const [actionLoading, setActionLoading] = useState(false);
  const { showMessage } = useMessages();

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  const restore = async () => {
    setActionLoading(true);

    try {
      const result = await apimCall(
        `/datasources/${id}/restore`,
        'PUT',
        {
          deletedDate: deletedDate,
        },
        clientId
      );

      if (result.statusText === 'OK') {
        setDataSourceDeletedDate(id);
      } else {
        showMessage(`Unable to restore DataSource.`);
      }
    } catch (error) {
      console.log(error);
    }
    setActionLoading(false);
  };

  return (
    <tr>
      <td>{name}</td>
      <td>{moment.utc(createdDate).local().calendar(null, calendarOptions)}</td>
      <td>{moment.utc(lastUpdatedDate).local().calendar(null, calendarOptions)}</td>
      <td>{deletedDate != null ? moment.utc(deletedDate).local().calendar(null, calendarOptions) : null}</td>
      <td>{id}</td>
      <td>{copyLocal.toString()}</td>
      <td>
        {deletedDate != null ? (
          <Dropdown trigger={<Button icon="ellipsis horizontal" loading={actionLoading} />} pointing="right" icon={null}>
            <Dropdown.Menu>
              <Dropdown.Item text="Restore" icon="configure" onClick={restore} />
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </td>
    </tr>
  );
}
