import React, { createContext, useContext, useEffect } from 'react';
import { useApi } from '../hooks/useApi';

export type ProfileContextValue = {
  profileLoading: boolean;
  profileLoaded: boolean;
  profile?: TypeMePlease;
};

const ProfileContext = createContext<ProfileContextValue>({ profileLoaded: false, profileLoading: false });

let _profile = null;

export function getProfile() {
  return _profile;
}

export function ProfileProvider(props) {
  const [profile, profileLoading, profileLoaded, refresh, setResult, error] = useApi<TypeMePlease>('/api/profile');

  useEffect(() => {
    if (error) {
      window.location.href = `/ensureloggedin?url=${window.location.href}`;
    }
  }, [error]);

  if (profileLoaded) {
    _profile = profile;
  }

  return <ProfileContext.Provider value={{ profile, profileLoading, profileLoaded }} {...props} />;
}

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error(`useProfile must be used within a ProfileProvider`);
  }
  return context;
}
