import React from 'react';
import { TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import './ClientAddOnsTableHeader.scss';

export function ClientAddOnsTableHeader() {
  return (
    <TableHeader className='client-add-ons-table-header'>
      <TableRow className="client-add-ons-table-header-row">
        <TableHeaderCell className='client-add-ons-table-header-value add-on-title'>Add On</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-price'>Pricing Type</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-price'>Monthly Price</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-price'>Annual Price</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-user-limit'>User Limit</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-user-limit'>Users Assigned</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-user-limit'>Pending Unsubscribe Date</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-user-limit'>Effective Unsubscribe Date</TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-metadata'></TableHeaderCell>
        <TableHeaderCell className='client-add-ons-table-header-value add-on-action-menu'></TableHeaderCell>
      </TableRow>
    </TableHeader>
  );
}