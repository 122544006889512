import React from 'react';
import Axios from 'axios';
import { deepClone } from '../../../utility/deepClone';
import AppConfig from '../AppConfig';
import AuthenticationOption from '../AuthenticationOption';
import { Button } from 'semantic-ui-react';

export interface DetailsProps {
  appInfo: any;
  setAppInfo: (property: string, value: any) => void;
  onSave: () => void;
  isSaving: boolean;
}

export default function Details(props: DetailsProps) {
  const handleIcon = async (file) => {
    const formData = new FormData();
    formData.append('icon', file);
    formData.append('appName', props.appInfo.appName);
    const res = await Axios.post(`/api/app/uploadicon`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const random = Math.floor(Math.random() * 1000000);
    props.setAppInfo('icon', `${res.data.uri}?rnd=${random}`);
  };

  const setConfigs = (index, key, value) => {
    const configs = deepClone(props.appInfo.configs);
    const config = configs[index];
    config[key] = value;
    configs[index] = config;
    props.setAppInfo('configs', configs);
  };

  const addConfig = () => {
    const configs = deepClone(props.appInfo.configs);
    configs.push({
      key: '',
      description: '',
      configType: 'general',
      secret: false,
      required: false,
    });
    props.setAppInfo('configs', configs);
  };

  const removeConfig = (index) => {
    const configs = deepClone(props.appInfo.configs);
    configs.splice(index, 1);
    props.setAppInfo('configs', configs);
  };

  const setAuthOption = (index?: number, key?: string, value?: any, isAuthInfo = false) => {
    const authOptions = deepClone(props.appInfo.authenticationOptions);
    const option = authOptions[index];

    if (isAuthInfo) {
      if (!option.authInfo) {
        option.authInfo = {
          clientId: '',
          clientSecret: '',
          scope: '',
          authorizeUrl: '',
          authorizationCodeUrl: '',
          refreshTokenUrl: '',
        };
      }
      option.authInfo[key] = value;
    } else {
      option[key] = value;
    }

    authOptions[index] = option;
    props.setAppInfo('authenticationOptions', authOptions);
  };

  const addAuthOption = () => {
    const authOptions = deepClone(props.appInfo.authenticationOptions);
    authOptions.push({
      name: '',
      type: 'simple',
      associatedConfigs: [],
      secret: false,
      required: false,
      isDefault: false,
      authInfo: {
        clientId: '',
        clientSecret: '',
        scope: '',
        authorizeUrl: '',
        authorizationCodeUrl: '',
        refreshTokenUrl: '',
      },
    });
    props.setAppInfo('authenticationOptions', authOptions);
  };

  const removeAuthOption = (index) => {
    const authOptions = deepClone(props.appInfo.authenticationOptions);
    authOptions.splice(index, 1);
    props.setAppInfo('authenticationOptions', authOptions);
  };

  return (
    <div className="ui form">
      <div className="two fields">
        <div className="field">
          <label>Name</label>
          <input type="text" value={props.appInfo.appName} onChange={(e) => props.setAppInfo('appName', e.target.value)} />
        </div>

        <div className="field">
          <label>Description</label>
          <input type="text" value={props.appInfo.description} onChange={(e) => props.setAppInfo('description', e.target.value)} />
        </div>
      </div>

      <div className="two fields">
        <div className="field">
          <label>Author</label>
          <input type="text" value={props.appInfo.author} onChange={(e) => props.setAppInfo('author', e.target.value)} />
        </div>

        <div className="field">
          <label>Icon (40x40)</label>
          <input type="file" name="icon" id="icon" onChange={(e) => handleIcon(e.target.files[0])} />
        </div>
      </div>

      <div className="field">
        <label>
          Scope
          <input type="text" value={props.appInfo.scopes ?? ''} onChange={(e) => props.setAppInfo('scopes', e.target.value.split(','))} />
        </label>
        <span>Determines which app runtimes can execute the app</span>
      </div>

      <h3 className="ui dividing header">Configs</h3>

      <div id="configs">
        {props.appInfo.configs.map((config, index) => {
          return (
            <AppConfig
              key={index}
              configKey={config.key}
              description={config.description}
              configType={config.configType}
              required={config.required}
              secret={config.secret}
              setConfigs={(key, value) => setConfigs(index, key, value)}
              removeConfig={() => removeConfig(index)}
            />
          );
        })}
      </div>

      <button type="button" id="addConfig" className="ui button" onClick={addConfig}>
        Add Config
      </button>

      <h3 className="ui dividing header">Authentication Options</h3>

      <div id="authenticationoptions">
        {props.appInfo.authenticationOptions.map((option, index) => {
          return (
            <AuthenticationOption
              key={index}
              name={option.name}
              type={option.type}
              associatedConfigs={option.associatedConfigs}
              authInfo={option.hasOwnProperty('authInfo') ? option.authInfo : null}
              isDefault={option.isDefault}
              index={index}
              setAuthOption={(index, key, value, isAuthInfo) => setAuthOption(index, key, value, isAuthInfo)}
              removeAuthOption={removeAuthOption}
            />
          );
        })}
      </div>
      <button type="button" id="addAuthenticationOption" className="ui button" onClick={addAuthOption}>
        Add Authentication Option
      </button>

      <h3 className="ui dividing header">Restrict by Client</h3>

      <div className="one field">
        <div className="field">
          <label>Client Ids</label>
          <input
            type="text"
            value={props.appInfo.restrictedClientIds ?? ''}
            onChange={(e) => props.setAppInfo('restrictedClientIds', e.target.value.split(','))}
          />
          <span className="">enter list of client id's separated by commas</span>
        </div>
      </div>

      <br />
      <br />
      <Button className="primary" onClick={props.onSave} loading={props.isSaving} disabled={props.isSaving}>
        Save
      </Button>
    </div>
  );
}
