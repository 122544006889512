import { useApi } from '../../../hooks/useApi';
import Loader from '../../Loader';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';

export type FormDetailsProps = {
  id: string;
};

export default function FormDetails(props: FormDetailsProps) {
  const { id } = props;

  const url = window.location.pathname;
  const formId = url.substring(url.lastIndexOf('/') + 1);

  const [formResult, formLoading, formLoaded] = useApi<TypeMePlease>(`api/account/${id}/forms/${formId}`);
  const extraProperties = formLoaded ? JSON.parse(formResult.extraProperties) : null;

  return (
    <div>
      <NavLink to={`/account/${id}/forms`} end>
        Back to Forms List
      </NavLink>
      {formLoaded ? (
        <table className="ui celled striped table">
          <tbody>
            <tr>
              <td>Form Name</td>
              <td>{formResult.name}</td>
            </tr>
            <tr>
              <td>Id</td>
              <td>{formResult.id}</td>
            </tr>
            <tr>
              <td>Owner</td>
              <td>{formResult.groupName ? formResult.groupName : formResult.ownerUsername}</td>
            </tr>
            <tr>
              <td>Created By</td>
              <td>{formResult.createdByUsername}</td>
            </tr>
            <tr>
              <td
                data-tooltip="This is the date and time the form was synced/created in SQL. It could have been created at an earlier time in the mobile apps"
                data-position="top left"
              >
                Created Date <i className="info blue circle icon"></i>
              </td>
              <td>{moment.utc(formResult.createdDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
            </tr>
            <tr>
              <td>Last Updated By</td>
              <td>{formResult.lastUpdatedByUsername}</td>
            </tr>
            <tr>
              <td>Last Updated Date</td>
              <td>{moment.utc(formResult.lastUpdatedDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
            </tr>
            <tr>
              <td>Completed Date</td>
              <td>{formResult.completedDate ? moment.utc(formResult.completedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
            </tr>
            <tr>
              <td>Deleted Date</td>
              <td>{formResult.deletedDate ? moment.utc(formResult.deletedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
            </tr>
            <tr>
              <td>Template Name</td>
              <td>{formResult.templateName}</td>
            </tr>
            <tr>
              <td>Template Id</td>
              <td>{formResult.templateId}</td>
            </tr>
            <tr>
              <td>Transfer History</td>
              <td>{JSON.stringify(extraProperties.transferHistory)}</td>
            </tr>
            <tr>
              <td>Manual Name Override</td>
              <td>{extraProperties.ManualNameOverride ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Has Been Reopened</td>
              <td>{extraProperties.HasBeenReopened ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td data-tooltip="This is the date and time that the form was created on the mobile app. It could be synced later" data-position="top left">
                Mobile App Created Date <i className="info blue circle icon"></i>
              </td>
              <td>{extraProperties.UserSetCreatedDate ? moment.utc(extraProperties.UserSetCreatedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
            </tr>
            <tr>
              <td data-tooltip="This is set by the mobile app" data-position="top left">
                Mobile App Last Updated By <i className="info blue circle icon"></i>
              </td>
              <td>{extraProperties.UserSetLastUpdatedBy}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <Loader isLoading={formLoading} />
      )}
    </div>
  );
}
