import React from 'react';
import { Statistic, StatisticValue, StatisticLabel, CardDescription, CardContent, CardHeader, Card } from 'semantic-ui-react';
import './FeatureFlagExperimentStatistic.scss';
import { FeatureFlagExperimentAnalytics } from './types';

interface FeatureFlagExperimentStatisticProps { 
  title: string;
  analytics: FeatureFlagExperimentAnalytics;
}

export function FeatureFlagExperimentStatistic(props: FeatureFlagExperimentStatisticProps) {
  const { analytics: stats } = props;

  return (
    <div className="feature-flag-experiment-statistic">
      <Card>
        <CardContent>
          <CardHeader>{props.title}</CardHeader>
          <CardDescription>
            <Statistic size='mini'>
              <StatisticValue>{stats?.clients ?? 0}</StatisticValue>
              <StatisticLabel>Clients</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.totalAcv}</StatisticValue>
              <StatisticLabel>Total ACV</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.subscriptionAcv}</StatisticValue>
              <StatisticLabel>Subscription ACV</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.addOnAcv}</StatisticValue>
              <StatisticLabel>AddOn ACV</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.licenses ?? 0}</StatisticValue>
              <StatisticLabel>License Count</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.ctps ?? 0}</StatisticValue>
              <StatisticLabel>CTPs</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.pendingDowngradeToBasic ?? 0}</StatisticValue>
              <StatisticLabel>Pending Downgrades</StatisticLabel>
            </Statistic>
            <Statistic size='mini'>
              <StatisticValue>{stats?.unsubscribes ?? 0}</StatisticValue>
              <StatisticLabel>Unsubscribes</StatisticLabel>
            </Statistic>
          </CardDescription>
        </CardContent>
      </Card>
    </div>
  );
}