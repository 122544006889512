import { Icon, Loader } from 'semantic-ui-react';
import './ReleaseConnector.scss';
import React from 'react';

export type ReleaseConnectorProps = {
  deploying?: boolean;
}
export function ReleaseConnector(props: ReleaseConnectorProps) {
 
  return (
    <div className="release-connector">
      <Icon name='arrow right' />
      {props.deploying && <Loader active inline size='mini'>Deploying</Loader>}
      {!props.deploying && <Icon name='check circle' />}
      <Icon name='arrow right' />
    </div>
  );
}
