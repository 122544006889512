import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useApim } from '../../hooks/useApi';
import { AppFeatures, canIUse } from '../../utility/SecurityHelper';
import Loader from '../Loader';
import { AppsResponseBody } from './apps-types.d';
import './Apps.scss';

export function Apps() {
  const [appsResult, appsLoading, appsLoaded] = useApim<AppsResponseBody>('/apps');

  return (
    <div className="apps">
      <Helmet>
        <title>Apps</title>
      </Helmet>
      {canIUse(AppFeatures.EditApps) ? (
        <NavLink className="nav-link" to={'/Apps/Create'} end>
          Create New
        </NavLink>
      ) : null}
      {appsLoaded ? (
        <table className="ui celled striped compact table">
          <thead>
            <tr>
              <th></th>
              <th>App</th>
              <th>Description</th>
              <th>Availability</th>
            </tr>
          </thead>
          <tbody>
            {appsResult.data.items.map((app) => (
              <tr key={app.id}>
                <td width="50">
                  <img style={{ width: '40px', height: '40px' }} src={app.icon} />
                </td>
                <td>
                  {canIUse(AppFeatures.EditApps) ? (
                    <NavLink className="nav-link" to={`/Apps/Edit/${app.id}`} end>
                      {app.appName}
                    </NavLink>
                  ) : (
                    app.appName
                  )}
                </td>
                <td>{app.description}</td>
                <td>{!app.restrictedClientIds || app.restrictedClientIds.length === 0 ? 'All Accounts' : `${app.restrictedClientIds.length} Account(s)`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loader isLoading />
      )}
    </div>
  );
}
