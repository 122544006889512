import './DynamicTable.scss';
import React, { useEffect, useReducer, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { Button, Icon, Pagination, PaginationProps, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import Loader from '../Loader';
import Papa from "papaparse";
import { getPrettyHeader, getPrettyValue, sortReducer } from './DynamicTableFunctions';

interface DynamicTableProps<T> { 
  header: string;
  subHeader?: string;
  url?: string;
  data?: any;
  pageSize?: number;
  onRowClick?: (row: T) => void;
  hyperLinkColumns?: number[];
  onColumnClick?: (row: T, column: number) => void;
}

export function DynamicTable<T = any>(props: DynamicTableProps<T>) {
  const [apiResults, apiResultsLoading, apiResultsLoaded, _apiResultsRefresh, _apiResultsSetResults, apiResultsError] 
    = useApi<T[]>(props.url, props.data != null);
  const [page, setPage] = useState(1);
  
  const tableRows = apiResults || props.data;
  const safeTableRows = tableRows || [];
  const [sortState, sortDispatch] = useReducer(sortReducer, {
    column: null,
    data: safeTableRows,
    direction: null,
  });

  useEffect(() => {
    sortDispatch({ type: "REPLACE_STATE", payload: {
      column: null,
      data: safeTableRows,
      direction: null,
    }});
  }, [apiResults, props.data])

  const { column, direction } = sortState;
  const sortedData = sortState.data || [];
  const pageSize = props.pageSize || 20;
  const totalPages = Math.ceil(sortedData.length / pageSize);
  const renderedData = sortedData.slice((page - 1) * pageSize, page * pageSize);
  const headerKeys = sortedData.length > 0 ? Object.keys(sortedData[0]) : [];

  const onPageChange = (_event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => { 
    setPage(data.activePage as number);
  }

  const onDownloadClick = () => {
    const filename = `${props.header}.csv`;
  
    const csv = Papa.unparse({ data: sortedData, fields: headerKeys})
    if (csv == null) return;
  
    const blob = new Blob([csv]);
    const downloadElement = window.document.createElement("a");
        downloadElement.href = window.URL.createObjectURL(blob);
        downloadElement.download = filename;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
  }


  const tableHeaders = headerKeys.map(key => {
    const prettyHeader = getPrettyHeader(key);
    
    return (
      <TableHeaderCell key={`dynamic-table-header-${key}`} className='dynamic-table=header'
        sorted={column === key ? direction : null}
        onClick={() => sortDispatch({ type: 'CHANGE_SORT', column: key })}>{prettyHeader}</TableHeaderCell>
      );
  });
  
  const tableCells = renderedData.map((row, rowIndex) => { 
    const rowCells = headerKeys.map((key, columnIndex) => (
      <TableCell 
        key={`dynamic-table-row-${rowIndex}-cell-${columnIndex}`}
        onClick={() => props.onColumnClick?.(row, columnIndex)}
        className={`dynamic-table-cell ${props.hyperLinkColumns?.includes(columnIndex) && 'hyperlink clickable'}`}>{getPrettyValue(row[key])}
      </TableCell>
    ));

    return (
      <TableRow key={`dynamic-table-row-${rowIndex}`} 
        className={`dynamic-table-row ${props.onRowClick && 'clickable'}`}
        onClick={() => props.onRowClick?.(row)}>{rowCells}
      </TableRow>
    );
  });


 

  const loading = props.data == null && apiResultsError == null && (!apiResultsLoaded || apiResultsLoading);
  const renderTable = tableCells != null && tableCells.length > 0;
  
  return (
    <div className="dynamic-table">
      <Loader isLoading={loading} />
      <div className="dynamic-table-title">
        <h2>{props.header}</h2>
        <span>{props.subHeader}</span>
        {apiResultsError && <span className='error'>Failed to load data. {apiResultsError.response.data}</span>}
        {renderTable && <Button icon labelPosition='right' onClick={onDownloadClick}>Download<Icon name='download'/></Button>}
      </div>
      {!renderTable && (
        <div>
          <span>No Results</span>
        </div>
      )}
      {renderTable && (
      <>
        <Table compact sortable>
          <TableHeader className='dynamic-table-header'>
            <TableRow>{tableHeaders}</TableRow>
          </TableHeader>
          <TableBody className='dynamic-table-body'>{tableCells}</TableBody>
        </Table>
        <Pagination className='dynamic-table-pagination' onPageChange={onPageChange} defaultActivePage={1} totalPages={totalPages} />
      </>
      )}
    </div>
  );
}
