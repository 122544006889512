import React, { useLayoutEffect, useRef } from 'react';
import Chart from 'chart.js';

export type MonthlyCTPsChartProps = {
  dailyCTPs: TypeMePlease;
  height?: number;
  width?: number;
};

export function MonthlyCTPsChart(props: MonthlyCTPsChartProps) {
  const { dailyCTPs } = props;
  const chartEl = useRef<TypeMePlease>(null);
  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'bar',
        options: {
          aspectRatio: chartEl.current.offsetWidth / chartEl.current.offsetHeight,
          legend: { display: false },
          scales: {
            xAxes: [
              {
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                gridLines: { display: true },
                ticks: {
                  min: 0,
                  max: Math.ceil(Math.max(Math.max(...dailyCTPs.map((x) => x.convertToPaid)), 1) / 10) * 10,
                },
              },
            ],
          },
        },
      });
    }

    // Reset chart to empty
    chart.current.data.labels = dailyCTPs.map((x) => x.dayOfMonth);
    chart.current.data.datasets = [
      {
        backgroundColor: '#375eb3',
        barThickness: 8,
        data: dailyCTPs.map((x) => x.convertToPaid),
      },
    ];

    // Redraw the chart
    chart.current.update();
  }, [dailyCTPs]);

  return (
    <div className="chart" ref={chartEl} style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
