import React from 'react';
import { SearchResults } from './Search';

type ParentAccountsResultsProps = {
  results: SearchResults[];
};

export function ParentAccountsResults(props: ParentAccountsResultsProps) {
  return (
    <table className="ui table">
      <thead>
        <tr>
          <th>Name</th>
          <th>ACV</th>
          <th>Client Count</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.results.map((row) => {
          return (
            <tr key={row.id}>
              <td>
                <a href={`parentAccount/${row.id}`}>{row.name}</a>
              </td>
              <td>{row.acv}</td>
              <td>{row.clientCount}</td>
              <td>
                <a href={`parentAccount/${row.id}`}>Details</a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
