import React, { useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { Button, Modal } from 'semantic-ui-react';
import DateRangePicker from './DateRangePicker';
import './DateRangePickerModal.scss';

export type DateRangePickerModalProps = {
    startDate?: Date;
    endDate?: Date;
    open?: boolean;
    onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>, props?: any) => void;
    onDoneClick: (range?: RangeModifier) => void;
    onRangeChange?: (range: RangeModifier) => void;
};

export default function DateRangePickerModal(props: DateRangePickerModalProps) {
    const { startDate, endDate, open, onClose, onRangeChange, onDoneClick } = props;
    const [selectedRange, setSelectedRange] = useState<RangeModifier>(null);
    
    const handleRangeChange = (range: RangeModifier) => {
        setSelectedRange(range);

        if (!!onRangeChange) {
            onRangeChange(range);
        }
    };

    return (
        <Modal className="datetime-modal" open={open ?? true} dimmer="inverted" size="mini" onClose={onClose}>
            <Modal.Header>Select Date Range</Modal.Header>
            <Modal.Content>
                <DateRangePicker 
                    initialStartDate={startDate} 
                    initialEndDate={endDate} 
                    onRangeChange={handleRangeChange}/>
            </Modal.Content>
            <Modal.Actions>
                <Button className="ui button cancel" onClick={onClose}>
                    Cancel
                </Button>
                <Button className="ui button primary" onClick={() => onDoneClick(selectedRange)}>
                    Done
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
