import React from "react";
import { Dropdown } from "semantic-ui-react";

export interface CategorySelectProps { 
  value: string;
  categories: string[];
  onChange: (newValue: string) => void;
  disabled: boolean;
}

export function CategorySelect(props: CategorySelectProps) {
  const { value, categories, onChange, disabled } = props;
  return (
    <Dropdown
      selection
      fluid
      value={value}
      options={(categories || []).map((c) => ({ key: c, text: c, value: c }))}
      allowAdditions
      search
      onChange={(e, data) => onChange(data.value?.toString())}
      disabled={disabled}
    />
  );
}