import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { formatCurrency } from '../../utility/formatting';
import './ProServiceLogs.scss';

export function ProServiceLogs() {
  const [items, loading, loaded] = useApi<TypeMePlease>('/api/proservicelogs');

  if (loading || !loaded) {
    return <Loader isLoading />;
  }

  return (
    <div className="proservicelogs">
      <Helmet>
        <title>Pro Service Logs</title>
      </Helmet>
      <table className="ui table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Opportunity Name</th>
            <th>Amount</th>
            <th>Close Date</th>
          </tr>
        </thead>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.clientId ? <a href={`/account/${item.clientId}`}>{item.clientName || item.clientId}</a> : null}</td>
            <td>{item.name}</td>
            <td>{formatCurrency(item.amount)}</td>
            <td>{moment.utc(item.closeDate).local().format('MM/DD/YYYY')}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}
