
export type AddOnConfigurationDto = { 
  addOnConfigurationId?: string;
  code?: string;
  pricingType?: AddOnPricingType;
  title?: string;
  description?: string;
  featureFlagId?: string;
  monthlyPrice?: number;
  yearlyPrice?: number;
  userLimit?: number;
  userCanUnsubscribe?: boolean;
  createdDate?: string;
  lastUpdatedDate?: string;
}

export type AddOnSubscriptionConfigurationDto = {
    subscription?: string;
    addOnConfigurationId?: string;
    monthlyPrice?: number;
    yearlyPrice?: number;
    pricingType?: AddOnPricingType;
    userLimit?: number;
    isDisabled?: boolean;
    createdDate?: string;
    lastUpdatedDate?: string;
}

export type AddOnClientConfigurationDto = {
  clientId?: string;
  addOnConfigurationId?: string;
  monthlyPrice?: number;
  yearlyPrice?: number;
  pricingType?: AddOnPricingType;
  userLimit?: number;
  isDisabled?: boolean;
  createdDate?: string;
  lastUpdatedDate?: string;
}

export type ClientAddOnDto = {
  clientId?: string;
  addOnConfigurationId?: string;
  monthlyPrice?: number;
  yearlyPrice?: number;
  pricingType?: AddOnPricingType;
  userLimit?: number;
  usersAssigned?: number;
  pendingUnsubscribedBy?: string;
  pendingUnsubscribedDate?: string;
  effectiveUnsubscribedDate?: string;
  createdBy?: string;
  createdDate?: string;
  lastUpdatedBy?: string;
  lastUpdatedDate?: string;
  deletedBy?: string;
  deletedDate?: string;  
}

export const enum AddOnPricingType {
  FixedPrice = 0,
  UserBased = 1,
}

export enum SubscriptionChangeTimeframe {
  Now = 0, 
  Renewal = 1,
}
