import React, { useLayoutEffect, useRef } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';

import './Charts.scss';
// todo - replace: this package is outdated and not actively supported and preventing us from upgrading chart.js
import 'chartjs-plugin-colorschemes';

export type WorkflowClientProps = {
  title?: string;
  clientId: string;
  startDate?: string;
  endDate?: string;
  width?: number;
  height?: number;
  colorScheme?: string;
};

export function WorkflowClient(props: WorkflowClientProps) {
  const defaultStartDate = useRef<TypeMePlease>(moment().subtract(14, 'days').format('YYYY-MM-DD'));
  const defaultColorScheme = useRef<TypeMePlease>(Chart['colorschemes'].tableau.Classic20);

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate || '';
  const colorScheme = getColorScheme();

  const [data, dataLoading, dataLoaded] = useApim<TypeMePlease>(
    `/metrics/workflows/jobs/attempts/clients/${props.clientId}?bySuccess=true&startDate=${startDate}&endDate=${endDate}`,
    props.clientId
  );

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'bar',
        options: {
          aspectRatio: 1.4,
          scales: {
            xAxes: [
              {
                stacked: true,
                type: 'time',
                time: {
                  unit: 'day',
                  unitStepSize: 1,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      });
    }

    chart.current.data.datasets = [];

    if (dataLoaded) {
      if (data.length === 0) {
        return;
      }

      data.sort((a, b) => b.success - a.success);

      for (let i = 0; i < data.length; i++) {
        chart.current.data.datasets.push({
          label: data[i].success ? 'Success' : 'Failed',
          backgroundColor: data[i].success ? colorScheme[5] : colorScheme[7],
          data: data[i].dates.map((date) => {
            return { x: date.date, y: date.count };
          }),
        });
      }

      // Redraw the chart
      chart.current.update();
    }
  }, [dataLoaded, data]);

  function getColorScheme() {
    let scheme;
    if (props.colorScheme) {
      const parts = props.colorScheme.split('.');
      if (parts.length > 1) {
        scheme = Chart['colorschemes'][parts[0]][parts[1]];
      }
    }
    if (!scheme) {
      scheme = defaultColorScheme.current;
    }
    return scheme;
  }

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 400}px` }}>
      <Loader isLoading={dataLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
