import React, { useState } from 'react';
import { Dropdown, Button, Checkbox } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { useApi, useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import { copyTextToClipboard } from '../../../utility/clipboard';
import axios from 'axios';
import { useProfile } from '../../../context/ProfileContext';
import { deepClone } from '../../../utility/deepClone';
import './Users.scss';
import { useMessages } from '../../../context/MessagesContext';
import Pagination from '../../paging/Pagination';
import SearchInput from '../../../helpers/SearchInput';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Account } from '../Account';

export type UsersProps = {
  id: string;
  account: TypeMePlease;
};

export function Users(props: UsersProps) {
  const { id, account } = props;
  const [onlyActive, setOnlyActive] = useState(true);
  const [groupResult, groupsLoading, groupsLoaded] = useApim<TypeMePlease>('/groups/search', id);
  const [usersInGroupsResult, usersInGroupsLoading, usersInGroupsLoaded] = useApim<TypeMePlease>('/groupsusers/list', id, '', !groupsLoaded, 'POST', {
    groupIds: groupResult ? groupResult.groups.map((x) => x.id) : [],
    take: 10000,
  });
  const [lastLoginsResult, lastLoginsLoading, lastLoginsLoaded] = useApi<TypeMePlease>(`/api/account/${id}/lastlogins`);
  const [search, setSearch] = useState();
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const params = [];

  if (onlyActive) {
    params.push(`onlyActive=${onlyActive}`);
  }
  if (search) {
    params.push(`q=${encodeURIComponent(search)}`);
  }
  params.push(`skip=${(pageNumber - 1) * pageSize}`);
  params.push(`take=${pageSize}`);
  params.push(`orderBy=CreatedDate Asc`);
  const [usersResult, usersLoading, usersLoaded, refreshUsers, setUsers] = useApim<TypeMePlease>(`/users/search?${params.join('&')}`, id);

  if (!usersLoaded) {
    return <Loader isLoading />;
  }

  return (
    <div className="users">
      <Helmet>
        <title>{account.name} - Users</title>
      </Helmet>
      <SearchInput placeholder="Search Users" searchFilter={search} updateSearchFilter={setSearch} />
      <br />
      <br />
      <Checkbox toggle label="Hide Deactivated Users" checked={onlyActive} onChange={() => setOnlyActive(!onlyActive)} />
      <br />
      <br />
      Active Users: {usersResult.activeUserCount}
      <table className="ui table">
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Groups</th>
            <th>Created Date</th>
            <th>Deactivated Date</th>
            <th>Last Login Date</th>
            <th>Id</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersResult.users.map((user) => (
            <UserRow
              key={user.id}
              user={user}
              clientId={id}
              refreshUsers={refreshUsers}
              usersInGroupsResult={usersInGroupsResult}
              groups={groupResult ? groupResult.groups : []}
              lastLogin={usersInGroupsLoaded && lastLoginsResult ? lastLoginsResult.find((x) => x.userId === user.id) : null}
              accountDeactivated={!!account.deletedDate}
            />
          ))}
        </tbody>
      </table>
      <div className="paging">
        <Pagination
          page={pageNumber}
          changePageSize={setPageSize}
          totalRows={usersResult ? (onlyActive ? usersResult.activeUserCount : usersResult.userCount) : 0}
          pageSize={pageSize}
          changePage={setPageNumber}
        />
      </div>
      <Loader isLoading={usersLoading || groupsLoading || usersInGroupsLoading || lastLoginsLoading} />
    </div>
  );
}

function UserRow(props) {
  const { user, clientId, refreshUsers, usersInGroupsResult, groups, lastLogin, accountDeactivated } = props;
  const { profile } = useProfile();
  const [actionLoading, setActionLoading] = useState(false);
  const { showMessage } = useMessages();

  const setSpecialAccessUsername = async () => {
    setActionLoading(true);
    const data = new FormData();
    data.append('LoginAsUsername', user.userName);
    await axios({
      method: 'put',
      url: '/Account/UpdateSpecialAccess',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    });
    setActionLoading(false);
  };

  const launchSpecialAccess = async () => {
    await setSpecialAccessUsername();
    window.open(`${profile.goFormzAppUrl}?sso=gf&prompt=login`);
  };

  const getSpecialAccessKey = () => {
    window.open(`/Account/SpecialAccessApiKey?LoginAsUsername=${user.userName}`);
  };

  const changeStatus = async (status: string) => {
    setActionLoading(true);
    const result = await axios({
      method: 'post',
      url: `/api/account/${clientId}/changeStatus/${status}?userId=${user.id}`,
    });
    if (result.status === 200) {
      refreshUsers();
    } else {
      showMessage(`Unable to ${status} user. ${status === 'reactivate' && `This is probably because there aren't enough available licenses.`}`);
    }
    setActionLoading(false);
  };

  const groupNames = [];
  if (usersInGroupsResult) {
    for (const groupUsers of usersInGroupsResult) {
      if (groupUsers.users && groupUsers.users.some((x) => x.userId === user.id)) {
        const group = groups.find((x) => x.id === groupUsers.groupId);
        groupNames.push(group.name);
      }
    }
  }

  const DropdownMenuItems = ({ user, setSpecialAccessUsername, launchSpecialAccess, getSpecialAccessKey, reactivate, deactivate }) => {
    if (!user.active) {
      return <Dropdown.Item text="Reactivate" icon="configure" onClick={reactivate} />;
    }

    if (user.active && !user.pending) {
      return (
        <React.Fragment>
          <Dropdown.Item text="Use for Special Access" icon="user" onClick={setSpecialAccessUsername} />
          <Dropdown.Item text="Launch Special Access" icon="external alternate" onClick={launchSpecialAccess} />
          <Dropdown.Item text="Get Special Access Token" icon="key" onClick={getSpecialAccessKey} />
          <Dropdown.Item text="Deactivate" icon="configure" onClick={deactivate} />
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <tr>
      <td>
        <NavLink to={`/account/${clientId}/users/${user.id}`} end>
          {user.userName}
        </NavLink>
      </td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.phoneNumber}</td>
      <td>{groupNames.join(', ')}</td>
      <td>{user.createdDate ? moment.utc(user.createdDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
      <td>{user.deactivatedDate ? moment.utc(user.deactivatedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
      <td>{lastLogin ? moment.utc(lastLogin.timestamp).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
      <td>
        {user.id}{' '}
        <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(user.id)}>
          <i className="copy outline icon"></i>
        </button>
      </td>
      <td>
        {(user.active && user.pending) || accountDeactivated ? null : (
          <Dropdown trigger={<Button icon="ellipsis horizontal" loading={actionLoading} />} pointing="right" icon={null}>
            <Dropdown.Menu>
              <DropdownMenuItems
                user={user}
                setSpecialAccessUsername={setSpecialAccessUsername}
                launchSpecialAccess={launchSpecialAccess}
                getSpecialAccessKey={getSpecialAccessKey}
                reactivate={() => changeStatus('reactivate')}
                deactivate={() => changeStatus('deactivate')}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
}
