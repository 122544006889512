import React, { useState, useEffect } from 'react';
import './SearchInput.scss';

export type SearchInputProps = {
  placeholder?: string;
  width?: string;
  searchFilter?: string;
  searching?: boolean;
  updateSearchFilter?: AnyFunc;
  disabled?: boolean;
  onBlur?: AnyFunc;
  inputRef?: TypeMePlease;
  seaching?: boolean;
};

export default function SearchInput(props: SearchInputProps) {
  const [search, setSearch] = useState(props.searchFilter);

  useEffect(() => {
    setSearch(props.searchFilter);
  }, [props.searchFilter]);

  const searchKeyDown = (e) => {
    if (e.keyCode === 13) {
      props.updateSearchFilter(search);
      e.target.blur();
    }
  };

  return (
    <div className={`ui icon input ${props.width ? props.width : 'search-input'}`}>
      <input
        type="text"
        placeholder={props.placeholder}
        value={search || ''}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={searchKeyDown}
        ref={props.inputRef}
        disabled={props.disabled || props.searching}
        onBlur={(e) =>
          props.onBlur
            ? props.onBlur(e.target.value)
            : () => {
                /* noop */
              }
        }
      />
      {!props.searchFilter || (props.searchFilter !== search && !props.seaching) ? (
        <i
          aria-hidden="true"
          className={`search ${props.disabled ? '' : 'link'} icon`}
          onClick={() => {
            if (!props.disabled && !props.searching) {
              props.updateSearchFilter(search);
            }
          }}
        />
      ) : !props.searching ? (
        <i
          aria-hidden="true"
          className={`x ${props.disabled ? '' : 'link'} icon`}
          onClick={() => {
            if (!props.disabled && !props.searching) {
              setSearch('');
              props.updateSearchFilter('');
            }
          }}
        />
      ) : (
        <i aria-hidden="true" className="circle notch icon loading" />
      )}
    </div>
  );
}
