import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, NavLink, Route, Routes } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import Loader from '../Loader';
import './Apps.scss';
import Axios from 'axios';
import { apimCall } from '../../utility/api';
import Details from './details/Details';
import Permissions from './permissions/Permissions';

export function AppEdit() {
  const { id } = useParams();
  const [appInfo, setAppInfo, setAppInfoValues] = useForm({
    appName: '',
    description: '',
    author: '',
    scopes: '',
    configs: [],
    authenticationOptions: [],
    permissions: [],
    icon: '',
  });

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apimCall(`/apps/${id}`).then((res) => {
      setAppInfoValues(res.data.data);
      setLoading(false);
    });
  }, [id]);

  const handleSubmit = async () => {
    setSaving(true);
    await Axios.put(`/api/app/${id}`, appInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setSaving(false);
  };

  if (loading) {
    return <Loader isLoading />;
  }

  return (
    <div className="apps">
      <Helmet>
        <title>App Edit</title>
      </Helmet>
      <div className="main">
        <div className="app-sidebar">
          <div className="app-sidebar-header">
            <img src={appInfo.icon} width="80" height="80" />
            <h3>{appInfo.appName}</h3>
          </div>
          <br />
          <div className="nav-wrapper">
            <NavLink className="nav-link" to={`/Apps/Edit/${id}`} end>
              Details
            </NavLink>
            <NavLink className="nav-link" to={`/Apps/Edit/${id}/Permissions`}>
              Permissions
            </NavLink>
          </div>
        </div>
        <div className="app-body">
          <Routes>
            <Route index element={<Details appInfo={appInfo} setAppInfo={setAppInfo} onSave={handleSubmit} isSaving={saving} />} />
            <Route path="permissions" element={<Permissions appInfo={appInfo} setAppInfo={setAppInfo} onSave={handleSubmit} isSaving={saving} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
