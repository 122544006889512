import React from 'react';
import { useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { useApim } from '../../hooks/useApi';
import { ClientsResults } from './ClientsResults';
import { IndependentUsersResults } from './IndependentUsersResults';
import { ParentAccountsResults } from './ParentAccountsResults';
import Loader from '../Loader';

import './RecentSearchList.scss';

export type SearchResults = {
  id: string;
  emailAddress?: string;
  name?: string;
  companySize?: string;
  acv?: number;
  promoCode?: string;
  subscription?: string;
  createdDate?: string;
  deletedDate?: string;
  trialEndDate?: string;
  firstName?: string;
  lastName?: string;
  clientCount?: number;
};

export function Search() {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('query');
  const condition = searchParams.get('condition');
  const [results, resultsLoading, resultsLoaded] = useApim<SearchResults[]>('/accounts/search', null, null, null, 'POST', {
    condition: condition ?? 'clients',
    term: searchTerm,
  });

  let body = <Loader isLoading />;

  const isClientId = validator.isUUID(searchTerm);
  if (isClientId) {
    window.location.href = `/account/${searchTerm}`;
    return;
  }

  if (resultsLoaded) {
    switch (condition) {
      case 'independentUsers':
        if (results.length == 1 && results[0].emailAddress == searchTerm) {
          window.location.href = `/independentUser/${results[0].id}`;
          break;
        }
        body = <IndependentUsersResults results={results} />;
        break;
      case 'parentAccounts':
        body = <ParentAccountsResults results={results} />;
        break;
      default:
      case 'clients':
        if (results.length == 1 && results[0].id == searchTerm) {
          window.location.href = `/account/${results[0].id}`;
          break;
        }
        body = <ClientsResults results={results} />;
        break;
    }
  }

  return <div className="search-results">{body}</div>;
}
