import './JiraTickets.scss';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Tab, TabPane, TabProps } from 'semantic-ui-react';
import { JiraTicketTable } from './JiraTicketTable';
import { JiraSearch } from './JiraSearch';
import { JiraReleaseSearch } from './JiraReleaseSearch';
import { useLocation, useNavigate } from 'react-router-dom';


export function JiraTickets() {
  const location = useLocation()
  const navigate = useNavigate();
  const pagePath = location.pathname.split('/').pop();

  const panes = [
    { menuItem: 'Dev', render: () => 
      <TabPane attached={false}>
        <JiraTicketTable releaseName="vNext - Web"/>
      </TabPane> 
    },
    { menuItem: 'Beta', render: () => 
      <TabPane attached={false}>
        <JiraTicketTable releaseName="Beta vNext - Web"/>
      </TabPane> 
    },
    { menuItem: 'Prod', render: () => 
      <TabPane attached={false}>
        <JiraReleaseSearch search='Prod - Web' />
      </TabPane> 
    },
    { menuItem: 'Mobile - Dev', render: () => 
      <TabPane attached={false}>
        <JiraTicketTable releaseName="vNext - Mobile"/>
      </TabPane> 
    },
    { menuItem: 'Mobile - Prod', render: () => 
      <TabPane attached={false}>
        <JiraReleaseSearch search='Prod - Mobile' />
      </TabPane> 
    },
    { menuItem: 'Search', render: () => 
      <TabPane attached={false}>
        <JiraSearch/>
      </TabPane> 
    },
  ];

  const handleTabChange = (_event: React.MouseEvent<HTMLDivElement>, data: TabProps)  => { 
    console.log(data.activeIndex);
    navigate(`/releasemanagement/tickets/${panes[data.activeIndex].menuItem.toLowerCase()}`, { replace: true });
  }

  const indexOfSelectedMenuItem = panes.map(pane => pane.menuItem.toLowerCase()).indexOf(pagePath?.toLowerCase() || '');
  const defaultActiveIndex = indexOfSelectedMenuItem < 0 ? 0 : indexOfSelectedMenuItem;
  return (
    <div className="azure-devops-releases">
      <Helmet>
        <title>Jira Tickets</title>
      </Helmet>

      <Tab defaultActiveIndex={defaultActiveIndex} onTabChange={handleTabChange} menu={{  borderless: true, attached: false, tabular: false }} panes={panes} />
    </div>
  );
}
