import moment from "moment";
const CalendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };


export const sortBy = (items, prop, desc = false) => {
  const sortOrder = desc ? -1 : 1;
  const value = items[prop];
  const parse = typeof value === 'number' ? Number.parseFloat : 
    moment(value, moment.ISO_8601, true).isValid() ? Date.parse : (a) => a?.toString()?.toLowerCase();

  return items.sort((a, b) => {
    const aProp = parse(a[prop]);
    const bProp = parse(b[prop]);
      if(aProp < bProp) {
          return -1  * sortOrder;
      }
      if (aProp > bProp) {
          return 1  * sortOrder;
      }
      return 0;
  });
}



export const sortReducer = (state, action) => {
  switch (action.type) {
    case "REPLACE_STATE":
      return action.payload;
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: sortBy(state.data, [action.column]),
        direction: 'ascending',
      } 
    default:
      throw new Error()
  }
}

export const getPrettyHeader = (value) => { 
  return value?.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

export const getPrettyValue = (value) => {
  if(value == null) { 
    return '--';
  }
  if(typeof value === 'number') { 
    return value.toString();
  }

 
  if(moment(value, moment.ISO_8601, true).isValid()) { 
    return moment.utc(value).local().calendar(null, CalendarOptions)
  }

  return value?.toString();
}