import React, { useState } from 'react';
import { Input, Button } from 'semantic-ui-react';
import { apiCall } from '../../utility/api';
import { useForm } from '../../hooks/useForm';
import { Module } from '../Module';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { CommentInput } from '../commentinput/CommentInput';

export type SubscriptionPricingProps = {
  id: string;
  account?: TypeMePlease;
  refreshAccount: () => void;
};

export function SubscriptionPricing(props: SubscriptionPricingProps) {
  const { id, account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [comments, setComments] = useState('');

  const [pricing, setPricing] = useForm({
    'gf-team': account.pricing ? account.pricing['gf-team'] : null,
    'gf-team-year': account.pricing ? account.pricing['gf-team-year'] : null,
    'gf-advanced': account.pricing ? account.pricing['gf-advanced'] : null,
    'gf-advanced-year': account.pricing ? account.pricing['gf-advanced-year'] : null,
    'gf-enterprise': account.pricing ? account.pricing['gf-enterprise'] : null,
    'gf-enterprise-year': account.pricing ? account.pricing['gf-enterprise-year'] : null,
  });

  const save = async () => {
    setSaving(true);
    const request = { pricing, comments };
    await apiCall(`/api/account/${id}/pricing`, 'PUT', request);
    setComments('');
    refreshAccount();
    setSaving(false);
  };

  return (
    <div className="settings">
      <div className="modules">
        <Module title="Subscription Pricing" className="fullwidth-module">
          <table className="ui very basic collapsing celled table">
            <thead>
              <tr>
                <th>Pricing (leave blank for default)</th>
                <th>Monthly Price</th>
                <th>Annual Price (Annually)</th>
                <th>Annual Price (Monthly)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>gf-team</td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-team'] === null ? '' : pricing['gf-team']}
                    onChange={(e) => setPricing('gf-team', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-team-year'] === null ? '' : pricing['gf-team-year']}
                    onChange={(e) => setPricing('gf-team-year', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-team-year'] === null ? '' : pricing['gf-team-year'] / 12}
                    onChange={(e) => setPricing('gf-team-year', e.target.value !== '' ? +e.target.value * 12 : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>gf-advanced</td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-advanced'] === null ? '' : pricing['gf-advanced']}
                    onChange={(e) => setPricing('gf-advanced', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-advanced-year'] === null ? '' : pricing['gf-advanced-year']}
                    onChange={(e) => setPricing('gf-advanced-year', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-advanced-year'] === null ? '' : pricing['gf-advanced-year'] / 12}
                    onChange={(e) => setPricing('gf-advanced-year', e.target.value !== '' ? +e.target.value * 12 : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>gf-enterprise</td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-enterprise'] === null ? '' : pricing['gf-enterprise']}
                    onChange={(e) => setPricing('gf-enterprise', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-enterprise-year'] === null ? '' : pricing['gf-enterprise-year']}
                    onChange={(e) => setPricing('gf-enterprise-year', e.target.value !== '' ? Number(e.target.value) : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
                <td className="field">
                  <Input
                    type="number"
                    value={pricing['gf-enterprise-year'] === null ? '' : pricing['gf-enterprise-year'] / 12}
                    onChange={(e) => setPricing('gf-enterprise-year', e.target.value !== '' ? +e.target.value * 12 : null)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </table>
          {account.deletedDate ? null : (
            <div className="ui form">
              <br />
              <CommentInput placeholder="Comment."
                  value={comments} onChange={setComments}
                  disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}/>
              <br />
              <Button className="primary" onClick={save} loading={saving} disabled={!comments}>
                Save
              </Button>
            </div>
          )}
        </Module>
      </div>
    </div>
  );
}
