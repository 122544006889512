import { AddOnPricingType } from "./types";

export const PricingTypeDropdownValues = [{
  key: AddOnPricingType.FixedPrice,
  text: 'Fixed Price',
  value: AddOnPricingType.FixedPrice,
},{
  key: AddOnPricingType.UserBased,
  text: 'User Based',
  value: AddOnPricingType.UserBased,
}];


export const CalendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };


export const UnavailableAddOnSubscription = [
  'gf-trial',
  'gf-basic',
]

export const IneligibleSubscriptionTierForClientConfig = [
  'gf-basic',
]