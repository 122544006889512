import { useState } from 'react';
import { RecentSearchStore } from '../utility/RecentSearchStore';

/**
 * Hook to to use recent searches that's saved to localStorage by default.
 */
export function useRecentSearches(): [string[], (search: string) => void] {
  const recentSearchStore = new RecentSearchStore(localStorage);
  const [recentSearches, setRecentSearchces] = useState(recentSearchStore.get());

  const updateRecentSearches = (term) => {
    // persist to storage
    recentSearchStore.save(term);
    // return the new
    setRecentSearchces(recentSearchStore.get());
  };

  return [recentSearches, updateRecentSearches];
}
