import React from 'react';

export type AppConfigProps = {
  configKey: string;
  description: string;
  configType: string;
  secret: boolean;
  required: boolean;
  setConfigs: (config: string, value: string | boolean) => void;
  removeConfig: () => void;
};

export default function AppConfig(props: AppConfigProps) {
  const { configKey, description, configType, secret, required, setConfigs, removeConfig } = props;

  return (
    <div className="four fields">
      <div className="field">
        <label>Key</label>
        <input type="text" value={configKey} onChange={(e) => setConfigs('key', e.target.value)} />
      </div>
      <div className="field">
        <label>Description</label>
        <input type="text" value={description} onChange={(e) => setConfigs('description', e.target.value)} />
      </div>
      <div className="field">
        <label>ConfigType</label>
        <select value={configType} onChange={(e) => setConfigs('configType', e.target.value)}>
          <option value="custom">custom</option>
          <option value="general">general</option>
          <option value="auth">auth</option>
        </select>
      </div>
      <div className="field">
        <label>Required</label>
        <input type="checkbox" checked={required} onChange={(e) => setConfigs('required', e.target.checked)} />
      </div>
      <div className="field">
        <label>Secret</label>
        <input type="checkbox" checked={secret} onChange={(e) => setConfigs('secret', e.target.checked)} />
      </div>
      <div className="field">
        <label>&nbsp;</label>
        <button type="button" className="ui icon button remove-config" onClick={() => removeConfig()}>
          <i className="trash icon"></i>
        </button>
      </div>
    </div>
  );
}
