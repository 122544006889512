import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import AddPublicFormsSubscriptionModal from './AddPublicFormsSubscriptionModal';

export type PublicFormsProps = {
  id: string;
  account: TypeMePlease;
};

export default function PublicForms(props: PublicFormsProps) {
  const { id } = props;
  const [publicFormsSusbcriptionModalIsOpen, setPublicFormsSusbcriptionModalIsOpen] = useState(false);
  const [creditsResult, creditsLoading, creditsLoaded] = useApim<TypeMePlease>('/billing/credits', id);
  const [creditPriceResult, creditPriceLoading, creditPriceLoaded] = useApim<TypeMePlease>('/billing/creditprice', id);
  const [creditHistoryResult, creditHistoryLoading, creditHistoryLoaded] = useApim<TypeMePlease>('/billing/credits/history', id);
  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };
  // @ts-ignore: fix typing for this operation
  const sortedCreditHistoryResult = creditHistoryLoaded ? creditHistoryResult.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) : '';

  const onClosePublicFormsSubscriptionClick = () => {
    setPublicFormsSusbcriptionModalIsOpen(false);
  };

  const onOpenPublicFormsSubscriptionClick = () => {
    setPublicFormsSusbcriptionModalIsOpen(true);
  };

  return (
    <div className="publicforms">
      <AddPublicFormsSubscriptionModal id={id} isOpen={publicFormsSusbcriptionModalIsOpen} onClose={onClosePublicFormsSubscriptionClick} />
      <table className="ui very basic collapsing celled table">
        <tr>
          <td>Public Form Credits</td>
          <td>{creditsLoaded ? creditsResult.credits : null}</td>
          <td>
            <Link to={`/account/${id}/publicforms/adjustcredits`}>Add/Remove Credits</Link>
          </td>
        </tr>
        <tr>
          <td>Credit Price</td>
          <td>{creditPriceLoaded ? creditPriceResult.creditPrice : null}</td>
          <td>
            <Link to={`/account/${id}/publicforms/pricing`}>Update Credit Price</Link>
          </td>
        </tr>
      </table>
      <Button onClick={onOpenPublicFormsSubscriptionClick}>Generate Public Forms Self Checkout Link</Button>

      <div className="ui header">Credits Change History</div>

      <table className="ui table">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Old Count</th>
            <th>New Count</th>
            <th>Price</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {sortedCreditHistoryResult
            ? sortedCreditHistoryResult.map((row) => {
                return (
                  <tr>
                    <td>{moment.utc(row.createdDate).local().calendar(null, calendarOptions)}</td>
                    <td>{row.internalUsername}</td>
                    <td>{row.oldCreditCount}</td>
                    <td>{row.newCreditCount}</td>
                    <td>{row.price}</td>
                    <td>{row.comment}</td>
                  </tr>
                );
              })
            : ''}
        </tbody>
      </table>
      <Loader isLoading={creditHistoryLoading} />
    </div>
  );
}
