import React, { useEffect, useState } from 'react'
import { Button, Radio } from 'semantic-ui-react';
import { Module } from '../Module';
import { Helmet } from 'react-helmet';
import { AppFeatures, canIUse } from '../../utility/SecurityHelper';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { useMessages } from '../../context/MessagesContext';
import { apiCall } from '../../utility/api';
import './AccountControls.scss';
import { CommentInput } from '../commentinput/CommentInput';


export type AccountControlsProps = {
  id: string;
  account: TypeMePlease;
  refreshAccount: () => void;
};

interface ClientMonitoring { 
  clientId: string;
  state: number,
  comment: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
}

enum ClientMonitoringState { 
    Normal = 0,
    Monitoring = 1,
    Spam = 2,
    Cleared = 3,
    GDPR = 4,
    ManualDelete = 5
}

const states = [
  {
    key:  ClientMonitoringState.Normal,
    label: 'Normal',
  },
  {
    key:  ClientMonitoringState.Monitoring,
    label: 'Monitoring',
  },
  {
    key: ClientMonitoringState.Spam,
    label: 'Spam',
  },
  {
    key:  ClientMonitoringState.Cleared,
    label: 'Cleared',
  }
]

const deleteStates = [
    {
        key: ClientMonitoringState.GDPR,
        label: 'GDPR',
    },
    {
        key: ClientMonitoringState.ManualDelete,
        label: 'Manual Delete',
    }
]

export function AccountControls(props: AccountControlsProps) {
  const { id, account, refreshAccount } = props;
  const [clientMonitoring, _clientMonitoringLoading, clientMonitoringLoaded, refreshClientMonitoring] = 
    useApi<ClientMonitoring>(`/api/account/${id}/monitoring`);
  const [isSaving, setIsSaving] = useState(false);
  const [comment, setComment] = useState('');
  const [accountState, setAccountState] = useState(ClientMonitoringState.Normal);
  const { showMessage } = useMessages();
  const currentState = states.find(state => state.key === clientMonitoring?.state)?.label ?? 'Normal';

  useEffect(() => {
    setAccountState(clientMonitoring?.state ?? ClientMonitoringState.Normal);
    setComment('');
  }, [clientMonitoring]);

  const canSave = () => {
    if(!canIUse(AppFeatures.EditAccountControls)) { 
      return false;
    }

    if(!comment) { 
      return false;
    }

    if(accountState === clientMonitoring?.state) { 
      return false;
    }

    return true;
  }

  const isReadonly = () => { 
      return !canIUse(AppFeatures.EditAccountControls) || clientMonitoring?.state === ClientMonitoringState.Spam;
  }

  const onAccountStateChange= (newState) => { 
    setAccountState(newState);
    }

  const onSaveClick = async  () => {
    if(!canSave()) { 
      return;
    }

    setIsSaving(true);

    const trySave = async () => { 
      try
      {
        const result = await apiCall(`/api/account/${id}/monitoring`, 'PUT', {
          clientId: id,
          state: accountState,
          comment: comment
         })

        if(result.status !== 200) { 
          showMessage('Failed to save the updated monitoring status');
          return;
        }
      } catch(e) { 
        console.log(e);
        showMessage('Failed to save the updated monitoring status');
      }
    }
    
    await trySave();

    refreshClientMonitoring();
    setIsSaving(false);
  }

  if (!clientMonitoringLoaded) {
    return <Loader isLoading />;
  }

  return (
    <div className="account-controls">
      <Helmet>
        <title>{account.name} - Controls</title>
      </Helmet>
      <div className="modules">
        <Module title="Account Controls" className="details-module">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>Current State</td>
                <td>{currentState}</td>
              </tr>
              <tr>
                <td>Spam</td>
                <td>{states.map(state => (
                  <Radio key={state.key} label={state.label} name='radioGroup' value={state.key} 
                    disabled={!canIUse(AppFeatures.EditAccountControls) || isReadonly()}
                    checked={accountState === state.key} onChange={(e, { value }) => onAccountStateChange(value)}
                  />
                ))}</td>
              </tr>
                <tr>
                <td>Delete</td>
                <td>{deleteStates.map(deleteState => (
                    <Radio key={deleteState.key} label={deleteState.label} name='radioGroup' value={deleteState.key}
                        disabled={!canIUse(AppFeatures.EditAccountControls) || isReadonly()}
                        checked={accountState === deleteState.key}
                        onChange={(e, { value }) => onAccountStateChange(value)}
                    />
                ))}</td>
                </tr>
            </tbody>
          </table>
          <CommentInput placeholder="Reason for the update. If Deactivated, include a detailed reason listing out the evidence."
               value={comment || ''} onChange={setComment} disabled={isReadonly()}/>
          <div>
            <Button className="btn-acount-update" color='red' onClick={onSaveClick} loading={isSaving} disabled={!canSave()}>
              Confirm Account Update
            </Button>
          </div>
        </Module>
      </div>
    </div>
  );
}
