import React from 'react';
import './AddOnsAddRow.scss';
import { Button, TableCell, TableRow } from 'semantic-ui-react';

export type AddOnsAddRowProps = {
  text: string;
  colSpan: number;
  disabled: boolean;
  onClick: () => void;
}

export function AddOnsAddRow(props: AddOnsAddRowProps) {
  const { onClick, disabled, colSpan, text } = props;

  return (
    <TableRow className='add-ons-add-row'>
      <TableCell colSpan={colSpan}>
        <Button
          primary
          size='small'
          onClick={onClick} 
          disabled={disabled}
        >{text}</Button>
      </TableCell>
    </TableRow>
  );
}
