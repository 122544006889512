import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import './Layout.scss';
import { Outlet } from 'react-router-dom';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="layout">
        <NavMenu />
        <div className="layout-body">
          {this.props.children}
          <Outlet />
        </div>
      </div>
    );
  }
}
