import React, { useLayoutEffect, useRef } from 'react';
import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import Chart from 'chart.js';
import './Charts.scss';

export type PublicApiClientHourlyProps = {
  title?: string;
  clientId: string;
  startDate?: string;
  endDate?: string;
  width?: number;
  height?: number;
  useLegend?: boolean;
};

export function PublicApiClientHourly(props: PublicApiClientHourlyProps) {
  const defaultStartDate = useRef<TypeMePlease>(moment().subtract(3, 'days').format('YYYY-MM-DD'));
  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate || '';
  const useLegend = props.useLegend === undefined ? true : props.useLegend;
  const color = 'rgba(109, 184, 100, 1)';

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const [data, dataLoading, dataLoaded] = useApim<TypeMePlease>(
    `/metrics/publicapi/requests/clients/${props.clientId}/hourly?categories=all&startDate=${startDate}&endDate=${endDate}`,
    props.clientId
  );

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'line',
        options: {
          aspectRatio: 1.4,
          legend: { display: useLegend },
          tooltips: {
            intersect: false,
            callbacks: {
              title: (tooltipItem) => moment(tooltipItem[0].label).format('MMM D, h A'),
              labelColor: (tooltipItem, data) => {
                const tooltipColor = data.data.datasets[tooltipItem.datasetIndex].backgroundColor;
                return {
                  borderColor: tooltipColor,
                  backgroundColor: tooltipColor,
                };
              },
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    hour: 'h A',
                  },
                },
              },
            ],
          },
        },
      });
    }

    // Reset chart to empty
    chart.current.data.labels = [];
    chart.current.data.datasets = [];

    // Add data
    if (dataLoaded) {
      chart.current.data.labels = data.map((x) => x.date);
      chart.current.data.datasets = [
        {
          data: data.map((item) => {
            return { x: item.date, y: item.count };
          }),
          pointRadius: 0,
          fill: false,
          lineTension: 0,
          borderWidth: 2,
          backgroundColor: color,
          borderColor: color,
        },
      ];
    }

    // Redraw the chart
    chart.current.update();
  }, [data, dataLoaded]);

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <Loader isLoading={dataLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
