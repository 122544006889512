import React, { useState, useEffect } from 'react';

export interface ErrorBoundaryProps {
    children: React.ReactNode;
    errorContent?: React.ReactNode;
}

export default function ErrorBoundary(props: ErrorBoundaryProps){
    const { children, errorContent } = props;
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        const errorListener = (error: ErrorEvent) => {
            setHasError(true);
            console.error(error);
        };

        window.addEventListener('error', errorListener);

        return () => {
            window.removeEventListener('error', errorListener);
        };
    }, []);

    if (hasError) {
        if (errorContent) {
            return <>{errorContent ?? null}</>;
        }
        return null;
    }

    return <>{children}</>;
}