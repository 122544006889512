import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { text: '15', value: 15 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '75', value: 75 },
  { text: '100', value: 100 },
];

export type PageSizeDropdownProps = {
  onChange: (size: number) => void;
  currentpageSize: number;
  className?: string;
};

export default function PageSizeDropdown(props) {
  return <Dropdown selection onChange={props.onChange} value={props.currentPageSize} options={options} compact upward className={props.className} />;
}
