import React, { useLayoutEffect, useRef } from 'react';
import { Button, Checkbox, Modal, Progress } from 'semantic-ui-react';
import Loader from '../../Loader';
import { AccountFeatureUpdate } from '../features/AccountFeatures';
import { calculateDefaultStartDate, filterCreatedFormsDataByTermDate, getAnnualCount, getCurrentMonthCount, useGetData } from '../helpers';
import moment from 'moment';
import Chart from 'chart.js';
import { ClientDto } from '../account-types';
import { ClientData } from '../../charts/charts-types';
import { getLabelForEventType } from '../../charts/Charts';

type FormCreatedLimitsProps = {
  clientId: string;
  account: ClientDto;
  formCreateLimit: number | null;
  toggleFormLimits: () => void;
  setShowConfigureFormCreatedLimits: (show: boolean) => void;
  showConfigureFormCreatedLimits: boolean;
  billingPeriod: number;
  setFormCreateLimit: (limit: number) => void;
  setAccountFeatureValue: (updates, setUpdates, formCreateLimitFeatureFlag, value) => void;
  updates: AccountFeatureUpdate;
  setUpdates: React.Dispatch<React.SetStateAction<AccountFeatureUpdate>>;
  formCreateLimitFeatureFlag: any;
  setFormLimitsChanged: (changed: boolean) => void;
  setFormLimitsCleared: (cleared: boolean) => void;
};

export default function FormCreatedLimits(props: FormCreatedLimitsProps) {
  const {
    account,
    clientId,
    formCreateLimit,
    toggleFormLimits,
    setShowConfigureFormCreatedLimits,
    showConfigureFormCreatedLimits,
    billingPeriod,
    setFormCreateLimit,
    setAccountFeatureValue,
    updates,
    setUpdates,
    formCreateLimitFeatureFlag,
    setFormLimitsChanged,
    setFormLimitsCleared,
  } = props;

  const defaultStartDate = useRef<string>(calculateDefaultStartDate());
  const defaultEndDate = useRef<string>(moment().format('YYYY-MM-DD'));

  const startDate = defaultStartDate.current;
  const endDate = defaultEndDate.current;
  const useLegend = false;

  const canvasEl = useRef<HTMLCanvasElement>(null);
  const chart = useRef<Chart | null>(null);

  const [createdData, createdDataLoading, createdDataLoaded] = useGetData('created', clientId, startDate, endDate);

  const currentTermStartedAt = account?.settings?.currentTermStartedAt
    ? moment.utc(account.settings.currentTermStartedAt)
    : billingPeriod === 12
      ? moment.utc().subtract(12, 'months').startOf('month')
      : moment.utc().startOf('month');
  const currentTermEndsAt = account?.settings?.currentTermEndsAt ? moment.utc(account.settings.currentTermEndsAt) : moment.utc().endOf('month');

  const groupedCreatedData = filterCreatedFormsDataByTermDate(createdData, currentTermStartedAt);

  const currentCount = getCurrentMonthCount(groupedCreatedData);
  const annualCount = getAnnualCount(groupedCreatedData, currentTermStartedAt);
  const term = billingPeriod === 12 ? 'Annual' : 'Monthly';

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'bar',
        options: {
          legend: { display: useLegend },
          tooltips: {
            intersect: false,
            callbacks: {
              labelColor: (tooltipItem, data) => {
                const tooltipColor = data.data.datasets[tooltipItem.datasetIndex].backgroundColor;
                return {
                  borderColor: tooltipColor,
                  backgroundColor: tooltipColor,
                };
              },
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                id: 'A',
                type: 'linear',
                position: 'left',
              },
              {
                id: 'B',
                type: 'linear',
                position: 'right',
              },
            ],
          },
        },
      });
    }

    chart.current.data.labels = [];
    chart.current.data.datasets = [];

    if (createdDataLoaded) {
      const adjustedCreatedData = {
        ...groupedCreatedData,
        dates: groupedCreatedData?.dates?.map((entry) => ({
          ...entry,
          count: Math.min(entry.count, formCreateLimit ?? entry.count),
        })),
      };
      addDataset('created', createdDataLoaded, adjustedCreatedData, 'rgba(109, 184, 100, 1)', 'bar', 'A', 2);
    }

    if (formCreateLimit) {
      addDataset('created', createdDataLoaded, groupedCreatedData, 'rgba(255, 0, 0, 1)', 'bar', 'A', 3);
    }

    const roundUp = (value: number) => {
      if (value > 5000) {
        return Math.ceil(value / 1000) * 1000;
      }
      return Math.ceil(value / 100) * 100;
    };

    const highestCount = groupedCreatedData ? Math.max(...groupedCreatedData?.dates?.map((entry) => entry.count)) : 100;
    const roundedCurrentCount = roundUp(highestCount);
    chart.current.options.scales.yAxes[0].ticks.max = roundedCurrentCount;
    chart.current.options.scales.yAxes[0].ticks.stepSize = roundedCurrentCount / 5;

    if (createdDataLoaded && billingPeriod === 12 && formCreateLimit) {
      const filteredData = groupedCreatedData.dates.map((entry) => ({
        date: entry.date,
        count: moment(entry.date).isSameOrAfter(currentTermStartedAt) ? entry.count : 0,
      }));

      const clientData: ClientData = {
        dates: filteredData
          .map((entry, index) => ({
            date: entry.date,
            count: filteredData.slice(0, index + 1).reduce((sum, e) => sum + e.count, 0),
          }))
          .sort((a, b) => moment(a.date).diff(moment(b.date))), // Ensure dates are in order, newest to oldest
        clientId: groupedCreatedData.clientId,
        clientName: groupedCreatedData.clientName,
      };

      addDataset('createdCurrent', createdDataLoaded, clientData, 'rgba(54, 162, 235, 1)', 'line', 'B', 1);

      const roundedAnnualCount = roundUp(annualCount);
      chart.current.options.scales.yAxes[1].ticks.max = roundedAnnualCount;
      chart.current.options.scales.yAxes[1].ticks.stepSize = roundedAnnualCount / 5;
    } else {
      chart.current.options.scales.yAxes[1].ticks.max = roundedCurrentCount;
      chart.current.options.scales.yAxes[1].ticks.stepSize = roundedCurrentCount / 5;
    }

    chart.current.update();
  }, [groupedCreatedData, createdDataLoaded, annualCount, formCreateLimit]);

  const addDataset = (eventType: string, loaded: boolean, data: ClientData, color: string, type: 'line' | 'bar', yAxisID: string, order: number) => {
    if (!loaded) return;

    // ensure dates are in order
    data?.dates?.sort((a, b) => moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD')));

    // generate labels based on the start date of each range
    if (chart.current && data?.dates) {
      chart.current.data.labels = Array.from(new Set([...chart.current.data.labels, ...data.dates.map((x) => x.date)]));

      chart.current.data.datasets = [
        ...chart.current.data.datasets,
        {
          eventType: eventType,
          label: getLabelForEventType(eventType),
          data: data.dates.map((x) => x.count),
          yAxisID: yAxisID,
          pointRadius: 0,
          type: type,
          fill: false,
          lineTension: 0,
          borderWidth: 2,
          backgroundColor: color,
          borderColor: color,
          order: order,
        },
      ];
    }
  };

  return (
    <div className="form-wrapper half-width">
      <div className="ui header header-cell">
        Form Created Limits
        <Checkbox toggle checked={!!formCreateLimit} onChange={toggleFormLimits} style={{ marginLeft: '40px' }} />
        {formCreateLimit && (
          <div style={{ border: '1px solid black', fontSize: '14px', padding: '4px', maxWidth: '250px', float: 'right' }}>
            <b>Limit resets on:</b> {currentTermEndsAt.format('MM/DD/YYYY')}
          </div>
        )}
      </div>
      <hr />
      <div className="content">
        <div className="limit-info">
          <div style={{ display: 'flex', alignItems: 'center', height: '20px' }}>
            <span style={{ marginLeft: '20px', marginRight: '20px' }}>
              <b>Term:</b> {term}
            </span>
            <div style={{ borderLeft: '1px solid #000', height: '20px', marginRight: '20px' }}></div>
            <span style={{ marginRight: '20px' }}>
              <b>Limit:</b> {formCreateLimit ?? 'Unlimited'} / Month{formCreateLimit && billingPeriod === 12 && ' (' + formCreateLimit * 12 + ' / Year)'}
            </span>
            <div style={{ borderLeft: '1px solid #000', height: '20px', marginRight: '20px' }}></div>
            <button className="link-button" onClick={() => setShowConfigureFormCreatedLimits(!showConfigureFormCreatedLimits)}>
              Configure Limit
            </button>
          </div>
          <hr />
        </div>
        {formCreateLimit && (
          <>
            {billingPeriod === 12 && (
              <div style={{ width: '500px', paddingBottom: '10px' }}>
                <Progress
                  value={annualCount}
                  total={formCreateLimit * 12}
                  label={`Current Year: ${annualCount}/${formCreateLimit * 12} used`}
                  warning={annualCount >= formCreateLimit * 12 * 0.9}
                  error={annualCount >= formCreateLimit * 12}
                  size="small"
                />
              </div>
            )}
            <div style={{ width: '500px', paddingBottom: '10px' }}>
              <Progress
                value={currentCount}
                total={formCreateLimit}
                label={`Current Month: ${currentCount}/${formCreateLimit} used`}
                warning={currentCount >= formCreateLimit * 0.9}
                error={currentCount >= formCreateLimit}
                size="small"
              />
            </div>
          </>
        )}
        <div className="chart" style={{ width: `500px`, height: `300px` }}>
          <Loader isLoading={createdDataLoading} />
          <canvas key="canvas" ref={canvasEl}></canvas>
        </div>
      </div>
      {showConfigureFormCreatedLimits && (
        <Modal open={showConfigureFormCreatedLimits} onClose={() => setShowConfigureFormCreatedLimits(false)} dimmer="inverted">
          <Modal.Header>Configure Form Created Limits</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div className="ui form">
                <div className="field">
                  <div>
                    <strong>Note: </strong>All limits are set as the monthly limit. Annual limits are automatically calculated using the monthly limit
                  </div>
                  <label>Monthly Form Create Limit</label>
                  <input
                    type="number"
                    value={formCreateLimit ?? ''}
                    onChange={(e) => {
                      setFormCreateLimit(Number(e.target.value));
                      setAccountFeatureValue(updates, setUpdates, formCreateLimitFeatureFlag, Number(e.target.value));
                      setFormLimitsChanged(true);
                      setFormLimitsCleared(false);
                    }}
                    placeholder="Enter new limit"
                  />
                </div>
              </div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowConfigureFormCreatedLimits(false)}>Close</Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}
