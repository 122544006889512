import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { Module } from '../Module';
import moment from 'moment';

type IndependentUser = {
  independentUser: User;
  independentUserClients: Clients[];
};

type User = {
  independentUserId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type Clients = {
  clientId: string;
  name: string;
  displayName: string;
  active: boolean;
  deletedDate?: Date;
  trialEndDate?: Date;
  createdDate?: Date;
  subscription: string;
  companySize: string;
  promoCode: string;
  acv: number;
};

export function IndependentUser() {
  const { id } = useParams();
  const [independentUser, independentUserLoading, independentUserLoaded] = useApi<IndependentUser>(`/api/independentUser/${id}`);

  if (independentUserLoading) {
    return <Loader isLoading />;
  }

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  return (
    <div className="account">
      <Helmet>
        <title>Independent User</title>
      </Helmet>
      <div className="modules">
        <Module title="Independent User" className="details-module">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>Id</td>
                <td>{independentUser.independentUser.independentUserId}</td>
              </tr>
              <tr>
                <td>Email Address</td>
                <td>{independentUser.independentUser.emailAddress}</td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{independentUser.independentUser.firstName}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{independentUser.independentUser.lastName}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{independentUser.independentUser.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </Module>
        <Module title="Clients" className="details-module">
          <table className="ui very basic collapsing celled table">
            <thead>
              <tr>
                <th>Display Name</th>
                <th>Name</th>
                <th>Active</th>
                <th>Company Size</th>
                <th>ACV</th>
                <th>Promo Code</th>
                <th>Level</th>
                <th>Signup Date</th>
                <th>Deleted Date</th>
                <th>Trial End Date</th>
              </tr>
            </thead>
            <tbody>
              {independentUserLoaded
                ? independentUser.independentUserClients.map((client: Clients) => (
                    <tr>
                      <td>
                        <a href={`/account/${client.clientId}`}>{client.displayName}</a>
                      </td>
                      <td>{client.name}</td>
                      <td>{client.active ? 'true' : 'false'}</td>
                      <td>{client.companySize}</td>
                      <td>{client.acv}</td>
                      <td>{client.promoCode}</td>
                      <td>{client.subscription}</td>
                      <td>{client.createdDate ? moment.utc(client.createdDate).local().calendar(null, calendarOptions) : null}</td>
                      <td>{client.deletedDate ? moment.utc(client.deletedDate).local().calendar(null, calendarOptions) : null}</td>
                      <td>{client.trialEndDate ? moment.utc(client.trialEndDate).local().calendar(null, calendarOptions) : null}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Module>
      </div>
    </div>
  );
}
