import React, { useLayoutEffect, useRef } from 'react';
import moment from 'moment';

import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import Chart from 'chart.js';
import './Charts.scss';

export type PublicApiAuthProps = {
  title?: string;
  clientId?: string;
  startDate?: string;
  endDate?: string;
  width?: number;
  height?: number;
  useLegend?: boolean;
};

export function PublicApiAuth(props: PublicApiAuthProps) {
  const defaultStartDate = useRef<TypeMePlease>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate || '';
  const useLegend = props.useLegend === undefined ? true : props.useLegend;

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const [basicData, basicDataLoading, basicDataLoaded] = useGetData('basic', props.clientId, startDate, endDate);
  const [bearerData, bearerDataLoading, bearerDataLoaded] = useGetData('bearer', props.clientId, startDate, endDate);

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'line',
        options: {
          legend: { display: useLegend },
          tooltips: {
            callbacks: {
              title: (tooltipItem) => moment(tooltipItem[0].label).format('MMM D'),
              labelColor: (tooltipItem, data) => {
                const tooltipColor = data.data.datasets[tooltipItem.datasetIndex].backgroundColor;
                return {
                  borderColor: tooltipColor,
                  backgroundColor: tooltipColor,
                };
              },
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'day',
                },
              },
            ],
          },
        },
      });
    }

    chart.current.data.labels = [];
    chart.current.data.datasets = [];

    addDataset('Basic', basicDataLoaded, basicData, 'rgba(109, 184, 100, 1)');
    addDataset('Bearer', bearerDataLoaded, bearerData, 'rgba(54, 162, 235, 1)');
    chart.current.update();
  }, [basicDataLoaded, basicData, bearerDataLoaded, bearerData]);

  const addDataset = (label, loaded, data, color) => {
    if (!loaded || data.length === 0) return;
    chart.current.data.labels = [...chart.current.data.labels, ...data[0].dates.map((x) => x.date)].distinct();
    chart.current.data.datasets = [
      ...chart.current.data.datasets,
      {
        label: label,
        data: data[0].dates.sort((a, b) => (a.date < b.date ? -1 : 1)).map((x) => x.count),
        pointRadius: 1,
        fill: false,
        borderWidth: 2,
        backgroundColor: color,
        borderColor: color,
      },
    ];
  };

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <Loader isLoading={basicDataLoading || bearerDataLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}

const useGetData = (authType, clientId, startDate, endDate) => {
  const clientFragment = clientId ? `/clients/${clientId}` : '';
  return useApim<TypeMePlease>(
    `/metrics/publicapi/requests${clientFragment}?categories=all&authType=${authType}&startDate=${startDate}&endDate=${endDate}`,
    clientId
  );
};
