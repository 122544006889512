import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Checkbox, Dropdown } from 'semantic-ui-react';
import Pagination from '../../paging/Pagination';
import { copyTextToClipboard } from '../../../utility/clipboard';
import './Templates.scss';
import { apimCall } from '../../../utility/api';
import SearchInput from '../../../helpers/SearchInput';

export type TemplatesProps = {
  id: string;
  account: TypeMePlease;
};

export default function Templates(props: TemplatesProps) {
  const { id, account } = props;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState();
  const [showOnlyDeleted, setShowOnlyDeleted] = useState(false);
  const [loading, setLoading] = useState();

  const params = getTemplateFilterParams(false, search, pageSize, page);
  const [templatesResult, templatesLoading, templatesLoaded, templatesRefresh] = useApim<TypeMePlease>(`/templates?${params.join('&')}`, id);

  function getTemplateFilterParams(isPublished, search, pageSize, page) {
    const params = [];
    if (search) {
      params.push(`filter=${encodeURIComponent(`contains(Name, '${search.replace(/'/g, "''")}') eq true`)}`);
    }
    if (isPublished) {
      params.push(`publishedOnly=true`);
    }
    if (showOnlyDeleted) {
      params.push('onlyDeleted=true');
    }
    params.push('includeDeleted=true');
    params.push('includeOnboardingTemplates=true');
    params.push(`take=${pageSize}`);
    params.push(`skip=${pageSize * (page - 1)}`);

    return params;
  }

  const restoreTemplate = async (templateId) => {
    setLoading(templateId);
    try {
      await apimCall(`/templates/${templateId}/restore`, 'PUT', '', account.clientId);
      setLoading(null);
      templatesRefresh();
    } catch (err) {
      alert('Error recovering template');
      setLoading(null);
    }
  };

  return (
    <div className="templates">
      <SearchInput placeholder="Search templates" searchFilter={search} updateSearchFilter={setSearch} />
      <div className="toggle-wrapper">
        <Checkbox toggle label="Show Only Deleted Templates" checked={showOnlyDeleted} onChange={() => setShowOnlyDeleted(!showOnlyDeleted)} />
      </div>
      <table className="ui table">
        <thead>
          <tr>
            <th>Template Name</th>
            <th>Template Folder</th>
            <th>TemplateId</th>
            <th>Created Date</th>
            <th>Last Updated By</th>
            <th>Deleted Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {templatesLoaded
            ? templatesResult.items.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>{row.folder}</td>
                    <td>
                      {row.id}{' '}
                      <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(row.id)}>
                        <i className="copy outline icon"></i>
                      </button>
                    </td>
                    <td>{moment.utc(row.createdDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
                    <td>{row.lastUpdatedBy == '00000000-0000-0000-0000-000000000000' ? '' : row.lastUpdatedBy}</td>
                    <td>{row.deletedDate ? moment.utc(row.deletedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
                    <td>
                      {row.deletedDate ? (
                        <Dropdown trigger={<Button icon="ellipsis horizontal" loading={row.id === loading} />} pointing="right" icon={null}>
                          <Dropdown.Menu>
                            <Dropdown.Item text="Restore" icon="configure" onClick={() => restoreTemplate(row.id)} />
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!templatesLoading ? (
        <Pagination
          pageSize={pageSize}
          page={page}
          totalRows={templatesResult ? templatesResult.totalCount : 0}
          changePageSize={setPageSize}
          changePage={setPage}
        />
      ) : null}
      <Loader isLoading={templatesLoading} />
    </div>
  );
}
