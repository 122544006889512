
import React from 'react'
import './CommentInput.scss';

export type CommentInputProps = { 
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

export function CommentInput(props: CommentInputProps) {
  return (
    <div className="ui form field comment-input">
      <textarea className='comment' placeholder={props.placeholder}
          value={props.value || ''} onChange={(e) => props.onChange(e.target.value)} disabled={props.disabled}/>
    </div>
  );
}

