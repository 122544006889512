import React, { useState } from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import { useMessages } from '../../context/MessagesContext';
import { useApi } from '../../hooks/useApi';
import { apiCall } from '../../utility/api';
import Loader from '../Loader';
import { AddOnsAddRow } from './AddOnsAddRow';
import './AddOnsClientConfigurationTable.scss';
import { AddOnsClientConfigurationTableHeader } from './AddOnsClientConfigurationTableHeader';
import { AddOnsClientConfigurationTableRow } from './AddOnsClientConfigurationTableRow';
import { AddOnsSaveTableFooter } from './AddOnsSaveTableFooter';
import { AddOnClientConfigurationDto, AddOnConfigurationDto } from './types';

export type AddOnsClientConfigurationTableProps = {
  readOnly: boolean;
  clientId?: string;
}

export function AddOnsClientConfigurationTable(props: AddOnsClientConfigurationTableProps) {
  const { readOnly, clientId } = props;
  const { showMessage } = useMessages();

  //global add ons
  const [addOnConfigurations, addOnConfigurationsLoading, _addOnConfigurationsLoaded, refreshAddOnConfigurations] = useApi<AddOnConfigurationDto[]>('api/addons/configurations');
  const addOnConfigurationsOrDefault = addOnConfigurations || [];
  
  //Client configurations 
  const [addOnClientConfigurations, addOnClientConfigurationsLoading, _addOnClientConfigurationsLoaded, refreshAddOnClientConfigurations] = useApi<AddOnClientConfigurationDto[]>(`api/addons/configurations/client/${clientId}`);
  const addOnClientConfigurationsOrDefault = addOnClientConfigurations || [];

  const [addOnClientConfigurationChanges, setAddOnClientConfigurationChanges] = useState<AddOnClientConfigurationDto[]>([]);
  const [addOnClientConfigurationDeletions, setAddOnClientConfigurationDeletions] = useState<AddOnClientConfigurationDto[]>([]);
  
  const [newAddOnClientConfiguration, setNewAddOnClientConfiguration] = useState<AddOnClientConfigurationDto>(null);
  const [comment, setComment] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);

  const addOnClientConfigurationsToDisplay = addOnClientConfigurationsOrDefault
    .map((addOnClientConfiguration) => 
      addOnClientConfigurationChanges.find((change) => change.addOnConfigurationId === addOnClientConfiguration.addOnConfigurationId) || addOnClientConfiguration);
  
  const unOverridenAddOns = addOnConfigurationsOrDefault.filter((addOn) => 
    !addOnClientConfigurationsToDisplay.some((x) => x.addOnConfigurationId === addOn.addOnConfigurationId));
  const nextAddOnOverride = unOverridenAddOns.length > 0 ? unOverridenAddOns[0] : null;

  const onAddOnClientConfigurationChange = (addOnClientConfiguration: AddOnClientConfigurationDto) => {
    const index = addOnClientConfigurationChanges.findIndex((x) => x.addOnConfigurationId === addOnClientConfiguration.addOnConfigurationId);
    const newChanges = [...addOnClientConfigurationChanges];
    if(index > -1 ) { 
      newChanges[index] = addOnClientConfiguration;
    } else { 
      newChanges.push(addOnClientConfiguration);
    }

    setAddOnClientConfigurationChanges(newChanges);
  }

  const onAddOnClientConfigurationDelete = (addOnClientConfiguration: AddOnClientConfigurationDto) => { 
    setAddOnClientConfigurationDeletions([...addOnClientConfigurationDeletions, addOnClientConfiguration]);
  }

  const onAddOnClientConfigurationUndoDelete = (addOnClientConfiguration: AddOnClientConfigurationDto) => {
    const addOnsForUpsert = addOnClientConfigurationDeletions.filter(x => x.addOnConfigurationId !== addOnClientConfiguration.addOnConfigurationId);
    setAddOnClientConfigurationDeletions(addOnsForUpsert);
  }

  const onNewAddOnClientConfigurationChange = (addOnClientConfiguration: AddOnClientConfigurationDto) => {
    if (addOnClientConfiguration.addOnConfigurationId !== newAddOnClientConfiguration.addOnConfigurationId) {
      const updatedAddOn = getAddOnConfigs(addOnClientConfiguration);
      setNewAddOnClientConfiguration(updatedAddOn);
    }
    else {
      setNewAddOnClientConfiguration(addOnClientConfiguration);
    }
  }

  const getAddOnConfigs = (addOnClientConfiguration: AddOnClientConfigurationDto) => {
    const addOnConfig = addOnConfigurationsOrDefault?.find(x => x.addOnConfigurationId === addOnClientConfiguration.addOnConfigurationId);
    return {...addOnClientConfiguration, 
      pricingType: addOnConfig?.pricingType, 
      monthlyPrice: addOnConfig?.monthlyPrice, 
      yearlyPrice: addOnConfig?.yearlyPrice, 
      userLimit: addOnConfig?.userLimit};
  }

  const onAddNewAddOnClientConfigurationClick = () => { 
    setNewAddOnClientConfiguration({
      clientId: props.clientId,
      addOnConfigurationId: nextAddOnOverride.addOnConfigurationId,
      pricingType: nextAddOnOverride.pricingType,
      monthlyPrice: nextAddOnOverride.monthlyPrice,
      yearlyPrice: nextAddOnOverride.yearlyPrice,
      userLimit: nextAddOnOverride.userLimit,
    });
  }

  const onAddNewAddOnClientConfigurationDelete = (_addOnClientConfiguration: AddOnClientConfigurationDto) => {
    setNewAddOnClientConfiguration(null) 
  }


  const onSaveClick = async () => { 
    if(readOnly) {
      return;
    }
    setIsSaving(true);
    
    try {
      const result = await apiCall(`/api/addons/configurations/client/${clientId}`, 'POST', {
        upserts: [...addOnClientConfigurationChanges, newAddOnClientConfiguration].filter(obj => !!obj),
        deletions: addOnClientConfigurationDeletions,
        comment: comment
      });

      if(result.status !== 200) { 
        showMessage('Failed to save the client add-on configuration changes');
        return;
      }

      setComment(null);
    } catch(e) { 
      console.log(e);
      showMessage('Failed to save the client add-on configuration changes');
    }
      
    setIsSaving(false);
    setAddOnClientConfigurationChanges([]);
    setAddOnClientConfigurationDeletions([]);
    setNewAddOnClientConfiguration(null) 

    refreshAddOnConfigurations();
    refreshAddOnClientConfigurations(); 
  }

  const canSave = () => { 
    if(readOnly) { 
      return false;
    }
    
    if(comment == null || comment === '') { 
      return false;
    }

    if(!addOnClientConfigurationChanges.length && !newAddOnClientConfiguration && !addOnClientConfigurationDeletions.length) {
      return false;
    }

    const changes = [...addOnClientConfigurationChanges, newAddOnClientConfiguration].filter(obj => !!obj);
    const changesMissingRequiredProps = changes.some(change => { 
      return change.addOnConfigurationId == null || 
      change.pricingType == null ||
      change.monthlyPrice == null || 
      change.yearlyPrice == null
    });

    if(changesMissingRequiredProps) { 
      return false;
    }

    return true;
  }

  const isLoading = isSaving || addOnConfigurationsLoading || addOnClientConfigurationsLoading;
  const addButtonDisabled = nextAddOnOverride == null || readOnly;
  const saveButtonDisabled = !canSave();
  const columnCount = 7;
  const isAddOnsLoaded = _addOnClientConfigurationsLoaded && _addOnConfigurationsLoaded;

  return (
    <div className='add-ons-client-configuration-table'>
      <Loader isLoading={isLoading} />
      <Table singleLine>
        <AddOnsClientConfigurationTableHeader/>
        <TableBody className='add-ons-client-configuration-table-body'>
          {isAddOnsLoaded && addOnClientConfigurationsToDisplay.map((addOnClientConfiguration) => (
            <AddOnsClientConfigurationTableRow addOnClientConfiguration={addOnClientConfiguration}
              addOns={[...unOverridenAddOns, addOnConfigurations.find(x => x.addOnConfigurationId === addOnClientConfiguration.addOnConfigurationId)]}
              key={`client-configurations-${addOnClientConfiguration?.addOnConfigurationId}`} 
              readOnly={readOnly} 
              onChange={onAddOnClientConfigurationChange}
              onDelete={onAddOnClientConfigurationDelete}
              onUndoDelete={onAddOnClientConfigurationUndoDelete}
              addOnClientDeletedConfigurations={addOnClientConfigurationDeletions} />
          ))}
          {!newAddOnClientConfiguration && !props.readOnly &&  (
            <AddOnsAddRow colSpan={columnCount}
              text="Add Client Override"
              onClick={onAddNewAddOnClientConfigurationClick} 
              disabled={addButtonDisabled} />
          )}
          {newAddOnClientConfiguration && 
            <AddOnsClientConfigurationTableRow 
              addOnClientConfiguration={newAddOnClientConfiguration}
              addOns={unOverridenAddOns}
              readOnly={readOnly} 
              onChange={onNewAddOnClientConfigurationChange}
              onDelete={onAddNewAddOnClientConfigurationDelete} />
          }
        </TableBody>
        {!props.readOnly && 
          <AddOnsSaveTableFooter colSpan={columnCount} readOnly={readOnly} disableSave={saveButtonDisabled} comment={comment} onCommentChange={setComment} onSaveClick={onSaveClick} />
        }
      </Table>
    </div>
  );
}
