export interface IFeatureFlagValue {
  boolValue?: boolean;
  numberValue?: number;
  decimalValue?: number;
  jsonValue?: string;
  stringValue?: string;
}

export interface FeatureFlag extends IFeatureFlagValue {
  featureFlagId?: string;
  flagName?: string;
  flagCodeName?: string;
  description?: string;
  category?: string;
  clientControlled?: boolean;
  readGroupIds?: string;
  writeGroupIds?: string;
  dataType?: FeatureFlagDataType;
  subscriptionDefaults?: FeatureFlagSubscriptionDefault[];
  promoCodeDefaults?: FeatureFlagPromoCodeDefault[];
  lastUpdatedDate?: Date;
}

export interface FeatureFlagSubscriptionDefault extends IFeatureFlagValue {
  featureFlagId?: string;
  subscription?: string;
  lastUpdatedDate?: Date;
  boolValue?: boolean;
  numberValue?: number;
  decimalValue?: number;
  jsonValue?: string;
  stringValue?: string;
}

export interface FeatureFlagPromoCodeDefault extends IFeatureFlagValue {
  promoCodeId?: string;
  lastUpdatedDate?: Date;
}

export interface FeatureFlagExperiment {
  featureFlagExperimentId?: string;
  featureFlagId?: string;
  experimentName?: string;
  description?: string;
  targetingDetails?: FeatureFlagExperimentTargetingDetails;
  startDate?: Date;
  endDate?: Date;
  segmentId?: string;
  segmentHistoricalId?: string;
  experimentGroupBoolValue?: boolean;
  experimentGroupNumberValue?: number;
  experimentGroupDecimalValue?: number;
  experimentGroupJsonValue?: string;
  experimentGroupStringValue?: string;
  lastUpdatedDate?: Date;
}

export interface FeatureFlagExperimentAnalytics {
  isControlGroup: boolean;
  clients: number;
  ctps: number;
  subscriptionAcv: number;
  addOnAcv: number;
  totalAcv: number;
  licenses: number;
  unsubscribes: number;
  pendingDowngradeToBasic: number;
}

export interface FeatureFlagExperimentTargetingDetails {
  clientType?: FeatureFlagExperimentClientType;
  subscriptions?: string[];
  featureFlags?: FeatureFlagTargetingFeatureFlag[];
  ignoreInternalAccounts?: boolean;
  experimentSize?: number;
  qualifierSkipSize?: number;
  timeToRequalifyMinutes?: number;
}

export interface FeatureFlagTargetingFeatureFlag extends IFeatureFlagValue {
  featureFlagId?: string;
}

export interface PromoCode {
  id: string;
  code: string;
  description: string;
}

export interface FeatureFlagAnalyticsTotals {
  totalFeatureFlagClientOverrides: number;
  totalFeatureFlagExperiments: number;
  totalFeatureFlagExperimentParticipants: number;
}

export interface FeatureFlagExperimentAnalyticsTotals {
  totalFeatureFlagExperimentParticipantsByExperiment: { [featureFlagExperimentId: string]: number };
  totalFeatureFlagExperimentControlParticipantsByExperiment: { [featureFlagExperimentId: string]: number };
}

export interface FeatureFlagAnalyticsByDay {
  featureFlagOverridesQuantityByDay: { date: Date; value: boolean | string | number; quantity: number }[];
  featureFlagExperimentQuantityByDay: { date: Date; featureFlagExperimentId: string; quantity: number }[];
}

export enum FeatureFlagExperimentClientType {
  New = 0,
  Existing = 1,
  NewAndExisting = 2,
  Anonymous = 3,
}

export enum FeatureFlagDataType {
  BoolValue = 0,
  NumberValue = 1,
  DecimalValue = 2,
  JsonValue = 3,
  StringValue = 4,
}
