import Axios, { AxiosError, Method } from 'axios';
import { useState, useLayoutEffect } from 'react';
import { getApimUrl } from '../utility/api';

function useApiWithOptions<T>(
  url: string,
  skip?: boolean,
  callback?: (arg: any) => void,
  headers?: any,
  httpMethod: Method = 'GET',
  body?: any
): [result: T, loading: boolean, loaded: boolean, refresh: (value?: T) => void, setResult: (result: T) => void, error: AxiosError] {
  const [result, setResult] = useState<T>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [invokeRefresh, setInvokeRefresh] = useState({});

  const refresh = () => {
    setInvokeRefresh({});
  };

  useLayoutEffect(() => {
    let cancelled = false;
    if (skip) {
      setResult(null);
      setLoading(false);
      setLoaded(false);

      if (callback) {
        callback(null);
      }
    } else {
      setError(null);
      setLoading(true);
      Axios(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
        // @ts-ignore - `mode` doesn't exist on axios config
        mode: 'cors',
        method: httpMethod || 'GET',
        data: body ? JSON.stringify(body) : null,
      })
        .then((r) => {
          if (!cancelled) {
            setResult(r.data);
            setLoading(false);
            setLoaded(true);

            if (callback) {
              callback(r.data);
            }
          }
        })
        .catch((e) => {
          console.error(e);
          setError(e);
          setResult(null);
          setLoading(false);
          setLoaded(false);

          if (callback) {
            callback(null);
          }
        });
    }
    return () => {
      cancelled = true;
    };
  }, [url, invokeRefresh, skip, callback]);

  return [result, loading, loaded, refresh, setResult, error];
}

export function useApi<T>(url: string, skip?: boolean) {
  return useApiWithOptions<T>(url, skip, null);
}

export function useApim<T>(url: string, clientId?: string, userId?: string, skip?: boolean, httpMethod: Method = 'GET', body?: any) {
  const headers = {};
  if (clientId) {
    headers['X-Client-Id'] = clientId;
  }
  if (userId) {
    headers['X-User-Id'] = userId;
  }

  return useApiWithOptions<T>(getApimUrl(url), skip, null, headers, httpMethod, body);
}

export function useApiWithCallback<T>(url: string, callback: (arg: any) => void) {
  return useApiWithOptions<T>(url, false, callback);
}
