import React from 'react';
import './SubscriptionFeatures.scss';
import { Checkbox, Input, TextArea } from 'semantic-ui-react';
import { useApi } from '../../hooks/useApi';
import { FeatureFlag, FeatureFlagDataType, FeatureFlagSubscriptionDefault } from '../featureflags/types';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

export type SubsciptionFeaturesProps = { 
  subscription: string;
}
export function SubscriptionFeatures(props: SubsciptionFeaturesProps) {
  const navigate = useNavigate();
  const [featureFlags, featureFlagsLoading, featureFlagsLoaded] = useApi<FeatureFlag[]>('/api/featureflags');
  const [featureFlagDefaults, featureFlagDefaultsLoading, featureFlagDefaultsLoaded] =
    useApi<FeatureFlagSubscriptionDefault[]>('/api/featureflags/defaults');

  const handleFeatureClick = (featureFlagId: string) => {
    navigate(`/featureflags/${featureFlagId}`);
  };

  const getFeatures = () => {
    const filterDefaultsOrEmpty = featureFlagDefaults || [];
    const featureFlagsOrEmpty = featureFlags || [];
    const subscriptionFeatureDefaults = props.subscription == null ? (featureFlagsOrEmpty)
      : (filterDefaultsOrEmpty).filter((x) => x.subscription === props.subscription);
    const categories = featureFlagsOrEmpty
      .filter((elem, index) => featureFlagsOrEmpty.findIndex((obj) => obj.category === elem.category) === index)
      .map((x) => x.category)
      .sort((a, b) => a.localeCompare(b.toLowerCase()));

    const categoryFeatures = categories.map((category) => {
      const featuresForCategory = featureFlagsOrEmpty.filter((featureFlag) => featureFlag.category === category);

      const featureGroup = (featuresForCategory || [])
        .filter(feature => (subscriptionFeatureDefaults as FeatureFlag[]).find((x) => x.featureFlagId === feature.featureFlagId))
        .map((feature) => {
          const selectedDefault = (subscriptionFeatureDefaults as FeatureFlag[]).find((x) => x.featureFlagId === feature.featureFlagId);
          const featureFlag = featuresForCategory.find((x) => x.featureFlagId === selectedDefault.featureFlagId);

          const checkboxComponent = (<Checkbox label={featureFlag.flagName} checked={Boolean(selectedDefault.boolValue).valueOf()} disabled={true} />);
          const numericComponent = (
          <>
            <span style={{ opacity: 0.5 }}>{featureFlag.flagName}: </span>
            <Input type="number" value={selectedDefault.numberValue} disabled={true} />
          </>)
            
          const decimalComponent = (
                <>
                    <span style={{ opacity: 0.5 }}>{featureFlag.flagName}: </span>
                    <Input type="number" value={selectedDefault.decimalValue} disabled={true} />
                </>
          );

          const jsonValueComponent = (
                <div className="json-value-component" onClick={() => handleFeatureClick(featureFlag.featureFlagId)}>
                    <span style={{ opacity: 0.5 }}>{featureFlag.flagName}: </span>
                    <div className="text-area-overlay">
                        <TextArea className="text-area-json" value={selectedDefault.jsonValue} disabled={true} />
                    </div>
                </div>
          );

          const stringValueComponent = (
                <div className="string-value-component" onClick={() => handleFeatureClick(featureFlag.featureFlagId)}>
                    <span style={{ opacity: 0.5 }}>{featureFlag.flagName}: </span>
                    <div className="text-area-overlay">
                        <TextArea className="text-area-string" value={selectedDefault.stringValue} disabled={true} />
                    </div>
                </div>
          );

            return (
            <div key={featureFlag.featureFlagId} className="feature-row feature-flag" onClick={() => handleFeatureClick(featureFlag.featureFlagId)}>
              {featureFlag.dataType === FeatureFlagDataType.BoolValue && checkboxComponent}
              {featureFlag.dataType === FeatureFlagDataType.NumberValue && numericComponent}
              {featureFlag.dataType === FeatureFlagDataType.DecimalValue && decimalComponent}
              {featureFlag.dataType === FeatureFlagDataType.JsonValue && jsonValueComponent}
              {featureFlag.dataType === FeatureFlagDataType.StringValue && stringValueComponent}
            </div>
          );
        });

      return featureGroup.filter(g => !!g).length > 0 && (
        <div key={category} className="feature-category">
          <h3 className="feature-row">{category}</h3>
          {featureGroup}
        </div>);
    });

    return categoryFeatures;
  };

  const loading = featureFlagsLoading || featureFlagDefaultsLoading;
  return (
    <div className="subscription-features">
      <Loader isLoading={loading} />
      {getFeatures()}
    </div>
  );
}
