export type ApiConfigsDto = {
  apiLimitDay: number;
  apiLimitHour: number;
  apiLimitMinute: number;
};

export const enum ApiThrottlingScope {
  day,
  hour,
  minute
}

export type ApiThrottlingSupportedLimits = {
  day: number[];
  hour: number[];
  minute: number[];
};

export type ApiThrottlingLimits = {
  day: number,
  hour: number,
  minute: number;
}

export type ApiThrottlingDisplay = {
  day: number | string;
  hour: number | string;
  minute: number | string;
};
