import React from 'react';
import './AddOnsClientConfigurationTableHeader.scss';
import { TableHeader, TableRow, TableHeaderCell } from 'semantic-ui-react';

export function AddOnsClientConfigurationTableHeader() {
  return (
    <TableHeader className='add-ons-client-configuration-table-header'>
      <TableRow className="add-ons-client-configuration-table-header-row">
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-title'>Add On</TableHeaderCell>
        <TableHeaderCell className='add-ons-subscription-configuration-table-header-value add-on-title'>Disabled</TableHeaderCell>
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-price'>Pricing Type</TableHeaderCell>
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-price'>Monthly Price</TableHeaderCell>
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-price'>Annual Price</TableHeaderCell>
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-user-limit'>User Limit</TableHeaderCell>
        <TableHeaderCell className='add-on-client-configuration-table-header-value add-on-metadata'></TableHeaderCell>
      </TableRow>
    </TableHeader>
  );
}