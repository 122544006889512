import React from 'react';
import './AddOnsConfigurationTableRow.scss';
import { AddOnConfigurationDto } from './types';
import { Checkbox, Dropdown, Icon, Input, Popup, TableCell, TableRow } from 'semantic-ui-react';
import moment from 'moment';
import { CalendarOptions, PricingTypeDropdownValues } from './AddOnConstants';
import { DeleteAddOnButton } from './DeleteAddOnButton';
import { FeatureFlag } from '../featureflags/types';


export type AddOnsConfigurationTableRowProps = { 
  addOnConfiguration: AddOnConfigurationDto;
  featureFlags: FeatureFlag[];
  readOnly?: boolean;
  onChange: (addOnConfiguration: AddOnConfigurationDto) => void;
  onDelete?: (addOnConfiguration: AddOnConfigurationDto) => void;
}

export function AddOnsConfigurationTableRow(props: AddOnsConfigurationTableRowProps) {
  const { addOnConfiguration, featureFlags, readOnly } = props;
    

  const featureFlagsDropdown = (featureFlags || [])
    .map((featureFlag) => ({ key: featureFlag.featureFlagId, text: featureFlag.flagName, value: featureFlag.featureFlagId }));

  const onPropertyChange = (property: keyof AddOnConfigurationDto, value: any) => {
    props.onChange({ ...props.addOnConfiguration, [property]: value });
  }

  const onDelete = () => { 
    props.onDelete(props.addOnConfiguration);
  }
  
  return (
    <TableRow className="add-ons-configuration-table-row">
      <TableCell className='add-ons-configuration-table-row-value add-on-recurly-code'>
        {!readOnly && !!props.onDelete && <DeleteAddOnButton onClick={onDelete} /> }
        <Input fluid placeholder='Recurly AddOn Code' 
          disabled={readOnly}
          value={addOnConfiguration.code ?? ''} 
          onChange={(_, data) => onPropertyChange('code', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-title'>
        <Input fluid placeholder='Example add on`' 
          disabled={readOnly}
          value={addOnConfiguration.title ?? ''} 
          onChange={(_, data) => onPropertyChange('title', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-description'>
        <Input fluid placeholder='Enables access to premium features' 
          disabled={readOnly}
          value={addOnConfiguration.description ?? ''} 
          onChange={(_, data) => onPropertyChange('description', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-feature-flag'>
        <Dropdown
          search
          clearable
          selection
          value={addOnConfiguration.featureFlagId} 
          options={featureFlagsDropdown}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('featureFlagId', data.value)} />    
      </TableCell>


      <TableCell className='add-ons-configuration-table-row-value add-on-pricing-type'>
        <Dropdown 
          value={addOnConfiguration.pricingType}
          options={PricingTypeDropdownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('pricingType', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-monthly-price'>
        <Input fluid placeholder='Price when billed monthly'
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnConfiguration.monthlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('monthlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-yearly-price'>
        <Input fluid placeholder='Price when billed annually' 
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={addOnConfiguration.yearlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('yearlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-user-limit'>
        <Input fluid 
          type='number'
          step='1'
          min='0' 
          disabled={readOnly}
          value={addOnConfiguration.userLimit ?? ''} 
          onChange={(_, data) => onPropertyChange('userLimit', data.value)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-unsubscribe'>
        <Checkbox fluid
          disabled={readOnly}
          checked={addOnConfiguration.userCanUnsubscribe ?? false} 
          onChange={(_, data) => onPropertyChange('userCanUnsubscribe', data.checked)} /> 
      </TableCell>

      <TableCell className='add-ons-configuration-table-row-value add-on-metadata'>
        <Popup
          on='click'
          trigger={<Icon size='small' circular name='info' />}
          content= { 
            <div className='add-ons-dates'>
              <div className='add-ons-dates-section'>
                <label>Created Date</label>
                <span>{addOnConfiguration.createdDate == null ? '--' : moment.utc(addOnConfiguration.createdDate).local().calendar(null, CalendarOptions)}</span>
              </div>
              <div className='add-ons-dates-section'>
                <label>Last Updated Date</label>
                <span>{addOnConfiguration.lastUpdatedDate == null ? '--' : moment.utc(addOnConfiguration.lastUpdatedDate).local().calendar(null, CalendarOptions)}</span>
              </div>
            </div>
          }
        />
      </TableCell>
    </TableRow>
  );
}