import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Pagination from '../paging/Pagination';
import Loader from '../Loader';
import { useApi } from '../../hooks/useApi';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ChangeLogItem = {
  id: string;
  subscription: string;
  email: string;
  company: string;
  oldACV: number;
  acv: number;
  netACV: number;
  oldAddOnACV: number;
  newAddOnACV: number;
  oldLicenseCount: number;
  licenseCount: number;
  oldPrice: number;
  price: number;
  promoCode: string;
  timestamp: string;
  trialEndDate: string;
  companySize: string;
  oldAddOnSubscriptionCodes: string;
  newAddOnSubscriptionCodes: string;
  netAddOnACV: number;
};

export function ChangeLog() {
  const [elastic, setElastic] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [clientId, setClientId] = useState('');
  const searchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const clientIdFromUrl = searchParams.get('clientId');
    if (clientIdFromUrl) {
      setClientId(clientIdFromUrl);
    }
  }, []);

  const [changeLog, changeLogLoading, changeLogLoaded, refreshChangeLog, setChangeLog] = useApi<ChangeLogItem[]>(
    '/api/changelog?' + createParams(page, pageSize, elastic, clientId)
  );

  const changePageSize = async (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
    setChangeLog(null);
    refreshChangeLog();
  };

  const changePage = async (pageNumber) => {
    setPage(pageNumber);
    setChangeLog(null);
    refreshChangeLog();
  };

  const toggleElastic = () => {
    setPage(1);
    setElastic(!elastic);
    setChangeLog(null);
    refreshChangeLog();
  };

  return (
    <div style={{ padding: '12px' }}>
      <Helmet>
        <title>Change Log</title>
      </Helmet>
      {!clientId && (
        <div className="ui buttons">
          <button id="cor</div>e" className={`ui button ${!elastic && ' positive'}`} onClick={toggleElastic}>
            Core
          </button>
          <div className="or"></div>
          <button id="elastic" className={`ui button ${!!elastic && ' positive'}`} onClick={toggleElastic}>
            Elastic
          </button>
        </div>
      )}
      <table className="ui celled striped compact selectable table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Company Size</th>
            <th>Net ACV</th>
            <th>Old Lic</th>
            <th>Licenses</th>
            <th>Old Price</th>
            <th>New Price</th>
            <th>Promo Code</th>
            <th>Timestamp</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {changeLog && changeLogLoaded
            ? changeLog.slice(0, pageSize).map((changeLogItem) => {
                return <ChangeLogItem key={changeLogItem.timestamp} {...changeLogItem} />;
              })
            : null}
        </tbody>
      </table>
      <div className="paging">
        <Pagination
          changePageSize={changePageSize}
          changePage={changePage}
          pageSize={pageSize}
          page={page}
          infinitePaging={true}
          hasMorePages={changeLog && changeLogLoaded && changeLog.length > pageSize}
        />
      </div>
      <Loader isLoading={changeLogLoading || !changeLog} />
    </div>
  );
}

function ChangeLogItem(changeLogItem: ChangeLogItem) {
  const { id, company, companySize, netACV, oldLicenseCount, licenseCount, oldPrice, price, promoCode, timestamp } = changeLogItem;

  const style: React.CSSProperties = {};

  if (netACV < 0) {
    style.color = 'red';
  } else {
    style.color = 'green';
  }

  if (netACV > 999 || netACV < -999) {
    style.fontWeight = 'bold';
  }

  return (
    <tr style={style}>
      <td>
        <a style={style} href={`account/${id}`}>
          {company}
        </a>
      </td>
      <td>{companySize}</td>
      <td>{netACV}</td>
      <td>{oldLicenseCount}</td>
      <td>{licenseCount}</td>
      <td>{oldPrice}</td>
      <td>{price}</td>
      <td>{promoCode}</td>
      <td>{moment.utc(timestamp).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>
        <a href={`account/${id}`}>Details</a>
      </td>
    </tr>
  );
}

function createParams(pageNumber: number, pageSize: number, elastic: boolean, clientId?: string) {
  const params = [];

  params.push(`offset=${(pageNumber - 1) * pageSize}`);
  params.push(`fetch=${pageSize + 1}`);
  params.push(`elastic=${elastic}`);

  if (clientId) {
    params.push(`clientId=${clientId}`);
  }

  return params.join('&');
}
