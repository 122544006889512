import { useReducer } from 'react';
import { deepClone } from '../utility/deepClone';

export type UseFormAction = { type: string; value?: unknown; valueObj?: unknown; propertyName?: string };

export type UseForm<T> = [formState: T, setValue: (propertyName: string, value: unknown) => void, setValues: (valueObj: Partial<T>) => void, clear: () => void];

export function reducer<T>(previousState: T, action: UseFormAction) {
  let state = deepClone(previousState);
  if (action.type === 'clear') {
    state = {};
  } else if (action.valueObj) {
    state = Object.assign(state, action.valueObj);
  } else {
    state[action.propertyName] = action.value;
  }
  return state;
}

/**
 * A custom hook that allows you to use a form as a stateful component.
 * @example
 * ```
 *  const [formState, dispatch] = useForm({
 *    name: '',
 *    email: '',
 *    password: ''
 *  });
 *  return (
 *    <form onSubmit={(e) => {
 *      dispatch({ type: 'clear' });
 *    }}>
 *      <input type="text" value={formState.name} onChange={(e) => {
 *        dispatch({ type: 'setValue', propertyName: 'name', value: e.target.value });
 *      }} />
 *      <input type="text" value={formState.email} onChange={(e) => {
 *        dispatch({ type: 'setValue', propertyName: 'email', value: e.target.value });
 *      }} />
 *      <input type="text" value={formState.password} onChange={(e) => {
 *        dispatch({ type: 'setValue', propertyName: 'password', value: e.target.value });
 *      }} />
 *      <button type="submit">Submit</button>
 *    </form>
 *  );
 * ```
 */
export function useForm<T>(initialState: T): UseForm<T> {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const setValue = (propertyName: string, value: unknown) => {
    dispatch({ type: 'setValue', propertyName, value });
  };
  const setValues = (valueObj: unknown) => {
    dispatch({ type: 'setValues', valueObj });
  };
  const clear = () => {
    dispatch({ type: 'clear' });
  };
  return [formState, setValue, setValues, clear];
}
