import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import moment from 'moment';
import React, { useState } from 'react';
import { Dropdown, Button, Checkbox } from 'semantic-ui-react';
import Pagination from '../../paging/Pagination';
import { copyTextToClipboard } from '../../../utility/clipboard';
import { apimCall } from '../../../utility/api';
import { useMessages } from '../../../context/MessagesContext';
import { deepClone } from '../../../utility/deepClone';
import SearchInput from '../../../helpers/SearchInput';
import { NavLink } from 'react-router-dom';

export type FormsProps = {
  id: string;
  account: TypeMePlease;
};

export default function Forms(props: FormsProps) {
  const { id } = props;
  const [onlyDeleted, setOnlyDeleted] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showDrafts, setShowDrafts] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState();
  const [templateId, setTemplateId] = useState();
  const [userId, setUserId] = useState();
  const [pageSize, setPageSize] = useState(25);

  const params = [];

  if (search) {
    params.push(`search=${encodeURIComponent(search)}`);
  }
  if (templateId) {
    params.push(`templateId=${templateId}`);
  }
  if (userId) {
    params.push(`userId=${userId}`);
  }
  if (onlyDeleted) {
    params.push(`onlyDeleted=${onlyDeleted}`);
  }
  if (showCompleted) {
    params.push(`showDrafts=false`);
  }
  if (showDrafts) {
    params.push(`showCompleted=false`);
  }
  params.push(`start=${(pageNumber - 1) * pageSize}`);
  params.push(`limit=${pageSize}`);

  params.push('includes=lastUpdatedByName');

  const [formsResult, formsLoading, formsLoaded, setForms] = useApim<TypeMePlease>(`/forms?${params.join('&')}`, id);

  const clearFormDeletedDate = (id, value = null) => {
    const newFormsResult = deepClone(formsResult);
    const form = newFormsResult?.items.find((x) => x.id === id);
    form ? form.deletedDate = value : null;
    setForms(newFormsResult);
  };

  return (
    <div className="forms">
      <SearchInput placeholder="Search Forms by Name" searchFilter={search} updateSearchFilter={setSearch} />
      &nbsp;&nbsp;
      <SearchInput placeholder="Filter by Template Id" searchFilter={templateId} updateSearchFilter={setTemplateId} />
      &nbsp;&nbsp;
      <SearchInput placeholder="Filter by User Id" searchFilter={userId} updateSearchFilter={setUserId} />
      <br />
      <br />
      <Checkbox toggle label="Only Deleted Forms" checked={onlyDeleted} onChange={() => setOnlyDeleted(!onlyDeleted)} />
      <br />
      <br />
      <Checkbox toggle label="Only Completed Forms" checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)} />
      <br />
      <br />
      <Checkbox toggle label="Only Draft Forms" checked={showDrafts} onChange={() => setShowDrafts(!showDrafts)} />
      <table className="ui table">
        <thead>
          <tr>
            <th>Form Name</th>
            <th>Template Id</th>
            <th>Owner</th>
            <th>Completed Date</th>
            <th>Last Updated Date</th>
            <th>Last Updated By</th>
            <th>Form Id</th>
            <th>Deleted Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {formsLoaded
            ? formsResult?.items.map((form) => {
                return <Form key={form.id} form={form} clientId={id} clearFormDeletedDate={clearFormDeletedDate} />;
              })
            : null}
        </tbody>
      </table>
      <div className="paging">
        <Pagination
          page={pageNumber}
          changePageSize={setPageSize}
          totalRows={formsResult ? formsResult.totalCount : 0}
          pageSize={pageSize}
          changePage={setPageNumber}
        />
      </div>
      <Loader isLoading={formsLoading} />
    </div>
  );
}

function Form(props) {
  const { clientId, form, clearFormDeletedDate } = props;
  const { name, templateId, groupName, userName, lastUpdatedDate, lastUpdatedByName, deletedDate, id, completedDate } = form;
  const [actionLoading, setActionLoading] = useState(false);
  const { showMessage } = useMessages();

  const restore = async () => {
    setActionLoading(true);

    try {
      const result = await apimCall(`/forms/${id}/restore`, 'POST', '', clientId);
      if (result.statusText === 'No Content') {
        clearFormDeletedDate(id);
      } else {
        showMessage(`Unable to restore Form.`);
      }
    } catch (error) {
      console.log(error);
    }
    setActionLoading(false);
  };

  return (
    <tr>
      <td>
        <NavLink to={`/account/${clientId}/forms/${id}`} end>
          {name}
        </NavLink>
      </td>
      <td>
        {templateId}{' '}
        <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(templateId)}>
          <i className="copy outline icon"></i>
        </button>
      </td>
      <td>{groupName ? groupName : userName}</td>
      <td>{completedDate ? moment.utc(completedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
      <td>{moment.utc(lastUpdatedDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>{lastUpdatedByName}</td>
      <td>
        {id}{' '}
        <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(id)}>
          <i className="copy outline icon"></i>
        </button>
      </td>
      <td>{deletedDate ? moment.utc(deletedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
      <td>
        {deletedDate != null ? (
          <Dropdown trigger={<Button icon="ellipsis horizontal" loading={actionLoading} />} pointing="right" icon={null}>
            <Dropdown.Menu>
              <Dropdown.Item text="Restore" icon="configure" onClick={restore} />
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </td>
    </tr>
  );
}
