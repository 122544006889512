import React from 'react';
import './SubscriptionAddOns.scss';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { AddOnsConfigurationTable } from '../addons/AddOnsConfigurationTable';
import { AddOnsSubscriptionConfigurationTable } from '../addons/AddOnsSubscriptionConfigurationTable';

export type SubscriptionAddOnsProps = { 
  subscription: string;
}
export function SubscriptionAddOns(props: SubscriptionAddOnsProps) {
  const { subscription } = props;
  const readOnly = !canIUse(AppFeatures.EditSubscriptions);
  
  return (
    <div className='subscription-add-ons'>
      { subscription == null && 
        <AddOnsConfigurationTable readOnly={readOnly} />
      }

      { subscription != null && 
        <AddOnsSubscriptionConfigurationTable readOnly={readOnly} subscription={subscription} />
      }
    </div>
  );
}
