import './styles/global.scss';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { useProfile } from './context/ProfileContext';
import Loader from './components/Loader';
import { NotFound } from './components/NotFound';
import { Account } from './components/account/Account';
import { DashboardMetrics } from './components/dashboard/DashboardMetrics';
import { Dashboard } from './components/dashboard/Dashboard';
import { ProServiceLogs } from './components/proservicelogs/ProServiceLogs';
import { WorkflowCopy } from './components/workflowcopy/WorkflowCopy';
import { Apps } from './components/apps/Apps';
import { AppEdit } from './components/apps/AppEdit';
import { AppCreate } from './components/apps/AppCreate';
import { AccountsSearch } from './components/account/search/AccountsSearch';
import { IndependentUser } from './components/independentUser/IndependentUser';
import { AppFeatures, canIUse } from './utility/SecurityHelper';
import { FeatureFlagsList } from './components/featureflags/FeatureFlagsList';
import { EditFeatureFlag } from './components/featureflags/EditFeatureFlag';
import { IndependentUserMerge } from './components/independentUser/IndependentUserMerge';
import { Search } from './components/search/Search';
import { ParentAccount } from './components/parentAccount/ParentAccount';
import { Subscriptions } from './components/subscriptions/Subscriptions';
import { AzureDevOpsReleases } from './components/azuredevops/AzureDevOpsReleases';
import { JiraTickets } from './components/jira/JiraTickets';
import { ChangeLog } from './components/changeLog/ChangeLog';
import { RecentSignups } from './components/recentSignups/RecentSignups';

function App() {
  const { profileLoaded } = useProfile();

  if (!profileLoaded) {
    return <Loader isLoading />;
  }

  return (
    <Layout>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/search" element={<Search />} />
        <Route path="/dashboard/metrics" element={<DashboardMetrics />} />
        <Route path="featureflags" element={<FeatureFlagsList />} />
        <Route path="featureflags/:featureFlagId/*" element={<EditFeatureFlag />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/proservicelogs" element={<ProServiceLogs />} />
        <Route path="/tools/workflowcopy" element={<WorkflowCopy />} />
        <Route path="/apps/create" element={<AppCreate />} />
        {canIUse(AppFeatures.EditApps) ? <Route path="/apps/edit/:id/*" element={<AppEdit />} /> : null}
        <Route path="/apps" element={<Apps />} />
        <Route path="/accountssearch" element={<AccountsSearch />} />
        <Route path="/account/changelog" element={<ChangeLog />} />
        <Route path="/account/recent" element={<RecentSignups />} />
        <Route path="/parentAccount/:id" element={<ParentAccount />} />
        {canIUse(AppFeatures.EditApps) && <Route path="/releasemanagement/releases/*" element={<AzureDevOpsReleases />} />}
        <Route path="/releasemanagement/tickets/*" element={<JiraTickets />} />

        {/* account v2 routes */}
        <Route path="/account/:id/*" element={<Account />} />
        <Route path="/v2/account/:id/*" element={<Account />} />
        {/* independent user routes */}
        <Route path="/independentUser/:id/*" element={<IndependentUser />} />
        <Route path="/independentUser/merge" element={<IndependentUserMerge />} />
        {/* not found route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
