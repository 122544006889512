import React from 'react';
import { Popup } from 'semantic-ui-react';

import './RecentSearchList.scss';

export type RecentSearchListProps = {
  terms: string[];
  trigger: React.ReactNode;
  onTermClick: (term: string, condition: string) => void;
  open?: boolean;
  searchText: string;
};

export function RecentSearchList({ terms, trigger, onTermClick, open = false, searchText }) {
  const listItem = (text) => (
    <li className="item" key={text} onClick={() => onTermClick(text, 'clients')}>
      <span className="text">{text}</span>
    </li>
  );

  return (
    <Popup className="recent-search-list" trigger={trigger} open={open} position="bottom right">
      {searchText ? (
        <Popup.Content>
          <ul className="recent-terms">
            <li className="item" onClick={() => onTermClick(searchText, 'clients')}>
              <span className="text">
                <b>Clients -</b> {searchText}
              </span>
            </li>
            <li className="item" onClick={() => onTermClick(searchText, 'parentAccounts')}>
              <span className="text">
                <b>Parent Accounts -</b> {searchText}
              </span>
            </li>
            <li className="item" onClick={() => onTermClick(searchText, 'independentUsers')}>
              <span className="text">
                <b>Independent Users -</b> {searchText}
              </span>
            </li>
          </ul>
        </Popup.Content>
      ) : null}
      {(terms?.length ?? 0) > 0 ? (
        <>
          <Popup.Header>Recent Searches</Popup.Header>
          <Popup.Content>
            <ul className="recent-terms">{terms?.map((term) => listItem(term))}</ul>
          </Popup.Content>
        </>
      ) : null}
    </Popup>
  );
}
