export const Environments = {
  Dev: 'Dev',
  Beta: 'Beta',
  Prod: 'Prod',
}

export const DeploymentStatus = { 
  Failed: "failed",	
  InProgress: "inProgress",	
  NotDeployed: "notDeployed",	
  PartiallySucceeded: "partiallySucceeded",	
  Succeeded: "succeeded",	
  Undefined: "undefined",	
}


export type AzureDevOpsRelease = {
  releaseDefinitionId: number;
  releaseName: string;
  url: string;
  environmentReleases: AzureDevOpsEnvironmentRelease[];
}

export type AzureDevOpsEnvironmentRelease = { 
  environmentName: string;
  releaseId: number;
  releaseVersion: string;
  url: string;
  deploymentDate: string;
  deployedBy: string;
  status: string;
  environments:AzureDevOpsEnvironmentsOnRelease[];
}

export type AzureDevOpsEnvironmentsOnRelease = { 
  id: number;
  name: string;
  status: string;
  url: string;
}