import './JiraSearch.scss';
import React, { useEffect, useState } from 'react';
import { JiraTicketTable } from './JiraTicketTable';
import { Input } from 'semantic-ui-react';


export function JiraSearch() {
  const [searchInput, setSearchInput] = useState(null);
  const [title, setTitle] = useState(null);
  const [ticketKey, setTicketKey] = useState(null);


  useEffect(() => {
    const debounce = setTimeout(() => {

      const isKeySearch = (searchInput.toUpperCase().startsWith("GD-") || searchInput.toUpperCase().startsWith("SB-"));
      if(isKeySearch) { 
        setTitle(null);
        setTicketKey(searchInput);
      } 

      const title = isKeySearch ? null : searchInput;
      const ticketKey = isKeySearch ? searchInput : null;
      setTitle(title);
      setTicketKey(ticketKey);
    }, 1000);
    return () => clearTimeout(debounce)
}, [searchInput])

  const onSearchInputChange = (e) => { 
    setSearchInput(e.target.value);
  }

  return (
    <div className="jira-search">
      <div>
        <Input onChange={onSearchInputChange} focus placeholder='Search Title...' />
      </div>
      <JiraTicketTable title={title} ticketKey={ticketKey}/>
    </div>
  );
}