import React from 'react';
import './AddOnsSaveTableFooter.scss';
import { Button, TableCell, TableFooter, TableRow } from 'semantic-ui-react';
import { CommentInput } from '../commentinput/CommentInput';

export type AddOnsTableProps = {
  readOnly: boolean;
  comment: string;
  colSpan: number;
  disableSave: boolean;
  onCommentChange: (comment: string) => void;
  onSaveClick: () => void;
}

export function AddOnsSaveTableFooter(props: AddOnsTableProps) {
  const { readOnly, disableSave, colSpan } = props;

  const disableSaveButton = readOnly || disableSave;

  return (
    <TableFooter fullWidth className='add-ons-save-table-footer'>
      <TableRow>
        <TableCell />
        <TableCell className='add-ons-save-table-comment-cell' colSpan={colSpan - 2}>
          <CommentInput placeholder="Reason for the update."
            value={props.comment} onChange={props.onCommentChange} disabled={readOnly}/>
        </TableCell>
        
        <TableCell colSpan='2' className='save-cell'>
          <Button
            primary
            disabled={disableSaveButton}
            onClick={props.onSaveClick}
          >Save</Button>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}
