import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Pagination from '../paging/Pagination';
import Loader from '../Loader';
import { useApi } from '../../hooks/useApi';

type RecentSignupItem = {
  id: string;
  acv: number;
  companySize: string;
  deletedDate: string;
  emailAddress: string;
  name: string;
  promoCode: string;
  signupDate: string;
  subscription: string;
  trialEndDate: string;
};

export function RecentSignups() {
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);

  const [recentSignups, recentSignupsLoading, recentSignupsLoaded, refreshRecentSignups, setRecentSignups] = useApi<RecentSignupItem[]>(
    `/api/recentsignups?offset=${(page - 1) * pageSize}&fetch=${pageSize + 1}`
  );

  const changePageSize = async (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
    setRecentSignups(null);
    refreshRecentSignups();
  };

  const changePage = async (pageNumber) => {
    setPage(pageNumber);
    setRecentSignups(null);
    refreshRecentSignups();
  };

  return (
    <div style={{ padding: '12px' }}>
      <Helmet>
        <title>Recent Signups</title>
      </Helmet>
      <table className="ui celled striped compact selectable table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Company</th>
            <th>Company Size</th>
            <th>ACV</th>
            <th>Promo Code</th>
            <th>Level</th>
            <th>Signup Date</th>
            <th>Deleted Date</th>
            <th>Trial End</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {recentSignups && recentSignupsLoaded
            ? recentSignups.slice(0, pageSize).map((recentSignupItem) => {
                return <RecentSignupItem key={recentSignupItem.signupDate} {...recentSignupItem} />;
              })
            : null}
        </tbody>
      </table>
      <div className="paging">
        <Pagination
          changePageSize={changePageSize}
          changePage={changePage}
          pageSize={pageSize}
          page={page}
          infinitePaging={true}
          hasMorePages={recentSignups && recentSignupsLoaded && recentSignups.length > pageSize}
        />
      </div>
      <Loader isLoading={recentSignupsLoading || !recentSignups} />
    </div>
  );
}

function RecentSignupItem(recentSignupItem: RecentSignupItem) {
  const { id, acv, companySize, deletedDate, emailAddress, name, promoCode, signupDate, subscription, trialEndDate } = recentSignupItem;

  const style: React.CSSProperties = {};

  if ((moment.utc(trialEndDate).isBefore(moment.utc()) && subscription === 'gf-basic') || deletedDate) {
    style.color = 'red';
  } else {
    style.color = 'black';
  }

  return (
    <tr style={style}>
      <td>
        <a style={style} href={`account/${id}`}>
          {emailAddress}
        </a>
      </td>
      <td>
        <a style={style} href={`account/${id}`}>
          {name}
        </a>
      </td>
      <td>{companySize}</td>
      <td>{acv}</td>
      <td>{promoCode}</td>
      <td>{subscription}</td>
      <td>{moment.utc(signupDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>{deletedDate && moment.utc(deletedDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>{moment.utc(trialEndDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>
        <a href={`account/${id}`}>Details</a>
      </td>
    </tr>
  );
}
