import moment from "moment/moment";

export function formatCurrency(number: number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
}

export function formatPercentage(number: number) {
  // `+` to ensure that strings are coersed into numbers
  return (+number * 100).toFixed(2) + ' %';
}

export function formatDateToUtc(dateString, fallbackValue) {
  if (!dateString) {
    return fallbackValue;
  }
  const date = moment(dateString);
  if (date.isValid()) {
    return date.utc().format();
  }
  return fallbackValue;
}

export function formatDateToLocal(dateString, format = 'MM/DD/YYYY h:mm:ss a') {
  const date = moment(dateString);
  if (date.isValid()) {
    return date.utc(dateString).local().format(format);
  }
}

export function formatDateRangeLabel(start: string, end: string, startFormat = 'MMM DD YYYY', endFormat = 'MMM DD YYYY'): string {
  return `${moment(start).format(startFormat)} - ${moment(end).format(endFormat)}`;
}