import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'semantic-ui-react';
import SearchInput from '../../helpers/SearchInput';
import { useApim } from '../../hooks/useApi';
import { apimCall, getMessageFromStatusCode, getApiErrorMessage } from '../../utility/api';
import './WorkflowCopy.scss';

export const WorkflowStatus = {
  ready: 'ready',
  copying: 'copying',
  done: 'done',
  error: 'error',
};

export type WorkflowRowItemProps = {
  clientId: string;
  rowKey: TypeMePlease;
  onRemove: (key) => void;
  onChange: (item: TypeMePlease, id: string, name: string) => void;
  status: string;
  errorMessage: string;
};

export function WorkflowRowItem(props: WorkflowRowItemProps) {
  const { clientId, rowKey, onRemove, onChange, status, errorMessage } = props;
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [errorDisplay, setErrorDisplay] = useState('');
  const [workflow, workflowLoading, workflowLoaded, refresh, setResult, error] = useApim<TypeMePlease>(`/workflows/${id}`, clientId, null, !id, 'GET');
  const inputRef = useRef<TypeMePlease>();

  useEffect(() => inputRef.current.focus(), []);

  useEffect(() => {
    if (error) {
      setErrorDisplay(getApiErrorMessage(error));
    }
    if (workflow) {
      if (workflow.statusCode >= 400) {
        setErrorDisplay(getMessageFromStatusCode(workflow.statusCode));
      } else if (workflow.data) {
        setId(workflow.data.id);
        setName(workflow.data.name);
        setErrorDisplay('');
      } else {
        console.log('Unknown error getting workflow', workflow, error);
        setErrorDisplay('unknown error');
      }
    }
  }, [workflow, error]);

  useEffect(() => {
    setErrorDisplay(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!id) {
      setName('');
      setErrorDisplay('');
      inputRef.current.focus();
    }
    onChange(rowKey, id, name);
  }, [id, name]);

  return (
    <div className="workflow-item">
      <div className="workflow-row">
        <StatusIcon status={status} onRemove={onRemove} />
        <div className="workflow-id">
          <SearchInput
            placeholder="Workflow ID"
            searchFilter={id}
            updateSearchFilter={setId}
            onBlur={setId}
            searching={workflowLoading}
            disabled={status === WorkflowStatus.copying}
            inputRef={inputRef}
          />
        </div>
        <Input
          className="workflow-name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={!id || status === WorkflowStatus.copying}
        />
      </div>
      {errorDisplay ? <div className={`error workflow-error`}>{errorDisplay}</div> : ''}
    </div>
  );
}

function StatusIcon(props) {
  const { status, onRemove } = props;
  if (status === WorkflowStatus.copying) {
    return <i aria-hidden="true" className="circle notch icon status-icon loading" />;
  }
  if (status === WorkflowStatus.done) {
    return <i aria-hidden="true" className="check icon status-icon green" />;
  }
  if (status === WorkflowStatus.error) {
    return <i aria-hidden="true" className="x icon status-icon red" />;
  }
  return <i aria-hidden="true" className="trash alternate icon status-icon hand" onClick={() => onRemove()} />;
}
