import './JiraReleaseSearch.scss';
import React, { useEffect, useState } from 'react';
import { JiraTicketTable } from './JiraTicketTable';
import { Dropdown } from 'semantic-ui-react';
import { JiraVersion } from './types';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';

export type JiraReleaseSearchProps = {
  search?: string;
}
  
export function JiraReleaseSearch(props: JiraReleaseSearchProps) {
  const [releases, releasesLoading] = useApi<JiraVersion[]>(`/api/jira/releases?search=${props.search || null}`);
  const safeVersions = (releases || []).map(version => version);
  const [releaseName, setReleaseName] = useState(null);

  useEffect(() => { 
    setReleaseName(safeVersions?.length && safeVersions[0].name);
  }, [releases]);


  if(releasesLoading) { 
    return <Loader isLoading />;
  }

  const onSearchInputChange = (value) => { 
    setReleaseName(value);
  }

  const getColor = (version: JiraVersion) => 
    version.archived ? 'red' : version.released ? 'green' : 'yellow';

  const versionOptions = safeVersions.map(version => ({ 
    key: version.id,
    text: version.name,
    value: version.name ,
    label: { color: getColor(version), empty: true, circular: true },
  }));

  
  console.log( safeVersions );

  return (
    <div className="jira-release-search">
      <div className='header'>
      <Dropdown
        button
        className='icon'
        floating
        labeled
        icon='filter'
        onChange={(e, data) => onSearchInputChange(data.value)}
        options={versionOptions}
        defaultValue={versionOptions?.length && versionOptions[0].value}
        search
        placeholder='Select Version'
      />
      </div>
      <JiraTicketTable releaseName={releaseName}/>
    </div>
  );
}