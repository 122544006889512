import React from 'react';
import { UserInfo } from '../uses-types';

export type UserInfoTableProps = {
  user: UserInfo;
};

export default function UserInfoTable({ user }) {
  return (
    <table className="user-info-table ui celled striped table">
      <tbody>
        <tr>
          <td>Email Address</td>
          <td>{user.username}</td>
        </tr>
        <tr>
          <td>First Name</td>
          <td>{user.firstName}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{user.lastName}</td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>{user.phoneNumber}</td>
        </tr>
      </tbody>
    </table>
  );
}
