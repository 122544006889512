import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Loader, Popup, Table } from 'semantic-ui-react';
import { useApim } from '../../../hooks/useApi';
import { apimCall } from '../../../utility/api';
import { deepClone } from '../../../utility/deepClone';
import { AppFeatures, canIUse } from '../../../utility/SecurityHelper';
import { AppItemPermissionLevel, ClientAppPermissions, PermissionType, PublishedAppsResponseBody } from '../../apps/apps-types.d';
import { ClientDto } from '../account-types';
import { getFriendlyPermissionLevelName, hasCommandTierPermission } from './helpers';
import { CommentInput } from '../../commentinput/CommentInput';

export interface ConnectedAppPermissionProps {
  clientId: string;
  account: ClientDto;
}

export default function ConnectedAppPermissions(props: ConnectedAppPermissionProps) {
  const { clientId, account } = props;
  const [saving, setSaving] = useState(false);
  const [comments, setComments] = useState('');

  const url = window.location.pathname;
  const appId = url.substring(url.lastIndexOf('/') + 1);
  const [clientPermissions, setClientPermissions] = useState<ClientAppPermissions>();
  const [publishedAppsResult, publishedAppsLoading, publishedAppsLoaded] = useApim<PublishedAppsResponseBody>(
    `/publishedapps?onlyActive=true&appId=${appId}`,
    clientId,
    undefined,
    appId === '00000000-0000-0000-0000-000000000000'
  );
  const [clientAppPermissionsResult, clientAppPermissionsLoading, clientAppPermissionsLoaded] = useApim<ClientAppPermissions[]>(
    `/clientapppermissions?appId=${appId}`,
    clientId
  );

  useEffect(() => {
    if (!clientAppPermissionsLoading) {
      if (clientAppPermissionsResult?.length || 0 > 0) {
        setClientPermissions(clientAppPermissionsResult[0]);
      } else {
        setClientPermissions({
          id: null,
          appId: appId,
          clientId: clientId,
          permissions: [],
        });
      }
    }
  }, [clientAppPermissionsResult, clientAppPermissionsLoading]);

  if (publishedAppsLoading || clientAppPermissionsLoading) {
    return <Loader active />;
  }

  const getRow = (permissionLevel: AppItemPermissionLevel, displayName: string, permissionName: string, permissionType: PermissionType) => {
    let checkbox = null;
    if (hasCommandTierPermission(permissionLevel, account.subscriptionCode)) {
      checkbox = <Popup content="Account has access to this through its subscription level" trigger={<Checkbox checked disabled />} />;
    } else {
      const permissionIndex = clientPermissions.permissions.findIndex((x) => x.name === permissionName && x.type === permissionType) !== -1;
      checkbox = <Checkbox checked={permissionIndex} onChange={(e, d) => onPermissionChange(permissionName, permissionType, d.checked)} />;
    }
    return (
      <Table.Row key={permissionName}>
        <Table.Cell>{displayName || permissionName}</Table.Cell>
        <Table.Cell>{getFriendlyPermissionLevelName(permissionLevel)}</Table.Cell>
        <Table.Cell>{checkbox}</Table.Cell>
      </Table.Row>
    );
  };

  const onPermissionChange = (permissionName: string, permissionType: PermissionType, value: boolean) => {
    const newPermissions: ClientAppPermissions = deepClone(clientPermissions);
    if (value) {
      newPermissions.permissions.push({
        name: permissionName,
        type: permissionType,
      });
    } else {
      const index = newPermissions.permissions.findIndex((x) => x.name === permissionName && x.type === permissionType);
      newPermissions.permissions.splice(index, 1);
    }
    setClientPermissions(newPermissions);
  };

  const onSave = () => {
    setSaving(true);
    if (!clientPermissions.id) {
      apimCall('/clientapppermissions', 'POST', clientPermissions, clientId, null, comments).then((res) => {
        const newClientPermissions = clientPermissions;
        newClientPermissions.id = res.data;
        setClientPermissions(newClientPermissions);
        setComments('');
        setSaving(false);
      });
    } else {
      apimCall(`/clientapppermissions/${clientPermissions.id}`, 'PUT', clientPermissions, clientId, null, comments).then(() => {
        setComments('');
        setSaving(false);
      });
    }
  };

  const rows = [];
  if (clientAppPermissionsLoaded) {
    if (publishedAppsLoaded) {
      const publishedApp = publishedAppsResult.data.items[0];
      if (publishedApp?.package?.metadata) {
        const metadata = publishedApp.package.metadata;
        const appItemPermissions = publishedApp.permissions;
        metadata.commands?.forEach((command) => {
          const permissionLevel = appItemPermissions?.find((x) => x.name === command.name)?.level || AppItemPermissionLevel.Unlisted;
          if (permissionLevel !== 'deprecated') {
            rows.push(getRow(permissionLevel, command.displayName, command.name, PermissionType.Command));
          }
        });
        metadata.webhooks?.forEach((webhook) => {
          const permissionLevel = appItemPermissions?.find((x) => x.name === webhook.id)?.level || AppItemPermissionLevel.Unlisted;
          if (permissionLevel !== 'deprecated') {
            rows.push(getRow(permissionLevel, webhook.name, webhook.id, PermissionType.Webhook));
          }
        });
        metadata.events?.forEach((event) => {
          const permissionLevel = appItemPermissions?.find((x) => x.name === event.type)?.level || AppItemPermissionLevel.Unlisted;
          if (permissionLevel !== 'deprecated') {
            rows.push(getRow(permissionLevel, event.name, event.type, PermissionType.Event));
          }
        });
      }
    }
  }

  return (
    <div className="connected-app-permissions">
      <h3>{appId === '00000000-0000-0000-0000-000000000000' ? 'GoFormz Trigger' : publishedAppsResult.data.items[0].appName}</h3>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Command Name</Table.HeaderCell>
            <Table.HeaderCell>Permission Level</Table.HeaderCell>
            <Table.HeaderCell>Has Permission</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
      {account.deletedDate || !canIUse(AppFeatures.EditApps) ? null : (
        <div className="ui form">
          <br />
          <CommentInput placeholder="Comment." value={comments} onChange={setComments}/>
          <br />
          <Button primary onClick={onSave} loading={saving} disabled={saving || !comments}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
