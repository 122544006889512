import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Checkbox } from 'semantic-ui-react';
import { Parallel } from '../../utility/parallel';
import { UserDetails, UserSelect } from './UserSelect';
import { WorkflowRowItem, WorkflowStatus } from './WorkflowItem';
import './WorkflowCopy.scss';
import { apimCall, getApiErrorMessage } from '../../utility/api';
import axios, { AxiosResponse } from 'axios';

export function WorkflowCopy() {
  const [workflows, setWorkflows] = useState([]);
  const [destination, setDestination] = useState(null);
  const [source, setSource] = useState(null);
  const [nextRowKey, setNextRowKey] = useState(0);
  const [disableCopy, setDisableCopy] = useState(true);
  const [sameAccount, setSameAccount] = useState(true);
  const [status, setStatus] = useState('');
  const [pendingUsers, setPendingUsers] = useState({ fromUser: false, toUser: false });

  const fromClientInputRef = useRef<TypeMePlease>();
  const toClientInputRef = useRef<TypeMePlease>();
  useEffect(() => fromClientInputRef.current.focus(), []);
  useEffect(() => setCopyButtonState(), [workflows]);
  useEffect(() => {
    setCopyButtonState();
    if (sameAccount) {
      fromClientInputRef.current.focus();
    } else {
      toClientInputRef.current.focus();
    }
  }, [sameAccount, destination]);

  const isCopying = () => status === WorkflowStatus.copying;

  const onChangeFromUser = (userMapping) => {
    setSource(userMapping ? { userId: userMapping.userId, clientId: userMapping.clientId } : null);
    setWorkflows([]);
  };

  const onChangeToUser = (userMapping) => {
    setDestination(userMapping ? { userId: userMapping.userId, clientId: userMapping.clientId } : null);
  };

  const updatePendingUser = (userType: string, isPending: boolean) => {
    setPendingUsers((prevState) => ({ ...prevState, [userType]: isPending }));
  };

  const onChangeSameAccount = (checked) => {
    setSameAccount(checked);
    if (!checked) {
      setDestination('');
    }
  };

  const isPendingUser = async (userId, clientId) => {
    const userDetails = await fetchUserDetails(clientId, userId);
    return userDetails && userDetails.user.pending;
  };

  const fetchUserDetails = async (clientId: string, userId: string) => {
    try {
      const userDetails: AxiosResponse<UserDetails> = await axios.get(`api/account/${clientId}/users/${userId}`);
      return userDetails.data;
    } catch (error) {
      console.log(error);
    }
  };

  const setCopyButtonState = (workflowList?: TypeMePlease[]) => {
    const list = workflowList || workflows;
    const disableButton = list.length === 0 || list.findIndex((x) => !x.id || !x.name) >= 0 || (!sameAccount && !destination);
    setDisableCopy(disableButton);
  };

  const addRow = () => {
    const newList = [...workflows, { rowKey: nextRowKey, id: '', name: '', status: '', copyErrorMessage: '' }];
    setWorkflows(newList);
    setNextRowKey(nextRowKey + 1);
  };

  const removeRow = (rowKey) => {
    const newList = workflows.filter((x) => x.rowKey !== rowKey);
    setWorkflows(newList);
  };

  const setWorkflow = (rowKey, id, name, status?, copyErrorMessage?: string) => {
    const newList = workflows.map((item) => {
      return item.rowKey !== rowKey ? item : { ...item, id: id, name: name, status: id ? status : WorkflowStatus.ready, copyErrorMessage: copyErrorMessage };
    });
    setWorkflows(newList);
  };

  const clearWorkflows = () => {
    setWorkflows([]);
    setStatus(WorkflowStatus.ready);
  };

  const copyWorkflows = () => {
    setStatus(WorkflowStatus.copying);
    Parallel(workflows.map((workflow) => copyWorkflow(workflow))).then(() => {
      setStatus(WorkflowStatus.done);
    });
  };

  const copyWorkflow = async (workflow) => {
    setWorkflow(workflow.rowKey, workflow.id, workflow.name, WorkflowStatus.copying);

    try {
      const body = { workflowId: workflow.id, name: workflow.name, destination: destination };

      await apimCall('/workflows/copy', 'POST', body, source.clientId, source.userId);

      setWorkflow(workflow.rowKey, workflow.id, workflow.name, WorkflowStatus.done);
    } catch (error) {
      setWorkflow(workflow.rowKey, workflow.id, workflow.name, WorkflowStatus.error, getApiErrorMessage(error));
    }
  };

  return (
    <div className="workflow-copy">
      <Helmet>
        <title>GoFormz Tools</title>
      </Helmet>
      <h3>Workflow Copy</h3>
      <div className="clients-row">
        <div className="from-client">
          <br />
          <UserSelect
            label="From User"
            onChange={onChangeFromUser}
            disabled={isCopying()}
            inputRef={fromClientInputRef}
            isPendingUser={isPendingUser}
            updatePendingUser={(isPending) => updatePendingUser('fromUser', isPending)}
          />
        </div>
        <div>
          <Checkbox
            className="same-account"
            label="Same Account"
            checked={sameAccount}
            onChange={(e, data) => onChangeSameAccount(data.checked)}
            disabled={isCopying()}
          />
          <UserSelect
            label="To User"
            disabled={sameAccount || isCopying()}
            onChange={onChangeToUser}
            empty={sameAccount}
            inputRef={toClientInputRef}
            isPendingUser={isPendingUser}
            updatePendingUser={(isPending) => updatePendingUser('toUser', isPending)}
          />
        </div>
      </div>
      <div className="button-row">
        <Button onClick={() => addRow()} disabled={!source?.clientId || isCopying() || Object.values(pendingUsers).includes(true)}>
          Add Workflow
        </Button>
        <Button onClick={() => clearWorkflows()} disabled={workflows.length < 1 || isCopying()}>
          Clear
        </Button>
        <Button onClick={() => copyWorkflows()} disabled={disableCopy || isCopying()}>
          Copy
        </Button>
      </div>
      <div className="workflow-list">
        {workflows.map((workflow) => (
          <WorkflowRowItem
            key={workflow.rowKey}
            rowKey={workflow.rowKey}
            clientId={source?.clientId}
            onChange={(rowKey, id, name) => setWorkflow(rowKey, id, name)}
            onRemove={() => removeRow(workflow.rowKey)}
            status={workflow.status}
            errorMessage={workflow.copyErrorMessage}
          />
        ))}
      </div>
    </div>
  );
}
