import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Module } from '../Module';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';
import { apiCall } from '../../utility/api';

export function IndependentUserMerge() {
  const [username, setUsername] = useState('');
  const [mergeUsers, setMergeUsers] = useState('');
  const [merging, setMerging] = useState(false);
  const [mergeResult, setMergeResult] = useState('');

  const merge = async () => {
    setMerging(true);
    setMergeResult('');
    const request = { username: username, mergeUsers: mergeUsers.split(',') };
    try {
      const result = await apiCall(`/api/independentuser/merge`, 'POST', request);
      setMergeResult(result.data.message);
      setMerging(false);
    } catch {
      setMergeResult('Error merging users');
      setMerging(false);
    }
  };

  return (
    <div className="account">
      <Helmet>
        <title>Independent User Merge Page</title>
      </Helmet>
      <div>
        <div className="modules">
          <Module title="Independent User To Keep" className="details-module">
            <table className="ui very basic collapsing celled table">
              <tbody>
                <tr>
                  <td>Email Address</td>
                  <td>
                    <Input value={username} onChange={(e) => setUsername(e.target.value)} style={{ minWidth: 400 }} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Module>
          <Module title="Users to Merge" className="details-module">
            <table className="ui very basic collapsing celled table">
              <tbody>
                <tr>
                  <td>Email Addresses (comma separated list, no spaces)</td>
                  <td>
                    <Form>
                      <TextArea value={mergeUsers} onChange={(e) => setMergeUsers(e.target.value)} style={{ minHeight: 100, minWidth: 400 }} />
                    </Form>
                  </td>
                </tr>
              </tbody>
            </table>
          </Module>
        </div>
        <br />
        <Button className="primary" onClick={merge} loading={merging}>
          Merge
        </Button>
        <div>
          <span>Status:</span>
          <Form>
            <TextArea value={mergeResult.replace('|', '\n')} style={{ minHeight: 100, width: 600 }} />
          </Form>
        </div>
      </div>
    </div>
  );
}
