import React from 'react';
import moment from 'moment';
import { SearchResults } from './Search';

type ClientsResultsProps = {
  results: SearchResults[];
};

export function ClientsResults(props: ClientsResultsProps) {
  return (
    <table className="ui table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Company</th>
          <th>Company Size</th>
          <th>ACV</th>
          <th>Promo Code</th>
          <th>Subscription</th>
          <th>Signup Date</th>
          <th>Deleted Date</th>
          <th>Trial End</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.results.map((row) => {
          return (
            <tr key={row.id}>
              <td>
                <a href={`account/${row.id}`}>{row.emailAddress}</a>
              </td>
              <td>
                <a href={`account/${row.id}`}>{row.name}</a>
              </td>
              <td>{row.companySize}</td>
              <td>{row.acv}</td>
              <td>{row.promoCode}</td>
              <td>{row.subscription}</td>
              <td>{moment.utc(row.createdDate).local().format('MM/DD/YYYY h:mm:ss a')}</td>
              <td>{row.deletedDate ? moment.utc(row.deletedDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
              <td>{row.trialEndDate ? moment.utc(row.trialEndDate).local().format('MM/DD/YYYY h:mm:ss a') : null}</td>
              <td>
                <a href={`account/${row.id}`}>Details</a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
