import { FeatureFlag, FeatureFlagDataType, IFeatureFlagValue } from "./types";


export const getDefaultValueFromFeatureObject = (obj: IFeatureFlagValue, dataType: FeatureFlagDataType) => { 
  return dataType === FeatureFlagDataType.BoolValue ? obj.boolValue
  : dataType === FeatureFlagDataType.NumberValue ? obj.numberValue
  : dataType === FeatureFlagDataType.DecimalValue ? obj.decimalValue
  : dataType === FeatureFlagDataType.JsonValue ? obj.jsonValue 
  : dataType === FeatureFlagDataType.StringValue ? obj.stringValue
  : null;
}


export const setFeatureFlagValueByType = (featureFlag: FeatureFlag, value, handler) => {
  featureFlag.dataType === FeatureFlagDataType.BoolValue && handler('boolValue', value);
  featureFlag.dataType === FeatureFlagDataType.NumberValue && handler('numberValue', value);
  featureFlag.dataType === FeatureFlagDataType.DecimalValue && handler('decimalValue', value);
  featureFlag.dataType === FeatureFlagDataType.JsonValue && handler('jsonValue', value);
  featureFlag.dataType === FeatureFlagDataType.StringValue && handler('stringValue', value);
}