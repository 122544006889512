import React from 'react';
import './AddOnsConfigurationTableHeader.scss';
import { TableHeader, TableRow, TableHeaderCell } from 'semantic-ui-react';

export function AddOnsConfigurationTableHeader() {
  return (
    <TableHeader className='add-ons-configuration-table-header'>
      <TableRow className="add-ons-configuration-table-header-row">
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-recurly-code'>Recurly Code</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-title'>Title</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-description'>Description</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-feature-flag'>Feature Flag</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-price'>Pricing Type</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-price'>Monthly Price</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-price'>Annual Price</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-user-limit'>User Limit</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-usubscribe'>Allow Unsubscribe</TableHeaderCell>
        <TableHeaderCell className='add-ons-configuration-table-header-value add-on-metadata'></TableHeaderCell>
      </TableRow>
    </TableHeader>
  );
}