import React, { createContext, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export type MessagesContextValue = {
  message?: string;
  showMessage?: (message: string) => void;
};

const MessagesContext = createContext<MessagesContextValue>({});

export function MessagesProvider(props) {
  const [message, showMessage] = useState<string>();

  return (
    <MessagesContext.Provider value={{ showMessage }}>
      {message ? (
        <Modal size="tiny" open dimmer="inverted" closeOnDimmerClick={false}>
          <Modal.Content>{message}</Modal.Content>
          <Modal.Actions>
            <Button
              content="Ok"
              className="primary"
              onClick={() => {
                showMessage(null);
              }}
            />
          </Modal.Actions>
        </Modal>
      ) : null}
      {props.children}
    </MessagesContext.Provider>
  );
}

export function useMessages() {
  const context = React.useContext(MessagesContext);
  if (context === undefined) {
    throw new Error(`useMessages must be used within a MessagesProvider`);
  }
  return context;
}
