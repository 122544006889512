import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './Details.scss';
import { Timeline } from '../timeline/Timeline';
import { Module } from '../../Module';
import { Button, Divider } from 'semantic-ui-react';
import { UpdateTemplateDateFormatsButton } from './UpdateTemplateDateFormatsButton';
import { ClientDto } from '../account-types';

export type DetailsProps = {
  id: string;
  account: ClientDto;
};

export function Details(props: DetailsProps) {
  const { id, account } = props;
  const [clearingCache, setClearingCache] = useState(false);

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  const clearCache = async () => {
    setClearingCache(true);
    await axios({
      method: 'put',
      url: `/Account/ClearClientCache?id=${id}`,
    });
    setClearingCache(false);
  };

  return (
    <div className="account-details">
      <Helmet>
        <title>{account.name} - Details</title>
      </Helmet>
      <div className="modules">
        <Module title="Details" className="details-module">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>Signup Date</td>
                <td>{moment.utc(account.createdDate).local().calendar(null, calendarOptions)}</td>
              </tr>
              <tr>
                <td>Trial End Date</td>
                <td>{moment.utc(account.trialEndDate).local().calendar(null, calendarOptions)}</td>
              </tr>
              <tr>
                <td>Subscription Start Date</td>
                <td>{account.subscriptionStartDate ? moment.utc(account.subscriptionStartDate).local().calendar(null, calendarOptions) : ''}</td>
              </tr>
              <tr>
                <td>Subscription End Date</td>
                <td>{account.subscriptionEndDate ? moment.utc(account.subscriptionEndDate).local().calendar(null, calendarOptions) : ''}</td>
              </tr>
              <tr>
                <td>Current Term Started At</td>
                <td>
                  {account.settings.currentTermStartedAt ? moment.utc(account.settings.currentTermStartedAt).local().calendar(null, calendarOptions) : ''}
                </td>
              </tr>
              <tr>
                <td>Current Term Ends At</td>
                <td>{account.settings.currentTermEndsAt ? moment.utc(account.settings.currentTermEndsAt).local().calendar(null, calendarOptions) : ''}</td>
              </tr>
              <tr>
                <td>Signup Source</td>
                <td>{account.signupEnvironment}</td>
              </tr>
              <tr>
                <td>Promo Code</td>
                <td>{account.promoCode}</td>
              </tr>
              <tr>
                <td>Terms of Service</td>
                <td>{account.termsServiceAcceptedDate}</td>
              </tr>
              <tr>
                <td>Company Size</td>
                <td>{account.companySize}</td>
              </tr>
              <tr>
                <td>Company Name</td>
                <td>{account.name}</td>
              </tr>
              <tr>
                <td>Account Owner</td>
                <td>{account?.ownerName}</td>
              </tr>
              <tr>
                <td>Install Base Account Type</td>
                <td>{account?.installBaseAccountType}</td>
              </tr>
              <tr>
                <td>Actions</td>
                <td>
                  <Button onClick={clearCache} loading={clearingCache}>
                    Clear Cache
                  </Button>
                  <Divider horizontal></Divider>
                  <UpdateTemplateDateFormatsButton clientId={id} />
                </td>
              </tr>
            </tbody>
          </table>
        </Module>
        <Module title="Timeline" className="timeline-module">
          <Timeline id={id} numberOfItemsToShow={300} createdDate={account.createdDate} deletedDate={account.deletedDate} trialEndDate={account.trialEndDate} />
        </Module>
      </div>
    </div>
  );
}
