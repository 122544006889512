import React, {useState} from 'react';
import {Button, Checkbox, Input, Label} from 'semantic-ui-react';
import {apimCall} from '../../../utility/api';
import {useForm} from '../../../hooks/useForm';
import {Module} from '../../Module';
import './UpdateSubscription.scss';
import {AppFeatures, canIUse} from "../../../utility/SecurityHelper";
import {formatDateToUtc, formatDateToLocal} from "../../../utility/formatting";
import { CommentInput } from '../../commentinput/CommentInput';

export type UpdateSubscriptionProps = {
  id: string;
  account: TypeMePlease;
  refreshAccount: () => void;
};

export function UpdateSubscription(props: UpdateSubscriptionProps) {
  const { account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [updateComments, setUpdateComments] = useState('');
  const initialState = {
    licensedUserCount: account.nonUserPricing ? null : account.licensedUserCount,
    price: account.nonUserPricing ? null : account.price,
    billingPeriod: account.billingPeriod,
    editionCode: account.subscriptionCode,
    acv: account.acv,
    isManualInvoice: account.isManualInvoice,
    nonUserPricing: account.nonUserPricing,
    trialEndDate: formatDateToLocal(account.trialEndDate), 
  }
  const [settings, setAccountSettings] = useForm(initialState);
  const currentSubscriptionCode = account.subscriptionCode;

  const save = async () => {
    setSaving(true);
    settings.trialEndDate = formatDateToUtc(settings.trialEndDate, account.trialEndDate);
    apimCall(`/subscriptions`, 'PUT', settings, account.clientId, null, updateComments)
      .then(() => {
        setSaving(false);
        setError('');
        setUpdateComments('');
        refreshAccount();
      })
      .catch((err) => {
        setSaving(false);
        if (err && err.response && err.response.data) {
          setError(err.response.data.message || err.response.data.error);
        } else {
          setError(`Unknown error: ${err}`)
        }
      });
  };

  return (
    <div className="update-subscription">
      <Module title="Update Subscription" className="fullwidth-module">
        <table className="ui very basic collapsing celled table">
          <tbody>
          <tr>
              <td>Manually Invoiced</td>
              <td>
                <Checkbox
                  checked={settings.isManualInvoice}
                  toggle
                  onChange={(e, data) => {
                    setAccountSettings('isManualInvoice', data.checked);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Non-User Pricing</td>
              <td>
                <Checkbox
                  checked={settings.nonUserPricing}
                  toggle
                  onChange={(e, data) => {
                    setAccountSettings('nonUserPricing', data.checked);
                    if (data.checked) {
                      setAccountSettings('licensedUserCount', null);
                      setAccountSettings('price', null);
                    }
                  }}
                />
              </td>
            </tr>
            {settings.nonUserPricing ? (
              <tr>
                <td>ACV</td>
                <td>
                  <Input type="number" value={settings.acv} onChange={(e) => setAccountSettings('acv', e.target.value)} error={settings.acv === ''} />
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Licensed Users</td>
                  <td>
                    <Input
                      type="number"
                      value={settings.licensedUserCount}
                      id="UpgradeLicenses"
                      onChange={(e) => {
                        setAccountSettings('licensedUserCount', e.target.value);
                        setAccountSettings('acv', +e.target.value * settings.price * 12);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Price Per User</td>
                  <td>
                    <Input
                      type="number"
                      value={settings.price}
                      id="UpgradePrice"
                      onChange={(e) => {
                        setAccountSettings('price', e.target.value);
                        setAccountSettings('acv', +e.target.value * settings.licensedUserCount * 12);
                      }}
                    />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td data-tooltip="1 for monthly, 12 for annual" data-position="top left">
                <i className="info blue circle icon"></i>Billing Period
              </td>
              <td>
                <Input
                  type="number"
                  value={settings.billingPeriod}
                  id="UpgradeBillingPeriod"
                  onChange={(e) => setAccountSettings('billingPeriod', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>
                <select
                  className="ui dropdown"
                  id="subscriptionId"
                  onChange={(e) => {
                    setAccountSettings('editionCode', e.target.value);
                    if (!settings.nonUserPricing && e.target.value === 'gf-basic') {
                      setAccountSettings('licensedUserCount', 1);
                      setAccountSettings('price', 0);
                    }
                  }}
                >
                  <option value={currentSubscriptionCode}>{currentSubscriptionCode}</option>
                  <option value="gf-trial">gf-trial</option>
                  <option value="gf-basic">gf-basic</option>
                  <option value="gf-teacher">gf-teacher</option>
                  <option value="gf-team">gf-team</option>
                  <option value="gf-advanced">gf-advanced</option>
                  <option value="gf-enterprise">gf-enterprise</option>
                </select>
              </td>
            </tr>
            {
              settings.editionCode === 'gf-trial' && (
                    <tr>
                      <td>Trial End Date</td>
                      <td>
                        <Input type="text"
                               style={{ width: '194px'}}
                               value={settings.trialEndDate || ''}
                               onChange={(e) => setAccountSettings('trialEndDate', e.target.value)}>
                        </Input>
                      </td>
                    </tr> 
                )
            }
        
          </tbody>
        </table>
        {error ? (
          <div className="error-message">
            <Label basic color="red" id="resultMsg">
              {error}
            </Label>
          </div>
        ) : null}
        {account.deletedDate ? null : (
            <div className="ui form">
              <CommentInput placeholder="Comment."
                  value={updateComments} onChange={setUpdateComments}/>
              <Button id="changeSubscription" primary onClick={save} loading={saving}
                      disabled={settings.nonUserPricing && settings.acv === '' || !canIUse(AppFeatures.EditFinancialAccountSettings) || !updateComments}>
                Update Subscription
              </Button>
            </div>

        )}
      </Module>
    </div>
  );
}
