import './AccountLimitConfigs.scss';
import React, { useState, useEffect } from 'react';
import { useApi, useApim } from '../../../hooks/useApi';
import { apiCall } from '../../../utility/api';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';
import { Button } from 'semantic-ui-react';
import { ApiConfigsDto, ApiThrottlingScope, ApiThrottlingLimits, ApiThrottlingDisplay } from '../../apiConfigs/apiConfigs-types';
import { CommentInput } from '../../commentinput/CommentInput';
import { AppFeatures, canIUse } from '../../../utility/SecurityHelper';
import { ClientDto } from '../account-types';
import { AccountFeature, AccountFeatureUpdate } from '../features/AccountFeatures';
import { getDisplayApiThrottlingLimits, getFeatureByFlagCodeName, GetFormCreateLimit, setAccountFeatureValue } from '../helpers';
import FormCreatedLimits from './FormCreatedLimits';
import ApiThrottling from './ApiThrottlingLimits';

export type AccountLimitConfigsProps = {
  id: string;
  account: ClientDto;
  refreshAccount: () => void;
};

type Edition = {
  code: string;
  apiConfigs: ApiConfigsDto;
};

export default function AccountLimitConfigs(props: AccountLimitConfigsProps) {
  const { id, account, refreshAccount } = props;
  const [selectedThrottlingLimits, setSelectedThrottlingLimits] = useState<ApiThrottlingLimits>();
  const [comments, setComments] = useState<string>();
  const [showConfigureFormCreatedLimits, setShowConfigureFormCreatedLimits] = useState<boolean>(false);
  const [saving, setSaving] = useState(null);
  const [supportedLimits, supportedLimitsLoading] = useApi<TypeMePlease>('api/account/apiconfigs/limits');
  const [editions, editionsLoading, editionsLoaded] = useApim<Edition[]>('/editions');
  const [clientEdition, setClientEdition] = useState<Edition>();
  const [editionApiThrottlingDefaults, setEditionApiThrottlingDefaults] = useState<ApiThrottlingDisplay>();
  const [effectiveThrottlingLimits, setEffectiveThrottlingLimits] = useState<ApiThrottlingDisplay>();
  const isReadOnly = !canIUse(AppFeatures.EditAccountControls);
  const [apiLimitsChanged, setApiLimitsChanged] = useState(false);
  const [formLimitsCleared, setFormLimitsCleared] = useState(false);
  const [formLimitsChanged, setFormLimitsChanged] = useState(false);

  const [accountFeatures, , accountFeaturesLoaded] = useApi<(AccountFeature & { hasUpdate: boolean })[]>(`/api/account/${id}/features`);
  const [updates, setUpdates] = useState<AccountFeatureUpdate>({ features: {} });

  const billingPeriod = account.billingPeriod;

  const formCreateLimitFeatureFlag = accountFeaturesLoaded && getFeatureByFlagCodeName(accountFeatures, 'FormCreateLimit');
  const [formCreateLimit, setFormCreateLimit] = useState<number>(null);

  useEffect(() => {
    if (!editionsLoaded) {
      return;
    }

    const edition = editions.find((x) => x.code == account.subscriptionCode);
    if (!edition || !edition.apiConfigs) {
      return;
    }

    setClientEdition(edition);
    setEditionApiThrottlingDefaults(getDisplayApiThrottlingLimits(edition.apiConfigs));
    setSelectedThrottlingLimits({
      day: props.account.apiLimitDay,
      hour: props.account.apiLimitHour,
      minute: props.account.apiLimitMinute,
    });
  }, [editions, editionsLoaded]);

  useEffect(() => {
    if (!props.account || !editionsLoaded || !clientEdition) {
      return;
    }

    setEffectiveThrottlingLimits(
      getDisplayApiThrottlingLimits({
        apiLimitDay:
          selectedThrottlingLimits.day > -1 &&
          clientEdition.apiConfigs?.apiLimitDay > 0 &&
          (selectedThrottlingLimits.day === 0 || selectedThrottlingLimits.day > clientEdition.apiConfigs?.apiLimitDay)
            ? selectedThrottlingLimits.day
            : clientEdition.apiConfigs.apiLimitDay,
        apiLimitHour:
          selectedThrottlingLimits.hour > -1 &&
          clientEdition.apiConfigs?.apiLimitHour > 0 &&
          (selectedThrottlingLimits.hour === 0 || selectedThrottlingLimits.hour > clientEdition.apiConfigs?.apiLimitHour)
            ? selectedThrottlingLimits.hour
            : clientEdition.apiConfigs.apiLimitHour,
        apiLimitMinute:
          selectedThrottlingLimits.minute > -1 &&
          clientEdition.apiConfigs?.apiLimitMinute > 0 &&
          (selectedThrottlingLimits.minute === 0 || selectedThrottlingLimits.minute > clientEdition.apiConfigs?.apiLimitMinute)
            ? selectedThrottlingLimits.minute
            : clientEdition.apiConfigs.apiLimitMinute,
      })
    );
  }, [selectedThrottlingLimits, props.account, editionsLoaded, clientEdition]);

  const updateThrottlingLimits = (scope: ApiThrottlingScope, value: number) => {
    const limits: ApiThrottlingLimits = {
      day: scope === ApiThrottlingScope.day ? value : selectedThrottlingLimits.day,
      hour: scope === ApiThrottlingScope.hour ? value : selectedThrottlingLimits.hour,
      minute: scope === ApiThrottlingScope.minute ? value : selectedThrottlingLimits.minute,
    };
    setSelectedThrottlingLimits(limits);
    setApiLimitsChanged(true);
  };

  const save = async () => {
    setSaving(true);

    if (apiLimitsChanged) {
      await apiCall(`/api/account/${id}/apiconfigs`, 'PUT', {
        apiLimitDay: selectedThrottlingLimits.day,
        apiLimitHour: selectedThrottlingLimits.hour,
        apiLimitMinute: selectedThrottlingLimits.minute,
        comments,
      });

      props.account.apiLimitDay = selectedThrottlingLimits.day;
      props.account.apiLimitHour = selectedThrottlingLimits.hour;
      props.account.apiLimitMinute = selectedThrottlingLimits.minute;
    }

    if (formLimitsChanged) {
      await apiCall(`/api/account/${id}/features`, 'PUT', updates);
    }

    if (formLimitsCleared) {
      await apiCall(`/api/account/${id}/features/${formCreateLimitFeatureFlag.featureFlagId}`, 'DELETE', { comments: comments });
    }

    setComments('');
    refreshAccount();
    setSaving(false);
  };

  useEffect(() => {
    if (!accountFeaturesLoaded) {
      return;
    }

    setFormCreateLimit(GetFormCreateLimit(formCreateLimitFeatureFlag));
  }, [formCreateLimitFeatureFlag]);

  const toggleFormLimits = () => {
    if (!!formCreateLimit) {
      setFormLimitsCleared(true);
      setFormLimitsChanged(false);
      setFormCreateLimit(null);
      setAccountFeatureValue(updates, setUpdates, formCreateLimitFeatureFlag, null);
    }
    if (!formCreateLimit) {
      setFormLimitsCleared(false);
      setFormLimitsChanged(true);
      setFormCreateLimit(500);
      setAccountFeatureValue(updates, setUpdates, formCreateLimitFeatureFlag, 500);
    }
  };

  return (
    <div className="api-configs">
      <Loader isLoading={supportedLimitsLoading || editionsLoading} />
      <Helmet>
        <title>{account.name} - Account Limit Configs</title>
      </Helmet>
      <h3>Account Limit Configs</h3>
      <FormCreatedLimits
        account={account}
        clientId={id}
        formCreateLimit={formCreateLimit}
        toggleFormLimits={toggleFormLimits}
        setShowConfigureFormCreatedLimits={setShowConfigureFormCreatedLimits}
        showConfigureFormCreatedLimits={showConfigureFormCreatedLimits}
        billingPeriod={billingPeriod}
        setFormCreateLimit={setFormCreateLimit}
        setAccountFeatureValue={setAccountFeatureValue}
        updates={updates}
        setUpdates={setUpdates}
        formCreateLimitFeatureFlag={formCreateLimitFeatureFlag}
        setFormLimitsChanged={setFormLimitsChanged}
        setFormLimitsCleared={setFormLimitsCleared}
      />
      <br />
      <br />
      <ApiThrottling
        supportedLimits={supportedLimits}
        selectedThrottlingLimits={selectedThrottlingLimits}
        updateThrottlingLimits={updateThrottlingLimits}
        isReadOnly={isReadOnly}
        editionApiThrottlingDefaults={editionApiThrottlingDefaults}
        effectiveThrottlingLimits={effectiveThrottlingLimits}
      />
      {account.deletedDate ? null : (
        <div className="ui form form-width">
          <br />
          <CommentInput placeholder="Comment." value={comments} onChange={setComments} />
          <Button className="primary" onClick={save} loading={saving} disabled={!comments}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
