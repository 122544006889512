import React, { useState } from 'react';
import moment from 'moment';
import { Checkbox, Input, Button } from 'semantic-ui-react';
import { apiCall } from '../../utility/api';
import { useForm } from '../../hooks/useForm';
import TimezoneDropdown from '../dropdowns/TimezoneDropdown';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { Module } from '../Module';
import { AccountDetail } from './account-types';
import { CommentInput } from '../commentinput/CommentInput';

export type AccountDetailsProps = {
  id: string;
  account: AccountDetail;
  refreshAccount: () => void;
};

export function AccountDetails(props: AccountDetailsProps) {
  const { id, account, refreshAccount } = props;
  const [saving, setSaving] = useState(false);
  const [comments, setComments] = useState('');

  const [accountDetails, setAccountDetails] = useForm<AccountDetail>({
    ...account,
    effectiveLicensedUserCount: account.nonUserPricing ? null : account.effectiveLicensedUserCount,
    licensedUserCount: account.nonUserPricing ? null : account.licensedUserCount,
    price: account.nonUserPricing ? null : account.price,
    subscriptionEndDate: account.subscriptionEndDate ? moment.utc(account.subscriptionEndDate).local().format('MM/DD/YYYY h:mm:ss a') : '',
    subscriptionStartDate: account.subscriptionStartDate ? moment.utc(account.subscriptionStartDate).local().format('MM/DD/YYYY h:mm:ss a') : '',
    trialEndDate: moment.utc(account.trialEndDate).local().format('MM/DD/YYYY h:mm:ss a'),
  });

  const save = async () => {
    setSaving(true);
    const request = { details: accountDetails, comments };
    await apiCall(`/api/account/${id}/details`, 'PUT', request);

    setComments('');
    refreshAccount();
    setSaving(false);
  };

  return (
    <div className="settings">
      <div className="modules flex-row">
        <Module title="Update Details">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>HIPAA Customer</td>
                <td>
                  <Checkbox
                    checked={accountDetails.isHIPAA}
                    toggle
                    onChange={(e, data) => setAccountDetails('isHIPAA', data.checked)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Legacy Pricing</td>
                <td>
                  <Checkbox
                    checked={accountDetails.legacyPricing}
                    toggle
                    onChange={(e, data) => setAccountDetails('legacyPricing', data.checked)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Elastic</td>
                <td>
                  <Checkbox
                    checked={accountDetails.elastic}
                    toggle
                    onChange={(e, data) => setAccountDetails('elastic', data.checked)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Manually Invoiced</td>
                <td>
                  <Checkbox
                    checked={accountDetails.isManualInvoice}
                    toggle
                    onChange={(e, data) => setAccountDetails('isManualInvoice', data.checked)}
                    disabled={!canIUse(AppFeatures.EditFinancialAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Maintenance Mode</td>
                <td>
                  <Checkbox
                    checked={accountDetails.maintenanceMode}
                    toggle
                    onChange={(e, data) => setAccountDetails('maintenanceMode', data.checked)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Promo Code</td>
                <td>
                  <Input type="text" value={accountDetails.promoCode} onChange={(e) => setAccountDetails('promoCode', e.target.value)}></Input>
                </td>
              </tr>
              <tr>
                <td>Company Name</td>
                <td>
                  <Input type="text" value={accountDetails.name} onChange={(e) => setAccountDetails('name', e.target.value)}></Input>
                </td>
              </tr>
              <tr>
                <td data-tooltip="This appears as a splash screen in the mobile app" data-position="top left">
                  <i className="info blue circle icon"></i>Co-Branding Image
                </td>
                <td>
                  <Input
                    type="text"
                    className="longLength"
                    placeholder="Enter image url"
                    value={accountDetails.brandingImageUrl}
                    onChange={(e) => setAccountDetails('brandingImageUrl', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                  {accountDetails.brandingImageUrl ? (
                    <div style={{ marginTop: '8px' }}>
                      <img src={accountDetails.brandingImageUrl} />
                    </div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td data-tooltip="Max number of registered devices, before the user will be asked to disconnect a device." data-position="top left">
                  <i className="info blue circle icon"></i>Max Registered Devices
                </td>
                <td>
                  <Input
                    type="number"
                    placeholder="Default is 2 devices"
                    value={accountDetails.maxRegisteredDevices}
                    onChange={(e) => setAccountDetails('maxRegisteredDevices', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td data-tooltip="How often the mobile devices sync (in seconds)" data-position="top left">
                  <i className="info blue circle icon"></i>Sync Timer
                </td>
                <td>
                  <Input
                    type="number"
                    placeholder="Default is 90 seconds"
                    min={60}
                    value={accountDetails.syncTimer}
                    onChange={(e) => setAccountDetails('syncTimer', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Locale</td>
                <td>
                  <Input
                    type="text"
                    value={accountDetails.locale}
                    onChange={(e) => setAccountDetails('locale', e.target.value)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td>Time Zone</td>
                <td>
                  <TimezoneDropdown
                    value={accountDetails.timezone}
                    onChange={(v) => setAccountDetails('timezone', v)}
                    disabled={!canIUse(AppFeatures.EditAllAccountSettings)}
                  />
                </td>
              </tr>
              <tr>
                <td>Parent Account Id</td>
                <td>
                  <Input type="text" value={accountDetails.parentAccountId} onChange={(e) => setAccountDetails('parentAccountId', e.target.value)}></Input>
                </td>
              </tr>
            </tbody>
          </table>
          {account.deletedDate ? null : (
            <div className="ui form">
              <br />
              <CommentInput placeholder="Comment."
                  value={comments} onChange={setComments}/>
              <br />
              <Button className="primary" onClick={save} loading={saving} disabled={!comments || (accountDetails.nonUserPricing && accountDetails.acv === '')}>
                Save
              </Button>
            </div>
          )}
        </Module>
        <Module title="Read Only Details">
          <table className="ui very basic collapsing celled table">
            <tbody>
              <tr>
                <td>Non-User Pricing</td>
                <td>
                  <Checkbox checked={accountDetails.nonUserPricing} toggle disabled />
                </td>
              </tr>
              <tr>
                <td>Trial End Date</td>
                <td>{accountDetails.trialEndDate || ''}</td>
              </tr>
              {accountDetails.nonUserPricing ? (
                <tr>
                  <td>ACV</td>
                  <td>{accountDetails.acv}</td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td
                      data-tooltip="This is how many licenses they are paying for during this billing cycle. This will update to be the value of Effective Licensed Users at the start of the next billing cycle."
                      data-position="top left"
                    >
                      <i className="info blue circle icon"></i>Licensed Users
                    </td>
                    <td>{accountDetails.licensedUserCount}</td>
                  </tr>
                  <tr>
                    <td
                      data-tooltip="This represents what the user count will be at the start of the next billing cycle. This is the number that enforces how many users are able to be created."
                      data-position="top left"
                    >
                      <i className="info blue circle icon"></i>Effective Licensed Users
                    </td>
                    <td>{accountDetails.effectiveLicensedUserCount}</td>
                  </tr>
                  <tr>
                    <td>Price Per User</td>
                    <td>{accountDetails.price}</td>
                  </tr>
                </>
              )}
              <tr>
                <td data-tooltip="1 for monthly, 12 for annual" data-position="top left">
                  <i className="info blue circle icon"></i>Billing Period
                </td>
                <td>{accountDetails.billingPeriod}</td>
              </tr>
              <tr>
                <td>Subscription</td>
                <td>{accountDetails.subscriptionCode || ''}</td>
              </tr>
              <tr>
                <td data-tooltip="This is used if there is a pending edition change" data-position="top left">
                  <i className="info blue circle icon"></i>Pending Subscription
                </td>
                <td>{accountDetails.pendingSubscriptionCode}</td>
              </tr>
              <tr>
                <td>Subscription Start</td>
                <td>{accountDetails.subscriptionStartDate || ''}</td>
              </tr>
              <tr>
                <td>Subscription End</td>
                <td>{accountDetails.subscriptionEndDate || ''}</td>
              </tr>
            </tbody>
          </table>
        </Module>
      </div>
    </div>
  );
}
