import './OnboardingStatus.scss';

import React from 'react';
import { useOnboardingStatus } from './useOnboardingStatus';
import { StatusNode } from './StatusNode';

export type OnboardingStatusProps = {
  clientId: string;
  userId: string;
};

export default function OnboardingStatus({ clientId, userId }: OnboardingStatusProps) {
  const { loading, state, steps } = useOnboardingStatus(clientId, userId);
  const completedItems = state?.completionState?.completedItems ?? {};

  if (loading) {
    return <div className="onboarding-status">Loading...</div>;
  }

  return (
    <div className="onboarding-status">
      {Object.entries(steps).map(([stepNo, step]) => (
        <StatusNode key={stepNo} step={step} completedItems={completedItems[stepNo]} />
      ))}
    </div>
  );
}
