export type App = {
  id: string;
  appName: string;
  description: string;
  author: string;
  icon: string;
  latestPackage: AppPackage;
  authInfo: AuthInfo;
  authenticationOptions: AuthenticationOption[];
  configs: AppConfig[];
  lastUpdatedDate: string;
  createdDate: string;
  deletedDate?: string;
  restrictedClientIds: string[];
  scopes: string[];
  permissions: AppItemPermission[];
};

export type AuthInfo = {
  clientId: string;
  clientSecret: string;
  scope: string;
  authorizeUrl: string;
  authorizationCodeUrl: string;
  refreshTokenUrl: string;
};

export type AuthenticationOption = {
  id: string;
  name: string;
  type: string;
  associatedConfigs: string[];
  authInfo: AuthInfo;
  isDefault: boolean;
};

export type AppConfig = {
  key: string;
  description: string;
  configType: string;
  secret: boolean;
  required: boolean;
};

export type AppItemPermission = {
  name: string;
  displayName: string;
  type: PermissionType;
  level: AppItemPermissionLevel;
};

export enum PermissionType {
  Command,
  Webhook,
  Event,
}

export enum AppItemPermissionLevel {
  Trial = 'trial',
  Team = 'team',
  Advanced = 'advanced',
  Enterprise = 'enterprise',
  AddOn = 'addon',
  Unlisted = 'unlisted',
  Deprecated = 'deprecated',
}

export type ClientAppPermissions = {
  id: string;
  clientId: string;
  appId: string;
  permissions: ClientPermission[];
};

export type ClientPermission = {
  name: string;
  type: PermissionType;
};

export type PublishedApp = {
  appId: string;
  package: AppPackage;
  active: boolean;
  appName: string;
  author: string;
  stamp: string;
  icon: string;
  description: string;
  authInfo: AuthInfo;
  authenticationOptions: AuthenticationOption[];
  configs: AppConfig[];
  scopes: string[];
  permissions: AppItemPermission[];
};

export type AppPackage = {
  id: string;
  appId: string;
  lastUpdatedDate: string;
  createdDate: string;
  deletedDate?: string;
  commands: AppCommandInfo[];
  assemblyContainer: string;
  appVersion: string;
  sdkVersion: string;
  typeName: string;
  typeFullName: string;
  assembly: string;
  approved: boolean;
  description: string;
  metadata: AppPackageMetadata;
};

export type AppCommandInfo = {
  icon: string;
  key: string;
  typeFullName: string;
  name: string;
  schedule: AppCommandSchedule;
  externalNotification: AppCommandExternalNotification;
};

export type AppCommandSchedule = {
  recurrenceFrequency: RecurrenceFrequency;
  interval: number;
};

export enum RecurrenceFrequency {
  Minute,
  Hour,
  Day,
  Week,
  Month,
}

export type AppCommandExternalNotification = {
  challengeKey: string;
  events: string[];
};

export type AppPackageMetadata = {
  appId: string;
  appName: string;
  appPath: string;
  globalInstall: boolean;
  commands: MetadataCommand[];
  webhooks: MetadataWebhook[];
  events: MetadataEvent[];
};

export type MetadataCommand = {
  name: string;
  displayName: string;
  description: string;
  inputs: MetadataInput[];
  outputs: MetadataOutput[];
  emittedEvents: string[];
  dynamicMetadata: boolean;
};

export type MetadataProperty = {
  key: string;
  type: string;
  label: string;
  editor: string;
  source: string;
  sourceFilter: string;
  helpText: string;
  placeholder: string;
  required: boolean;
  values: MetadataPropertyValue[];
  jsonSchema: string;
  hidden: boolean;
  advanced: boolean;
};

export type MetadataPropertyValue = {
  label: string;
  value: string;
};

export type MetadataInput = MetadataProperty & {
  alternateKeys: string[];
  properties: MetadataProperty[];
  triggersDynamicMetadata: boolean;
};

export type MetadataOutput = {
  key: string;
  type: string;
};

export type MetadataWebhook = {
  id: string;
  name: string;
  description: string;
  path: string;
  appPath: string;
  httpMethod: string;
  emittedEvents: string[];
  workflowTrigger: boolean;
  outputs: MetadataOutput[];
};

export type MetadataEvent = {
  name: string;
  description: string;
  type: string;
  headers: MetadataEventValue[];
  values: MetadataEventValue[];
};

export type MetadataEventValue = {
  key: string;
  type: string;
};

//Response Bodies
export type AppsResponseBody = {
  data: AppsResponseData;
};

export type AppsResponseData = {
  items: App[];
  count: number;
};

export type PublishedAppsResponseBody = {
  data: PublishedAppsResponseData;
};

export type PublishedAppsResponseData = {
  items: PublishedApp[];
  count: number;
};
