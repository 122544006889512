import React, { PureComponent } from 'react';
import PageSizeDropdown from './PageSizeDropdown';
import './paging.scss';

export type PaginationProps = {
  page: number;
  pageSize: number;
  totalRows?: number;
  hasMorePages?: boolean;
  hideChangePageSize?: boolean;
  hideTotalPages?: boolean;
  infinitePaging?: boolean;

  changePage?: (page: number) => void;
  changePageSize?: (size: number) => void;
};

export default class Pagination extends PureComponent<PaginationProps, TypeMePlease> {
  constructor(props: PaginationProps) {
    super(props);

    this.pageInputChange = this.pageInputChange.bind(this);
    this.pageInputKeyDown = this.pageInputKeyDown.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.state = { pageText: (this.props.totalRows === 0 ? 1 : this.props.page) || '', infinitePageMax: 1 };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({ pageText: this.props.totalRows === 0 ? 1 : this.props.page });

      if (this.props.infinitePaging && this.state.infinitePageMax < this.props.page + 1) {
        this.setState({ infinitePageMax: this.props.page + 1 });
      }
    }
  }

  pageInputChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ pageText: e.target.value });
    }
  }

  pageInputKeyDown(e) {
    if (e.keyCode === 13) {
      const { pageSize, totalRows } = this.props;
      const totalPages = Math.ceil(totalRows / pageSize);
      let newPage = Number(this.state.pageText);
      newPage = Math.min(newPage, totalPages);
      if (this.props.infinitePaging) {
        this.props.changePage(this.state.pageText);
      } else if (newPage >= 1) {
        this.props.changePage(newPage);
      } else {
        this.setState({ pageText: 1 }, () => {
          this.props.changePage(this.state.pageText);
        });
      }
    }
  }

  changePageSize(e, data) {
    if (data.value !== this.props.pageSize) {
      this.props.changePageSize(data.value);
    }
  }

  next() {
    this.props.changePage(Number(this.props.page) + 1);
  }

  previous() {
    this.props.changePage(Number(this.props.page) - 1);
  }

  render() {
    const { page, pageSize, totalRows } = this.props;
    const totalPages = Math.ceil(totalRows / pageSize);
    let pagerSuffixLabel = <React.Fragment>of {totalPages}</React.Fragment>;
    if (this.props.infinitePaging) {
      pagerSuffixLabel = null;
    }
    return (
      <div className="ui secondary fitted menu wf-paging">
        <div className="item wf-showing-rows">
          <div className="paging-text">
            Page
            <input value={this.state.pageText} onChange={this.pageInputChange} onKeyDown={this.pageInputKeyDown} />
            {pagerSuffixLabel}
          </div>
          {page > 1 ? (
            <button className="plain-button paging-button paging-previous" onClick={this.previous}>
              Previous
            </button>
          ) : null}
          {page > 1 && ((!this.props.infinitePaging && page < totalPages) || (this.props.infinitePaging && this.props.hasMorePages)) ? (
            <span className="paging-separator" />
          ) : null}
          {(!this.props.infinitePaging && page < totalPages) || (this.props.infinitePaging && this.props.hasMorePages) ? (
            <button className="plain-button paging-button paging-next" onClick={this.next}>
              Next
            </button>
          ) : null}
        </div>
        <div className="right menu">
          <div className="item">
            {!this.props.hideChangePageSize ? (
              <React.Fragment>
                <span className="mobile-hidden">Show: &nbsp;</span>
                <PageSizeDropdown currentPageSize={this.props.pageSize} onChange={this.changePageSize} className="mobile-hidden" />
              </React.Fragment>
            ) : null}
            {!this.props.infinitePaging && !this.props.hideTotalPages ? (
              <React.Fragment>
                <span className="paging-total-results">{totalRows} Results</span>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
