import React, { useState, useEffect } from 'react';
import { ApiThrottlingSupportedLimits, ApiThrottlingScope } from './apiConfigs-types';

export type ApiThrottlingLimitsDropdownProps = {
  supportedLimits: ApiThrottlingSupportedLimits;
  scope: ApiThrottlingScope;
  value: number;
  readOnly: boolean;
  onChange: (value: number) => void;
};

export function ApiThrottlingLimitsDropdown(props: ApiThrottlingLimitsDropdownProps) {
  const [limits, setLimits] = useState([{ text: 'loading', value: [] }]);
  
  useEffect(() => {
    if (!props.supportedLimits) {
      return;
    }

    const standardOptions = [{ text: 'Not Set', value: undefined}, { text: 'Unlimited', value: 0}];
    let scopeLimits = [];
    switch(props.scope) {
      case ApiThrottlingScope.day:
        scopeLimits = props.supportedLimits.day.filter(x => x !== 0).map(x => { return { text: x, value: x}; });
        break;

      case ApiThrottlingScope.hour:
        scopeLimits = props.supportedLimits.hour.filter(x => x !== 0).map(x => { return { text: x, value: x}; });
        break;
        
      case ApiThrottlingScope.minute:
        scopeLimits = props.supportedLimits.minute.filter(x => x !== 0).map(x => { return { text: x, value: x}; });
        break;
    }
    setLimits([...standardOptions, ...scopeLimits].map(x => x === 0 || x ? x : ''));
  }, [props.supportedLimits]);

  return (
    <select
      disabled={props.readOnly}
      className="ui fluid dropdown"
      value={props.value}
      onChange={(e) => props.onChange(Number(e.target.value))}
      style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif" }}
    >
      {limits.map((x) => (
        <option key={x.text} value={x.value}>
          {x.text}
        </option>
      ))}
    </select>
  );
}
