import './AzureDevOpsReleaseTable.scss';
import React, { useEffect, useState } from 'react';
import { AzureDevOpsRelease } from './types';
import { Table, TableRow, TableBody, TableCell, Button, Modal, ModalActions, ModalContent, ModalHeader, Checkbox, Icon } from 'semantic-ui-react';
import { ReleaseConnector } from './ReleaseConnector';
import { apiCall } from '../../utility/api';
import Loader from '../Loader';


export type AzureDevOpsReleaseModalProps = {
  releases: AzureDevOpsRelease[];
  fromEnvironment: string;
  toEnvironemnt: string;
  open: boolean;
  onReleaseStarted: () => void;
  onCancelClick: () => void;
}

type ReleaseToDeploy = { 
  release: AzureDevOpsRelease;
  releaseId: number;
  environmentId: number;
}

type FailedAzureDevOpsRelease = AzureDevOpsRelease & { 
  reason: string;
  statusCode: number;
  statusText: string;
}

export function AzureDevOpsReleaseModal(props: AzureDevOpsReleaseModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [failedReleases, setFailedReleases] = useState<FailedAzureDevOpsRelease[]>(null);
  const [releasesToDeploy, setReleasesToDeploy] = useState<ReleaseToDeploy[]>([]);

  const getReleaseToDeploy = (release: AzureDevOpsRelease) => {
    const fromEnvironmentRelease = release.environmentReleases.find(environment => environment.environmentName.startsWith(props.fromEnvironment));
    const toEnvironment = fromEnvironmentRelease.environments.find(environment => environment.name.startsWith(props.toEnvironemnt));

    return {
      release: release,
      releaseId: fromEnvironmentRelease.releaseId,
      environmentId: toEnvironment.id
    };
  }
  
  useEffect(() => { 
    setError(null);
    setFailedReleases(null);
    setReleasesToDeploy(props.releases.map(getReleaseToDeploy));
  }, [props.releases, props.fromEnvironment, props.toEnvironemnt]);

  const onCheckBoxChange = (release: AzureDevOpsRelease, checked: boolean) => {
    if(checked) {
      setReleasesToDeploy([...releasesToDeploy, getReleaseToDeploy(release)]);
    } else {
      setReleasesToDeploy(releasesToDeploy.filter(releaseToDeploy => releaseToDeploy.releaseId !== getReleaseToDeploy(release).releaseId));
    }
  }

  const isChecked = (release: AzureDevOpsRelease) =>
    releasesToDeploy.some(releaseToDeploy => releaseToDeploy.releaseId === getReleaseToDeploy(release).releaseId);


  const onDeployClick = async () => {

    setLoading(true);
    const result = await Promise.all(releasesToDeploy.map(async (release) => {
       const result = await apiCall(`api/azuredevops/releases/${release.releaseId}/${release.environmentId}`, 'PUT');

       return {
          apiResult: result,
          release: release.release,
       }
    }));

    if(result.some(result => result.apiResult.status !== 200)) {
      setError('Failed to deploy some releases. Please Review');
      setFailedReleases(result.map(result => ({
        ...result.release,
        reason: result.apiResult.data,
        statusCode: result.apiResult.status,
        statusText: result.apiResult.statusText,
      })));
    }
    setLoading(false);
    props.onReleaseStarted();
  }

  const getReleaseName = (release: AzureDevOpsRelease, environmentName: string) => { 
    const releaseEnvironment = release.environmentReleases.find(releaseEnvironment => releaseEnvironment.environmentName === environmentName);
    const versionName = releaseEnvironment?.releaseVersion ? ` (${releaseEnvironment?.releaseVersion})` : '';
    return `${release.releaseName}${versionName}`
  }

  return (
    <Modal
      onClose={props.onCancelClick}
      open={props.open}
    >
      <ModalHeader>Deploy {props.toEnvironemnt}</ModalHeader>
      <ModalContent className="azure-devops-release-modal" scrolling>
        {error && <div className='error-text'>{error}</div>}
        {!!failedReleases && <Table definition>
          <TableBody>
            <Loader isLoading={loading} />
            {failedReleases.map((release, releaseIndex) => (
              <TableRow className = 'release-row' key={`release-row-${releaseIndex}`}>
                <TableCell>{`${release.statusCode} - ${release.statusText}`}</TableCell>
                <TableCell>{release.reason}</TableCell>
                <TableCell><a className='release-name' href={release.url}>{getReleaseName(release, props.fromEnvironment)}</a></TableCell>
                <TableCell className='failed-release-icon'><Icon name='cancel' /></TableCell>
                <TableCell><a className='release-name' href={release.url}>{getReleaseName(release, props.toEnvironemnt)}</a></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
        {!failedReleases && <Table definition>
          <TableBody>
            <Loader isLoading={loading} />
            {props.releases.map((release, releaseIndex) => (
              <TableRow className = 'release-row' key={`release-row-${releaseIndex}`}>
                <TableCell><Checkbox checked={isChecked(release)} onChange={(e, { checked }) => onCheckBoxChange(release, checked)} /></TableCell>
                <TableCell><a className='release-name' href={release.url}>{getReleaseName(release, props.fromEnvironment)}</a></TableCell>
                <TableCell><ReleaseConnector/></TableCell>
                <TableCell><a className='release-name' href={release.url}>{getReleaseName(release, props.toEnvironemnt)}</a></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </ModalContent>
      <ModalActions>
        <Button color='black' onClick={props.onCancelClick}>
          Cancel
        </Button>
        <Button
          content="Deploy"
          labelPosition='right'
          icon='checkmark'
          onClick={onDeployClick}
          positive
        />
      </ModalActions>
    </Modal>
  );

 
}
