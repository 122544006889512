import React from 'react';
import './Module.scss';

export type ModuleProps = {
  title?: string;
  children?: any;
  className?: string;
};

export function Module(props: ModuleProps) {
  const { title, children, className } = props;

  return (
    <div className={`module ${className || ''}`}>
      {title ? <h3 className="">{title}</h3> : null}
      <div className="body">{children}</div>
    </div>
  );
}
