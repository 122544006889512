import React, { useEffect, useState } from 'react';
import { Card, CardContent, Message } from 'semantic-ui-react';
import { AppFeatures, canIUse } from '../../utility/SecurityHelper';
import { Module } from '../Module';
import { IneligibleSubscriptionTierForClientConfig, UnavailableAddOnSubscription } from '../addons/AddOnConstants';
import { AddOnsClientConfigurationTable } from '../addons/AddOnsClientConfigurationTable';
import { ClientAddOnsTable } from '../addons/ClientAddOnsTable';
import './AccountAddOns.scss';
import { ClientDto } from './account-types';

export type AccountAddOnsProps = {
  id: string;
  account: ClientDto;
};

export function AccountAddOns(props: AccountAddOnsProps) {
  const { id, account } = props;
  const [message, setMessage] = useState(null);
  const readOnly = !canIUse(AppFeatures.EditFinancialAccountSettings);

  const addOnsUnavailable = UnavailableAddOnSubscription.includes(account.subscriptionCode);
  const addOnClientConfigUnavailable = IneligibleSubscriptionTierForClientConfig.includes(account.subscriptionCode);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  const handleMessageDimiss = () => {
    setMessage(null);
  }

  return (
    <div className="settings">
      <div className="modules">
        <Module title="Add Ons" className="fullwidth-module add-ons-module">
          {addOnsUnavailable && 
            <Card>
              <CardContent header='Add On Unavailable' />
              <CardContent description="Add Ons are unavaiable at the clients current subscription tier" />
            </Card>
          }
           {message && 
            <Message
                success
                header='The request was submitted successfully. '
                content={message}
                onDismiss={handleMessageDimiss}
              />
            }
           {!addOnsUnavailable && 
           <Card>
              <CardContent header='Subscribed Add Ons' />
              <CardContent description="Add Ons that the client has subscribed to at some point in their lifetime" />
              <CardContent extra>
                <ClientAddOnsTable readOnly={readOnly} clientId={id} setCancelMessage={setMessage}/>
              </CardContent>
            </Card>
            }
            {!addOnClientConfigUnavailable &&
            <Card>
              <CardContent header='Client Add On Configurations' />
              <CardContent description="Configurations here will override subscription and global settings. The client will the configuration here on the billing pages" />
              <CardContent extra>
                <AddOnsClientConfigurationTable readOnly={readOnly} clientId={id} />
              </CardContent>
            </Card>
            }
        </Module>
      </div>
    </div>
  );
}
