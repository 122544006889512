import React, { useState } from 'react';
import Loader from '../Loader';
import { canIUse, AppFeatures } from '../../utility/SecurityHelper';
import { Button, Icon, Search, Table } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import './FeatureFlagsList.scss';
import { useApi } from '../../hooks/useApi';
import { FeatureFlag } from './types';

export function FeatureFlagsList() {
  const navigate = useNavigate();
  const readOnly = !canIUse(AppFeatures.EditFeatureFlags);
  const [featureFlags, _, featureFlagsLoaded] = useApi<FeatureFlag[]>('/api/featureflags');
  const [searchResults, setSearchResults] = useState([]);
  const handleSearchChange = React.useCallback((e, data) => {
    const escapeRegExp = (regVal :string) => {
      return (regVal ?? '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    const reg = new RegExp(escapeRegExp(data.value ??  ''), 'i')
    const isMatch = (result: FeatureFlag) => reg.test(result.flagName) || reg.test(result.flagCodeName) || reg.test(result.description) || reg.test(result.category);
  
    const searchResults = featureFlags.filter(isMatch).map(featureFlag => ({
      id: featureFlag.featureFlagId,
      title: `${featureFlag.flagName} (${featureFlag.category})`,
      description: featureFlag.flagName,
    }))
    setSearchResults(searchResults);
  }, [featureFlags])
  
  if (!featureFlagsLoaded) {
    return <Loader isLoading />;
  }

  const onSearchClick =(_, data) => {
    navigate(`./${data.result.id}`);
  }

  const onAddClick = () => {
    navigate('./add');
  }

  const onRowClick = (id: string) => {
    navigate(`./${id}`);
  }

  return (
    <div className="feature-flags-list">
      <div className="page-header">
        <div className="header-item">
          <h2>Feature Flags</h2>
        </div>
        <div className="header-item center">
          <Search
              placeholder='Search...'
              onResultSelect={onSearchClick}
              onSearchChange={handleSearchChange}
              results={searchResults}
          />
        </div>
        <div className="header-item">
        </div>
      </div>
      <div className='list'> 
        <Table singleLine selectable>
          <Table.Header>
              <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
              </Table.Row>
          </Table.Header>
            <Table.Body>
              {featureFlags.sort((ff1, ff2) => ff1.flagName > ff2.flagName ? 1 : -1).map(featureFlag => (
                <Table.Row onClick={() => onRowClick(featureFlag.featureFlagId)} key={featureFlag.featureFlagId}>
                  <Table.Cell className='flag-name' collapsing title={featureFlag.flagName}>{featureFlag.flagName}</Table.Cell>
                  <Table.Cell  collapsing>{featureFlag.flagCodeName}</Table.Cell>
                  <Table.Cell className='flag-description' title={featureFlag.description}>{featureFlag.description}</Table.Cell>
                  <Table.Cell collapsing>{featureFlag.category}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>
                  {!readOnly &&  <Button onClick={onAddClick} className="primary" floated='right'>
                    <Icon name='plus circle' /> Add Feature
                  </Button>}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
        </Table>
      </div>
    </div>
  );
}