import React, {useState} from "react";
import {apimCall} from "../../../utility/api";
import {Button} from "semantic-ui-react";

export function UpdateTemplateDateFormatsButton(props) {
    const [updating, setUpdating] = useState(false);

    const doUpdate = async () => {
        setUpdating(true);
        await apimCall('/templates/updatedateformats', 'POST', null, props.clientId);
        setUpdating(false);
    };

    return (
        <Button onClick={doUpdate} loading={updating}>
            Update Template Date Formats
        </Button>
    );
}