import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import { ApiConfigsDto, ApiThrottlingDisplay } from '../apiConfigs/apiConfigs-types';
import { ClientData } from '../charts/charts-types';
import { AccountFeature, AccountFeatureUpdate } from './features/AccountFeatures';

export function getDisplayApiThrottlingLimits(configs: ApiConfigsDto): ApiThrottlingDisplay {
  return {
    day: configs.apiLimitDay === 0 ? 'Unlimited' : !configs.apiLimitDay ? 'Not Set' : configs.apiLimitDay,
    hour: configs.apiLimitHour === 0 ? 'Unlimited' : !configs.apiLimitHour ? 'Not Set' : configs.apiLimitHour,
    minute: configs.apiLimitMinute === 0 ? 'Unlimited' : !configs.apiLimitMinute ? 'Not Set' : configs.apiLimitMinute,
  };
}

export function useGetData(eventType: string, clientId: string, startDate: string, endDate: string) {
  return useApim<ClientData>(`/metrics/forms/events/clients/${clientId}?eventType=${eventType}&startDate=${startDate}&endDate=${endDate}`, clientId, null);
}

export function setAccountFeatureValue(
  accountFeatureUpdates: AccountFeatureUpdate,
  setAccountFeatureUpdates: React.Dispatch<React.SetStateAction<AccountFeatureUpdate>>,
  accountFeature: AccountFeature,
  featureValue: number
) {
  setAccountFeatureUpdates({
    ...accountFeatureUpdates,
    features: {
      ...(accountFeatureUpdates?.features || {}),
      [accountFeature.featureFlagId]: featureValue,
    },
  });
}

export function getFeatureByFlagCodeName(accountFeatures: AccountFeature[], featureFlagCodeName: string): AccountFeature {
  return accountFeatures.find((feature) => feature.flagCodeName === featureFlagCodeName);
}

export function calculateDefaultStartDate(): string {
  return moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
}

export function filterCreatedFormsDataByTermDate(createdData: ClientData, currentTermStartedAt: moment.Moment) {
  if (!createdData?.dates || createdData?.dates.length === 0) {
    return null;
  }
  const groupedCreatedData: ClientData = {
    clientId: createdData?.clientId,
    clientName: createdData?.clientName,
    dates: [],
  };

  const termStartDay = moment.utc(currentTermStartedAt).startOf('day').date();
  const startDate = moment.utc().subtract(1, 'year').startOf('day');
  const endDate = moment.utc().endOf('day'); // Include the current day

  let currentStart = startDate.clone();
  while (currentStart.isBefore(endDate)) {
    const currentEnd = currentStart.clone().add(1, 'month').date(termStartDay).subtract(1, 'day');
    let validEnd = currentEnd;
    while (!validEnd.isValid()) {
      validEnd = validEnd.clone().subtract(1, 'day');
    }

    const sumCount = createdData.dates
      .filter((entry) => moment.utc(entry.date).isBetween(currentStart, validEnd, null, '[]'))
      .reduce((sum, entry) => sum + entry.count, 0);

    groupedCreatedData.dates.push({
      date: currentStart.format('YYYY-MM-DD'),
      count: sumCount,
    });

    currentStart = currentStart.add(1, 'month').date(termStartDay);
  }
  return groupedCreatedData;
}

export function getCurrentMonthCount(data) {
  if (!data?.dates || data?.dates.length === 0) {
    return null;
  }

  const sortedDates = data.dates.sort((a, b) => moment(b.date).diff(moment(a.date)));
  return sortedDates[0].count;
}

export function getAnnualCount(data, currentTermStartedAt) {
  if (!data?.dates || data?.dates.length === 0) {
    return null;
  }

  return data.dates.filter((entry) => moment(entry.date).isSameOrAfter(currentTermStartedAt)).reduce((sum, entry) => sum + entry.count, 0);
}

export function GetFormCreateLimit(formCreateLimitFeatureFlag: AccountFeature) {
  return typeof formCreateLimitFeatureFlag?.value === 'number' ? formCreateLimitFeatureFlag.value : null;
}
