import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Pagination from '../../paging/Pagination';
import Loader from '../../Loader';
import { useApi } from '../../../hooks/useApi';

type AuditLogProps = {
  clientId: string;
  name: string;
};

type AuditLogItem = {
  actionName: string;
  clientId: number;
  comment: string;
  diff: string;
  goFormzUser: string;
  newValue: string;
  oldValue: string;
  timestamp: string;
};

export function AccountAuditLog(props: AuditLogProps) {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);

  const [auditLogs, auditLogsLoading, auditLogsLoaded, refreshAuditLogs, setAuditLogs] = useApi<AuditLogItem[]>(
    `/api/auditLogs/${props.clientId}?offset=${(page - 1) * pageSize}&fetch=${pageSize + 1}`
  );

  const changePageSize = async (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
    setAuditLogs(null);
    refreshAuditLogs();
  };

  const changePage = async (pageNumber) => {
    setPage(pageNumber);
    setAuditLogs(null);
    refreshAuditLogs();
  };

  return (
    <div>
      <Helmet>
        <title>Account Audit Log - {props.name}</title>
      </Helmet>
      <table className="ui celled striped compact selectable table">
        <thead>
          <tr>
            <th>GoFormz User</th>
            <th>Action</th>
            <th>Timestamp</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Diff</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {auditLogs && auditLogsLoaded
            ? auditLogs.slice(0, pageSize).map((auditLogItem) => {
                return <AuditLogItem key={auditLogItem.timestamp} {...auditLogItem} />;
              })
            : null}
        </tbody>
      </table>
      <div className="paging">
        <Pagination
          changePageSize={changePageSize}
          changePage={changePage}
          pageSize={pageSize}
          page={page}
          infinitePaging={true}
          hasMorePages={auditLogs && auditLogsLoaded && auditLogs.length > pageSize}
        />
      </div>
      <Loader isLoading={auditLogsLoading || !auditLogs} />
    </div>
  );
}

function AuditLogItem(auditLogItem: AuditLogItem) {
  const { actionName, clientId, comment, diff, goFormzUser, newValue, oldValue, timestamp } = auditLogItem;
  const actionNameEnum = [
    'Account_Features_Update',
    'Account_Settings_Update',
    'Account_ApiConfigs_Update',
    'Account_User_Reactivate',
    'Account_TrialEndDate_Update',
    'Account_CompanyName_Update',
    'Account_Pricing_Update',
    'Account_PublicCreditPrice_Update',
    'Account_PublicCredit_Update',
    'Account_Subscription_Update',
    'Account_ClientAppPermissions_Update',
    'Account_Deactivate',
    'Account_Monitoring_Update',
    'Account_AddOns_Update',
    'Account_User_Deactivate',
  ];

  const actionNameConverted = actionNameEnum[parseInt(actionName, 10)] || actionName;
  return (
    <tr>
      <td>{goFormzUser}</td>
      <td>{actionNameConverted}</td>
      <td>{moment.utc(timestamp).local().format('MM/DD/YYYY h:mm:ss a')}</td>
      <td>
        {oldValue?.length > 50 ? (
          <span>
            Value too long. Click to copy:&nbsp;&nbsp;
            <button className="ui compact mini icon button" title={oldValue} onClick={() => navigator.clipboard.writeText(oldValue)}>
              <i className="copy icon"></i>
            </button>
          </span>
        ) : (
          <span>{oldValue}</span>
        )}
      </td>
      <td>
        {newValue?.length > 50 ? (
          <span>
            Value too long. Click to copy:&nbsp;&nbsp;
            <button className="ui compact mini icon button" title={newValue} onClick={() => navigator.clipboard.writeText(newValue)}>
              <i className="copy icon"></i>
            </button>
          </span>
        ) : (
          <span>{newValue}</span>
        )}
      </td>
      <td>
        {diff?.length > 50 ? (
          <span>
            Value too long. Click to copy:&nbsp;&nbsp;
            <button className="ui compact mini icon button" title={diff} onClick={() => navigator.clipboard.writeText(diff)}>
              <i className="copy icon"></i>
            </button>
          </span>
        ) : (
          <span>{diff}</span>
        )}
      </td>
      <td>{comment}</td>
    </tr>
  );
}
