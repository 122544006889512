import React from 'react';

export type AuthenticationOptionProps = {
  name: string;
  type: string;
  associatedConfigs: string;
  authInfo: {
      clientId: string,
      clientSecret: string,
      scope: string,
      authorizeUrl: string,
      authorizationCodeUrl: string,
      refreshTokenUrl: string,
  }
  isDefault: boolean;
  index: number;
  setAuthOption: (index: number, type: string, value: string | string[] | boolean, isAuthInfo?: boolean) => void;
  removeAuthOption: (index: number) => void;
};

export default function AuthenticationOption(props: AuthenticationOptionProps) {
  const { name, type, associatedConfigs, authInfo, isDefault, index, setAuthOption, removeAuthOption } = props;

  return (
    <div>
      <h4 className="ui dividing header">Authentication Option</h4>
      <div className="four fields">
        <div className="field">
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setAuthOption(index, 'name', e.target.value)} />
        </div>
        <div className="field">
          <label>Type</label>
          <select value={type} onChange={(e) => setAuthOption(index, 'type', e.target.value)}>
            <option value="oauth2">oauth2</option>
            <option value="simple">simple</option>
          </select>
        </div>
        <div className="field">
          <label>Associated Configs</label>
          <input type="text" value={associatedConfigs} onChange={(e) => setAuthOption(index, 'associatedConfigs', e.target.value.split(','))} />
        </div>
        <div className="field">
          <label>Is Default</label>
          <input type="checkbox" checked={isDefault} onChange={(e) => setAuthOption(index, 'isDefault', e.target.checked)} />
        </div>
      </div>

      <h5 className="ui dividing header">Auth Info (OAuth2 only)</h5>

      <div className="three fields">
        <div className="field">
          <label>Client Id</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('clientId') ? authInfo.clientId : ''}
            onChange={(e) => setAuthOption(index, 'clientId', e.target.value, true)}
          />
        </div>

        <div className="field">
          <label>Client Secret</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('clientSecret') ? authInfo.clientSecret : ''}
            onChange={(e) => setAuthOption(index, 'clientSecret', e.target.value, true)}
          />
        </div>

        <div className="field">
          <label>Scope</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('scope') ? authInfo.scope : ''}
            onChange={(e) => setAuthOption(index, 'scope', e.target.value, true)}
          />
        </div>
      </div>

      <div className="three fields">
        <div className="field">
          <label>Authorize Url</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('authorizeUrl') ? authInfo.authorizeUrl : ''}
            onChange={(e) => setAuthOption(index, 'authorizeUrl', e.target.value, true)}
          />
        </div>

        <div className="field">
          <label>Authorization Code Url</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('authorizationCodeUrl') ? authInfo.authorizationCodeUrl : ''}
            onChange={(e) => setAuthOption(index, 'authorizationCodeUrl', e.target.value, true)}
          />
        </div>

        <div className="field">
          <label>Refresh Token Url</label>
          <input
            type="text"
            value={authInfo !== null && authInfo.hasOwnProperty('refreshTokenUrl') ? authInfo.refreshTokenUrl : ''}
            onChange={(e) => setAuthOption(index, 'refreshTokenUrl', e.target.value, true)}
          />
        </div>
      </div>

      <a
        href="#"
        className="remove-authenticationoption"
        onClick={(e) => {
          e.preventDefault();
          removeAuthOption(index);
        }}
      >
        Remove
      </a>
      <br />
      <br />
    </div>
  );
}
