import React, { useState } from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import { useMessages } from '../../context/MessagesContext';
import { useApi } from '../../hooks/useApi';
import { apiCall } from '../../utility/api';
import Loader from '../Loader';
import { AddOnsAddRow } from './AddOnsAddRow';
import { AddOnsSaveTableFooter } from './AddOnsSaveTableFooter';
import './ClientAddOnsTable.scss';
import { ClientAddOnsTableHeader } from './ClientAddOnsTableHeader';
import { ClientAddOnsTableRow } from './ClientAddOnsTableRow';
import { AddOnConfigurationDto, ClientAddOnDto, SubscriptionChangeTimeframe } from './types';

export type ClientAddOnsTableProps = {
  readOnly: boolean;
  Client?: string;
  clientId?: string;
  setCancelMessage: (message: string) => void;
}

export function ClientAddOnsTable(props: ClientAddOnsTableProps) {
  const { readOnly, clientId, setCancelMessage } = props;
  const { showMessage } = useMessages();

  //global add ons
  const [addOnConfigurations, addOnConfigurationsLoading, _addOnConfigurationsLoaded, refreshAddOnConfigurations] = useApi<AddOnConfigurationDto[]>('api/addons/configurations');
  const addOnConfigurationsOrDefault = addOnConfigurations || [];
  
  //Client configurations 
  const [clientAddOns, clientAddOnsLoading, _clientAddOnsLoaded, refreshClientAddOns] = useApi<ClientAddOnDto[]>(`api/addons/accounts/${clientId}`);
  const clientAddOnsOrDefault = clientAddOns || [];

  const [clientAddOnChanges, setClientAddOnChanges] = useState<ClientAddOnDto[]>([]);
  const [newClientAddOn, setNewClientAddOn] = useState<ClientAddOnDto>(null);
  const [comment, setComment] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);
  const isLoading = isSaving || addOnConfigurationsLoading || clientAddOnsLoading;

  const clientAddOnsToDisplay = !isLoading && clientAddOnsOrDefault
    .filter((addOn) => !addOn.effectiveUnsubscribedDate)
    .sort((a, b) => sortedByCreatedDateDesc(a, b))
    .reduce((unique, item) => {
      const itemCode = addOnConfigurationsOrDefault?.find(x => x.addOnConfigurationId == item.addOnConfigurationId)?.code;
      //check if an add-on with the same code already exists in the unique array so we only display unique codes
      return unique.some((u) => addOnConfigurationsOrDefault?.find(x => x.addOnConfigurationId === u.addOnConfigurationId)?.code == itemCode) ? unique : [...unique, item];
    }, [])
    .sort((a, b) => {
      //show subscribed/active add-ons first
      return sortAddOnsBySubscribedDate(a, b);
    });

  function sortedByCreatedDateDesc(addonA, addonB) {
    return new Date(addonB.createdDate).getTime() - new Date(addonA.createdDate).getTime();
  }

  function sortAddOnsBySubscribedDate(addonA, addonB) {
    //items without both dates come first
    if (!addonA.pendingUnsubscribedDate && !addonA.effectiveUnsubscribedDate && (addonB.pendingUnsubscribedDate || addonB.effectiveUnsubscribedDate)) {
        return -1; //addonA comes before addonB
    }
    if (!addonB.pendingUnsubscribedDate && !addonB.effectiveUnsubscribedDate && (addonA.pendingUnsubscribedDate || addonA.effectiveUnsubscribedDate)) {
        return 1; //addonB comes before addonA
    }

    // Items without effectiveUnsubscribedDate come second
    if (!addonA.effectiveUnsubscribedDate && addonB.effectiveUnsubscribedDate) {
        return -1;
    }
    if (!addonB.effectiveUnsubscribedDate && addonA.effectiveUnsubscribedDate) {
        return 1;
    }

    //maintain original order
    return 0;
}

  const unOverridenAddOns = clientAddOnsToDisplay?.length > 0 ? addOnConfigurationsOrDefault?.filter((addOnConfiguration) =>
    !clientAddOnsToDisplay?.some((clientAddOn) => clientAddOn.addOnConfigurationId === addOnConfiguration.addOnConfigurationId && (clientAddOn.effectiveUnsubscribedDate == null))) 
  : addOnConfigurationsOrDefault;
  const nextAddOnOverride = unOverridenAddOns.length > 0 ? unOverridenAddOns[0] : null;

  const onClientAddOnChange = (clientAddOn: ClientAddOnDto) => {
    const index = clientAddOnChanges.findIndex((x) => x.addOnConfigurationId === clientAddOn.addOnConfigurationId);
    const newChanges = [...clientAddOnChanges];
    if(index > -1 ) { 
      newChanges[index] = clientAddOn;
    } else { 
      newChanges.push(clientAddOn);
    }

    setClientAddOnChanges(newChanges);
  }

  const onNewClientAddOnChange = (clientAddOn: ClientAddOnDto) => {
    if (clientAddOn.addOnConfigurationId !== newClientAddOn.addOnConfigurationId) {
      const updatedAddOn = getAddOnConfigs(clientAddOn);
      setNewClientAddOn(updatedAddOn);
    }
    else {
      setNewClientAddOn(clientAddOn);
    }
  }

  const getAddOnConfigs = (clientAddOn: ClientAddOnDto) => {
    const addOnConfig = addOnConfigurationsOrDefault?.find(x => x.addOnConfigurationId === clientAddOn.addOnConfigurationId);
    return {...clientAddOn, 
      pricingType: addOnConfig?.pricingType, 
      monthlyPrice: addOnConfig?.monthlyPrice, 
      yearlyPrice: addOnConfig?.yearlyPrice, 
      userLimit: addOnConfig?.userLimit};
  }

  const onNewClientAddOnDelete = (_clientAddOn: ClientAddOnDto) => {
    setNewClientAddOn(null);
  }

  const onAddNewClientAddOnClick = () => { 
    setNewClientAddOn({
      clientId: props.clientId,
      addOnConfigurationId: nextAddOnOverride.addOnConfigurationId,
      pricingType: nextAddOnOverride.pricingType,
      monthlyPrice: nextAddOnOverride.monthlyPrice,
      yearlyPrice: nextAddOnOverride.yearlyPrice,
      userLimit: nextAddOnOverride.userLimit,
    });
  }

  const onSaveClick = async () => { 
    if(readOnly) {
      return;
    }
    setIsSaving(true);
    
    try {
      const result = await apiCall(`api/addons/accounts/${clientId}`, 'POST', {
        upserts: [...clientAddOnChanges, newClientAddOn].filter(obj => !!obj),
        comment: comment
      });

      if(result.status !== 200) { 
        showMessage('Failed to save the subscriptions add-on configuration changes');
        return;
      }
    } catch(e) { 
      console.log(e);
      showMessage('Failed to save the subscriptions add-on configuration changes');
    }
      
    setIsSaving(false);
    setClientAddOnChanges([]);
    setNewClientAddOn(null) 

    refreshAddOnConfigurations();
    refreshClientAddOns();
  }

  const canSave = () => { 
    if(readOnly) { 
      return false;
    }
    
    if(comment == null || comment === '') { 
      return false;
    }

    if(!clientAddOnChanges.length && !newClientAddOn) {
      return false;
    }

    const changes = [...clientAddOnChanges, newClientAddOn].filter(obj => !!obj);
    const changesMissingRequiredProps = changes.some(change => { 
      return change.addOnConfigurationId == null || 
      change.pricingType == null ||
      change.monthlyPrice == null || 
      change.yearlyPrice == null
    });

    if(changesMissingRequiredProps) { 
      return false;
    }

    return true;
  }

  const onCancelConfirm = async (clientAddOn: ClientAddOnDto, cancelAt: SubscriptionChangeTimeframe) => {
    try {
      setIsSaving(true);

      const result = await apiCall(`api/addons/accounts/${clientAddOn.clientId}`, 'POST', {
        deletions: [clientAddOn],
        deleteTimeFrame: cancelAt,
      });

      if(result.status !== 200) { 
        showMessage('Failed to cancel the add-on subscription');
        return;
      }
      
      setCancelMessage('Cancellations may not be immediately reflected until Recurly confirms the cancellation. Please check back or refresh the page for updates.');
    } catch(e) { 
      console.log(e);
      showMessage('Failed to cancel the add-on subscription');
    }

    setIsSaving(false);
    refreshAddOnConfigurations();
    refreshClientAddOns();
  }

  const addButtonDisabled = nextAddOnOverride == null || readOnly;
  const saveButtonDisabled = !canSave();
  const columnCount = 8;

  return (
    <div className='client-add-ons-table'>
      <Loader isLoading={isLoading} />
      <Table singleLine>
        <ClientAddOnsTableHeader/>
        <TableBody className='client-add-ons-table-body'>
          {clientAddOnsToDisplay?.length > 0 && clientAddOnsToDisplay.map((clientAddOn) => (
            <ClientAddOnsTableRow 
              clientAddOn={clientAddOn}
              addOnConfigurations={[
                ...unOverridenAddOns, 
                addOnConfigurationsOrDefault.find(x => x.addOnConfigurationId === clientAddOn.addOnConfigurationId)
              ].filter(addOnConfiguration => !!addOnConfiguration)}
              key={`client-add-on-${clientAddOn?.addOnConfigurationId}`} 
              readOnly={readOnly || !!clientAddOn.deletedDate || !!clientAddOn.effectiveUnsubscribedDate} 
              onChange={onClientAddOnChange}
              onCancelConfirm={onCancelConfirm} />
          ))}
          {!newClientAddOn && !props.readOnly && (
            <AddOnsAddRow colSpan={columnCount}
              text="Add Client Add On"
              onClick={onAddNewClientAddOnClick} 
              disabled={addButtonDisabled} />
          )}
          {newClientAddOn && 
            <ClientAddOnsTableRow 
              clientAddOn={newClientAddOn}
              addOnConfigurations={unOverridenAddOns}
              readOnly={readOnly} 
              onChange={onNewClientAddOnChange}
              onDelete={onNewClientAddOnDelete}
              onCancelConfirm={onCancelConfirm} />
          }
        </TableBody>
        {!props.readOnly && 
          <AddOnsSaveTableFooter colSpan={columnCount} readOnly={readOnly} disableSave={saveButtonDisabled  } comment={comment} onCommentChange={setComment} onSaveClick={onSaveClick} />
        }
      </Table>
    </div>
  );
}
