import React, { useState } from 'react';
import { useRecentSearches } from '../../hooks/useRecentSearches';
import { RecentSearchList } from './RecentSearchList';

export type SuggestedSearchInputProps = {
  term?: string;
  onSearch: (term: string, condition: string) => void;
};

export function SuggestedSearchInput({ term = '', onSearch }: SuggestedSearchInputProps) {
  const [recentSearches, updateRecentSearches] = useRecentSearches();
  const [searchText, setSearchText] = useState(term);
  const [filteredSearches, setFilteredSearches] = useState(recentSearches);
  const [isRecentListOpen, setIsRecentListOpen] = useState(false);

  /** @param {KeyboardEvent} e */
  const handleSearchKeyDown = ({ key }: { key: string }) => {
    switch (key) {
      case 'Enter':
        performSearch(searchText, 'clients');
        break;
      case 'Escape':
        setIsRecentListOpen(false);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (term: string) => {
    setSearchText(term);
    // filter the list of terms
    filterRecentSearches(term);
  };

  const handleOnTermClick = (term: string, condition: string) => {
    setSearchText(term);
    filterRecentSearches(term);
    setIsRecentListOpen(false);
    performSearch(term, condition);

    console.log(condition);
  };

  const handleOnBlur = (e) => {
    e.preventDefault();
    // delay long enough the set recent list to extract the value before
    // closing
    setTimeout(() => {
      setIsRecentListOpen(false);
    }, 250);
  };

  const filterRecentSearches = (term: string) => {
    // only filter when there's a value typed
    setFilteredSearches(recentSearches.filter((recentTerms) => recentTerms.toLowerCase().includes(term.toLowerCase())));
  };

  const performSearch = (term: string, condition: string) => {
    // persist the search term
    updateRecentSearches(term);
    onSearch(term, condition);
  };

  const searchInput = (
    <div className="ui icon input">
      <input
        id="headerSearch"
        type="text"
        placeholder="Search..."
        value={searchText}
        autoComplete="off"
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={handleSearchKeyDown}
        onClick={() => setIsRecentListOpen(true)}
        onBlur={handleOnBlur}
      />
      <i className="search link icon" onClick={() => performSearch(searchText, 'clients')} tabIndex={-1} />
    </div>
  );

  return (
    <div className="suggested-search-input">
      <RecentSearchList terms={filteredSearches} trigger={searchInput} onTermClick={handleOnTermClick} open={isRecentListOpen} searchText={searchText} />
    </div>
  );
}
