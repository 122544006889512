import React from 'react';
import { useApim } from '../../../hooks/useApi';
import Loader from '../../Loader';
import './EmailActivityEvents.scss';
import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { CombinedMessageRecipientType } from './EmailActivity';

export type EmailActivityEventsProps = {
  clientId: string;
  combinedMessageRecipient: CombinedMessageRecipientType;
  onClose: VoidFunction;
};

export default function EmailActivityEvents(props: EmailActivityEventsProps) {
  const { clientId, combinedMessageRecipient } = props;

  const [activityEvents, loading, loaded] = useApim<EmailActivityEvent[]>(
    `/emailactivity/messages/${combinedMessageRecipient.messageId}/recipients/${combinedMessageRecipient.messageRecipientId}/events`,
    clientId
  );

  return (
    <Modal open onClose={() => props.onClose} dimmer="inverted" closeOnDimmerClick>
      <Modal.Header>Message Status</Modal.Header>
      <Modal.Content>
        <div className="email-activity-events">
          <table className="ui table basic definition">
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  {' '}
                  <div className="email-status-wrapper">
                    <div className={`status-circle ${combinedMessageRecipient.status.toLowerCase()}`}></div>
                    <span>{combinedMessageRecipient.status}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>To</td>
                <td>{combinedMessageRecipient.emailAddress}</td>
              </tr>
              <tr>
                <td>Message</td>
                <td>{combinedMessageRecipient.subject}</td>
              </tr>
              <tr>
                <td>Timestamp</td>
                <td>{moment.utc(combinedMessageRecipient.timestamp).local().format('MM/DD/YYYY h:mm:ss a')}</td>
              </tr>
              {combinedMessageRecipient.formId ? (
                <tr>
                  <td>Form Id</td>
                  <td>{combinedMessageRecipient.formId}</td>
                </tr>
              ) : null}
              {combinedMessageRecipient.correlationId ? (
                <tr>
                  <td>Correlation Id</td>
                  <td>{combinedMessageRecipient.correlationId}</td>
                </tr>
              ) : null}
              <tr>
                <td>Message Id</td>
                <td>{combinedMessageRecipient.messageId}</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>{combinedMessageRecipient.emailProvider}</td>
              </tr>
            </tbody>
          </table>

          <h3>Events</h3>

          <table className="ui table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Event</th>
                <th>Response</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {loaded
                ? activityEvents.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{moment.utc(row.timestamp).local().format('MM/DD/YYYY h:mm:ss a')}</td>
                        <td>
                          <div className="email-status-wrapper">
                            <div className={`status-circle ${row.event.toLowerCase()}`}></div>
                            <span>{row.event}</span>
                          </div>
                        </td>
                        <td>{row.response}</td>
                        <td>{row.reason}</td>
                      </tr>
                    );
                  })
                : null}
              {loading ? (
                <tr>
                  <td colSpan={4}>
                    <div className="loading-wrapper">
                      <Loader isLoading />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

type EmailActivityEvent = {
  emailAddress: string;
  timestamp: string;
  event: string;
  attempt?: number;
  response: string;
  reason: string;
};
