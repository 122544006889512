import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export type ConfirmationModal = {
  isOpen: boolean;
  headerText?: string;
  confirmationText?: React.ReactNode;
  body?: React.ReactNode;
  closeModal: () => void;
  onConfirm: () => void;
}

export default function ConfirmationModal({ isOpen, headerText, confirmationText, body, closeModal, onConfirm }: ConfirmationModal) {
  return (
    <>
      <Modal className='confirmation-modal' size={'tiny'} open={isOpen} onClose={closeModal} dimmer="inverted">
        <Modal.Header>{headerText}</Modal.Header>
        <Modal.Content>
          {!!confirmationText && <span>{confirmationText}</span>}
          {!!body && <div>{body}</div>}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button primary onClick={onConfirm}>
            Confirm
          </Button>
        </Modal.Actions>
     </Modal>
    </>
  )
}