import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export type AddOnCancelConfirmModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  isCancelNow: boolean;
  onConfirm: () => void;
  addOnCode: string;
}

export default function AddOnCancelConfirmModal({ isOpen, closeModal, isCancelNow, onConfirm, addOnCode }) {
  const cancelAtTerm = isCancelNow ? 'now' : 'at renewal';
  return (
    <>
      <Modal size={'tiny'} open={isOpen} onClose={closeModal} dimmer="inverted">
      <Modal.Header>{`Confirm Cancel`}</Modal.Header>
      <Modal.Content>
        <span>{`Are you sure you want to cancel `}<strong>{addOnCode}</strong>{` add-on ${cancelAtTerm}?`}</span>
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button primary onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
    </>
  )
}