import React, { useLayoutEffect, useRef, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import Chart from 'chart.js';
import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';

import './Charts.scss';
// todo - replace: this package is outdated and not actively supported and preventing us from upgrading chart.js
import 'chartjs-plugin-colorschemes';

export type WorkflowClientTopProps = {
  title?: string;
  clientId: string;
  startDate?: string;
  endDate?: string;
  width?: number;
  height?: number;
  colorScheme?: string;
};

export function WorkflowClientTop(props: WorkflowClientTopProps) {
  const defaultStartDate = useRef<TypeMePlease>(moment().subtract(14, 'days').format('YYYY-MM-DD'));
  const defaultColorScheme = useRef<TypeMePlease>(Chart['colorschemes'].tableau.Classic20);
  const [failures, setFailures] = useState(true);

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate || '';
  const colorScheme = getColorScheme();

  const [data, dataLoading, dataLoaded] = useApim<TypeMePlease>(
    `/metrics/workflows/jobs/attempts/clients/${props.clientId}?topWorkflows=20&bySuccess=true${
      failures ? '&success=false' : ''
    }&byWorkflow=true&startDate=${startDate}&endDate=${endDate}`,
    props.clientId
  );
  const [workflows, workflowsLoading, workflowsLoaded] = useApim<TypeMePlease>(`/workflows`, props.clientId);

  useLayoutEffect(() => {
    if (!dataLoaded || !workflowsLoaded) {
      return;
    }

    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'horizontalBar',
        options: {
          aspectRatio: 1.4,
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: (tooltipItem, data) => {
                const dataItem = data.datasets[0].data[tooltipItem[0].index];
                return [dataItem.name, dataItem.id];
              },
            },
          },
        },
      });
    }

    chart.current.data.datasets = [];

    if (dataLoaded && workflowsLoaded) {
      if (data.length === 0) {
        return;
      }

      // list of workflows with succes/failure counts
      const items = data
        .reduce((array, item) => {
          let index = array.findIndex((x) => x.id === item.workflowId);
          if (index < 0) {
            const workflowName = workflows.data.items.find((x) => x.id === item.workflowId)?.name || item.workflowId;
            array.push({ id: item.workflowId, name: workflowName, successes: 0, failures: 0, total: 0 });
            index = array.length - 1;
          }
          const count = item.dates.reduce((a, b) => a + b.count, 0);
          const currentItem = array[index];
          if (item.success) {
            currentItem.successes = count;
          } else {
            currentItem.failures = count;
          }
          currentItem.total = currentItem.successes + currentItem.failures;
          return array;
        }, [])
        .sort((a, b) => b.total - a.total);

      // stacked bar chart data
      chart.current.data.labels = items.map((x) => {
        const name = x.name.length > 17 ? `${x.name.substring(0, 15)}...` : x.name;
        return name;
      });
      chart.current.data.datasets.push({
        label: 'Success',
        backgroundColor: colorScheme[5],
        data: items.map((x) => {
          return { x: x.successes, name: x.name, id: x.id };
        }),
      });
      chart.current.data.datasets.push({
        label: 'Failures',
        backgroundColor: colorScheme[7],
        data: items.map((x) => {
          return { x: x.failures, name: x.name, id: x.id };
        }),
      });

      // Redraw the chart
      chart.current.update();
    }
  }, [dataLoaded, data, workflowsLoaded, workflows]);

  function getColorScheme() {
    let scheme;
    if (props.colorScheme) {
      const parts = props.colorScheme.split('.');
      if (parts.length > 1) {
        scheme = Chart['colorschemes'][parts[0]][parts[1]];
      }
    }
    if (!scheme) {
      scheme = defaultColorScheme.current;
    }
    return scheme;
  }

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 400}px` }}>
      <Loader isLoading={dataLoading || workflowsLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
        <span style={{ margin: '0px 5px' }}>Failures Only</span>
        <Checkbox checked={failures} toggle onChange={(e, data) => setFailures(data.checked)} />
      </div>
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
