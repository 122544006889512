import moment from 'moment';
import React from 'react';
import { Dropdown, Icon, Input, Popup, TableCell, TableRow } from 'semantic-ui-react';
import { ActionMenu } from '../shared/actionmenu/ActionMenu';
import { ActionMenuItemProps } from '../shared/actionmenu/actionMenu-types';
import AddOnCancelConfirmModal from './AddOnCancelConfirmModal';
import { CalendarOptions, PricingTypeDropdownValues } from './AddOnConstants';
import './ClientAddOnsTableRow.scss';
import { DeleteAddOnButton } from './DeleteAddOnButton';
import { AddOnConfigurationDto, ClientAddOnDto, SubscriptionChangeTimeframe } from './types';
import useCancelAddOn from './useCancelAddOn';

export type ClientAddOnsTableRowProps = { 
  clientAddOn: ClientAddOnDto;
  addOnConfigurations: AddOnConfigurationDto[];
  readOnly?: boolean;
  onChange: (clientAddOn: ClientAddOnDto) => void;
  onDelete?: (clientAddOn: ClientAddOnDto) => void;
  onCancelConfirm?: (clientAddOn: ClientAddOnDto, cancelAt: SubscriptionChangeTimeframe) => void;
}

export function ClientAddOnsTableRow(props: ClientAddOnsTableRowProps) {
  const { clientAddOn, addOnConfigurations, readOnly } = props;
  const { onCancelAtRenewal, onCancelNow, isCancelConfirmModalOpen, onCloseCancelConfirmModal, cancelAt } = useCancelAddOn();

  const addOnDropDownValues = (addOnConfigurations || []).map((addOnConfiguration, index) =>({
    key: `client-add-on-dropdown-${addOnConfiguration.addOnConfigurationId}-${index}`,
    text: `${addOnConfiguration.title} (${addOnConfiguration.code})`,
    value: addOnConfiguration.addOnConfigurationId,
  }));
  

  const onPropertyChange = (property: keyof ClientAddOnDto, value: any) => {
    props.onChange({ ...props.clientAddOn, [property]: value });
  }

  const onDelete = () => { 
    props.onDelete(props.clientAddOn);
  }

  const createCancelOption = (text: string, action: () => void, disabled: boolean): ActionMenuItemProps => ({
    text,
    action: disabled ? undefined : action,
    disabled,
  });

  const getCancelOptions = () => {
    //Can cancel now if the add-on hasn't been stopped yet 
    const isAlreadyCanceled = !!clientAddOn.effectiveUnsubscribedDate;
    const isAlreadyCanceledAtRenewal = !!clientAddOn.pendingUnsubscribedDate;
  
    return [
      createCancelOption('Cancel Now', onCancelNow, isAlreadyCanceled),
      createCancelOption('Cancel At Renewal', onCancelAtRenewal, isAlreadyCanceled || isAlreadyCanceledAtRenewal),
    ];
  };

  const getAddOnCode = () => {
    return addOnConfigurations?.length > 0 && addOnConfigurations?.filter(x => x.addOnConfigurationId === clientAddOn.addOnConfigurationId)[0]?.code;
  }

  const onConfirmCancel = () => {
    props.onCancelConfirm(clientAddOn, cancelAt);
    onCloseCancelConfirmModal();
  }

  return (
    <TableRow className="client-add-ons-table-row">
      <TableCell className='client-add-ons-table-row-value add-on-id'>
        {!readOnly && !!props.onDelete && <DeleteAddOnButton onClick={onDelete} /> }
        <Dropdown
          className='client-add-on-dropdown'
          search
          selection 
          value={clientAddOn.addOnConfigurationId} 
          options={addOnDropDownValues}
          disabled={clientAddOn.createdDate != null || readOnly}
          onChange={(_, data) => onPropertyChange('addOnConfigurationId', data.value)} />         
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-pricing-type'>
        <Dropdown 
          value={clientAddOn.pricingType} 
          options={PricingTypeDropdownValues}
          disabled={readOnly}
          onChange={(_, data) => onPropertyChange('pricingType', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-price'>
        <Input fluid placeholder='Price when billed monthly'
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={clientAddOn.monthlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('monthlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-price'>
        <Input fluid placeholder='Price when billed annually' 
          type='number'
          step='0.01'
          min='0' 
          disabled={readOnly}
          value={clientAddOn.yearlyPrice ?? ''} 
          onChange={(_, data) => onPropertyChange('yearlyPrice', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-user-limit'>
        <Input fluid 
          type='number'
          step='1'
          min='0' 
          disabled={readOnly}
          value={clientAddOn.userLimit ?? ''} 
          onChange={(_, data) => onPropertyChange('userLimit', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-users-assigned'>
        <Input fluid 
          type='number'
          step='1'
          min='0' 
          disabled={true}
          value={clientAddOn.usersAssigned ?? ''} 
          onChange={(_, data) => onPropertyChange('usersAssigned', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-unsubscribed-date'>
        <Input fluid 
          type='date'
          disabled={true}
          value={clientAddOn.pendingUnsubscribedDate ? moment.utc(clientAddOn.pendingUnsubscribedDate).format('YYYY-MM-DD') : null} 
          onChange={(_, data) => onPropertyChange('pendingUnsubscribedDate', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-unsubscribed-date'>
        <Input fluid 
          type='date'
          disabled={true}
          value={clientAddOn.effectiveUnsubscribedDate ? moment.utc(clientAddOn.effectiveUnsubscribedDate).format('YYYY-MM-DD') : null} 
          onChange={(_, data) => onPropertyChange('effectiveUnsubscribedDate', data.value)} /> 
      </TableCell>

      <TableCell className='client-add-ons-table-row-value add-on-metadata'>
        <Popup
          on='click'
          trigger={<Icon size='small' circular name='info' />}
          content= { 
            <div className='add-ons-dates'>
              <div className='add-ons-dates-section'>
                <label>Created By</label>
                <span>{clientAddOn.createdBy}</span>
              </div>
              <div className='add-ons-dates-section'>
                <label>Created Date</label>
                <span>{clientAddOn.createdDate == null ? '--' : moment.utc(clientAddOn.createdDate).local().calendar(null, CalendarOptions)}</span>
              </div>

              <div className='add-ons-dates-section'>
                <label>Unsubscribed By</label>
                <span>{clientAddOn.pendingUnsubscribedBy}</span>
              </div>

              <div className='add-ons-dates-section'>
                <label>Last Updated By</label>
                <span>{clientAddOn.lastUpdatedBy}</span>
              </div>

              <div className='add-ons-dates-section'>
                <label>Last Updated Date</label>
                <span>{clientAddOn.lastUpdatedDate == null ? '--' : moment.utc(clientAddOn.lastUpdatedDate).local().calendar(null, CalendarOptions)}</span>
              </div>
              
              <div className='add-ons-dates-section'>
                <label>Deleted By</label>
                <span>{clientAddOn.deletedBy ?? '--'}</span>
              </div>
              <div className='add-ons-dates-section'>
                <label>Deleted Date</label>
                <span>{clientAddOn.deletedDate == null ? '--' : moment.utc(clientAddOn.lastUpdatedDate).local().calendar(null, CalendarOptions)}</span>
              </div>
            </div>
          }
        />
      </TableCell>
      {!readOnly && clientAddOn.createdDate != null &&
        <TableCell className='client-add-ons-table-row-value add-on-action-menu'>
          <ActionMenu options={getCancelOptions()}/>
        </TableCell>
      }

      <AddOnCancelConfirmModal onConfirm={onConfirmCancel} 
        isOpen={isCancelConfirmModalOpen} 
        isCancelNow={cancelAt === SubscriptionChangeTimeframe.Now} 
        closeModal={onCloseCancelConfirmModal} 
        addOnCode={getAddOnCode()} />
    </TableRow>
  );
}