import React, { useLayoutEffect, useRef } from 'react';
import moment from 'moment';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import Chart from 'chart.js';
import { getLabelForEventType } from './Charts';
import './Charts.scss';

export type FormEventsByClientProps = {
  clientId: string;
  title?: string;
  eventTypes?: string[];
  startDate?: string;
  endDate?: string;
  width?: number;
  height?: number;
  useLegend?: boolean;
};

export function FormEventsByClient(props: FormEventsByClientProps) {
  const { clientId } = props;
  const defaultStartDate = useRef<TypeMePlease>(moment().subtract(6, 'months').format('YYYY-MM-DD'));

  const eventTypes = props.eventTypes || ['completed'];
  const startDate = props.startDate || defaultStartDate.current;
  const endDate = props.endDate;
  const useLegend = props.useLegend === undefined ? true : props.useLegend;

  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const [createdData, createdDataLoading, createdDataLoaded] = useGetData('created', eventTypes, clientId, startDate, endDate);
  const [completedData, completedDataLoading, completedDataLoaded] = useGetData('completed', eventTypes, clientId, startDate, endDate);
  const [publicFormsData, publicFormsDataLoading, publicFormsDataLoaded] = useGetData('publicFormSubmitted', eventTypes, clientId, startDate, endDate);
  const [transferData, transferDataLoading, transferDataLoaded] = useGetData('transfered', eventTypes, clientId, startDate, endDate);

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'line',
        options: {
          legend: { display: useLegend },
          tooltips: {
            intersect: false,
            callbacks: {
              labelColor: (tooltipItem, data) => {
                const tooltipColor = data.data.datasets[tooltipItem.datasetIndex].backgroundColor;
                return {
                  borderColor: tooltipColor,
                  backgroundColor: tooltipColor,
                };
              },
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'day',
                },
              },
            ],
          },
        },
      });
    }

    chart.current.data.labels = [];
    chart.current.data.datasets = [];

    addDataset('created', createdDataLoaded, createdData, 'rgba(109, 184, 100, 1)');
    addDataset('completed', completedDataLoaded, completedData, 'rgba(54, 162, 235, 1)');
    addDataset('publicFormSubmitted', publicFormsDataLoaded, publicFormsData, 'rgba(245, 155, 54, 1)');
    addDataset('transfered', transferDataLoaded, transferData, 'rgba(218, 220, 53, 1)');

    chart.current.data.datasets.sort((a, b) => {
      if (b.eventType === 'created') return 1;
      if (b.eventType === 'completed' && a.eventType !== 'created') return 1;
      if (b.eventType === 'publicFormSubmitted' && a.eventType !== 'completed' && a.eventType !== 'created') return 1;
      if (b.eventType === 'transfered' && a.eventType !== 'publicFormSubmitted' && a.eventType !== 'completed' && a.eventType !== 'created') return 1;
      return -1;
    });

    chart.current.update();
  }, [createdData, createdDataLoaded, completedData, completedDataLoaded, publicFormsData, publicFormsDataLoaded, transferData, transferDataLoaded]);

  const addDataset = (eventType, loaded, data, color) => {
    if (!loaded) return;
    chart.current.data.labels = [...chart.current.data.labels, ...data.dates.map((x) => x.date)].distinct();
    chart.current.data.datasets = [
      ...chart.current.data.datasets,
      {
        eventType: eventType,
        label: getLabelForEventType(eventType),
        data: data.dates.sort((a, b) => (a.date < b.date ? -1 : 1)).map((x) => x.count),
        pointRadius: 0,
        fill: false,
        lineTension: 0,
        borderWidth: 2,
        backgroundColor: color,
        borderColor: color,
      },
    ];
  };

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <Loader isLoading={completedDataLoading || createdDataLoading || publicFormsDataLoading || transferDataLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}

const useGetData = (eventType, eventTypes, clientId, startDate, endDate) => {
  return useApim<TypeMePlease>(
    `/metrics/forms/events/clients/${clientId}?eventType=${eventType}&startDate=${startDate}&endDate=${endDate}`,
    clientId,
    null,
    !eventTypes.find((x) => x === eventType)
  );
};
