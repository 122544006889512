import './AzureDevOpsReleases.scss';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import { AzureDevOpsRelease } from './types';
import { AzureDevOpsReleaseTable } from './AzureDevOpsReleaseTable';
import { Tab, TabPane, TabProps } from 'semantic-ui-react';
import { useLocation, useNavigate } from 'react-router-dom';


export function AzureDevOpsReleases() {
  const [releases, releasesLoading, releasesLoaded, refreshReleases, ,releasesError] = useApi<AzureDevOpsRelease[]>('/api/azuredevops/releases');
  const [delayRefreshLoader, setDelayRefreshLoader] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();
  const pagePath = location.pathname.split('/').pop();
  
  useEffect(() => {
    if (releasesError) {
      window.location.href = `/azuredevops/ensureloggedin?url=${window.location.href}`;
    }
  }, [releasesError]);
  
  useEffect(() => { 
    const interval = setInterval(() => { 
      refreshReleases();
    }, 60 * 1000)

    return () => { 
      clearInterval(interval);
    }
  }, [])

  if (releasesLoading || !releasesLoaded || delayRefreshLoader) {
    return <Loader isLoading />;
  }

  const invokeRefreshAfterDelay = () => { 
    setDelayRefreshLoader(true);
    setTimeout(() => {
      refreshReleases();
      setDelayRefreshLoader(false);
    }, 2000);
  }

  const appReleases = releases
    .filter(release => release?.releaseName?.endsWith('-app'))
    .sort((a, b) => a.releaseName.localeCompare(b.releaseName));

  const mobileReleases = releases
    .filter(release => release?.releaseName?.endsWith('App v4'))
    .sort((a, b) => a.releaseName.localeCompare(b.releaseName));

  const internalReleases = releases
    .filter(release => ['Salesforce Synchronizer', 'FileScan Service', 'EventHub Processor'].some(name => name === release?.releaseName))
    .sort((a, b) => a.releaseName.localeCompare(b.releaseName));
    
  const infrastructureReleasesNames = ['APIM'];
  const infrastructureReleases = releases
    .filter(release => infrastructureReleasesNames.includes(release?.releaseName ?? ''))
    .sort((a, b) => a.releaseName.localeCompare(b.releaseName));
  
  const otherReleases = releases.filter(release => 
    !appReleases.includes(release)
    && !infrastructureReleases.includes(release)
    && !mobileReleases.includes(release)
    && !internalReleases.includes(release)
  ).sort((a, b) => a.releaseName.localeCompare(b.releaseName));

  const servicesReleases = [...infrastructureReleases, ...otherReleases];

  const panes = [
    { menuItem: 'Services', render: () => 
      <TabPane attached={false}>
        <AzureDevOpsReleaseTable releases={servicesReleases} refreshReleases={invokeRefreshAfterDelay} isDevToProd={false}/>
      </TabPane> 
    },
    { menuItem: 'Apps', render: () => 
      <TabPane attached={false}>
          <AzureDevOpsReleaseTable releases={appReleases} refreshReleases={invokeRefreshAfterDelay} isDevToProd={false}/>
      </TabPane> 
    },
    { menuItem: 'Interal Services', render: () => 
    <TabPane attached={false}>
        <AzureDevOpsReleaseTable releases={internalReleases} refreshReleases={invokeRefreshAfterDelay} isDevToProd={true}/>
    </TabPane> 
  },
    { menuItem: 'Mobile', render: () => 
      <TabPane attached={false}>
          <AzureDevOpsReleaseTable releases={mobileReleases} refreshReleases={invokeRefreshAfterDelay} isDevToProd={true}/>
      </TabPane> 
    },
  ];

  const handleTabChange = (_event: React.MouseEvent<HTMLDivElement>, data: TabProps)  => { 
    console.log(data.activeIndex);
    navigate(`/releasemanagement/releases/${panes[data.activeIndex].menuItem.toLowerCase()}`, { replace: true });
  }

  const indexOfSelectedMenuItem = panes.map(pane => pane.menuItem.toLowerCase()).indexOf(pagePath?.toLowerCase() || '');
  const defaultActiveIndex = indexOfSelectedMenuItem < 0 ? 0 : indexOfSelectedMenuItem;
  return (
    <div className="azure-devops-releases">
      <Helmet>
        <title>Azure DevOps Releases</title>
      </Helmet>

      <Tab defaultActiveIndex={defaultActiveIndex} onTabChange={handleTabChange} menu={{ borderless: true, attached: false, tabular: false }} panes={panes} />
    </div>
  );
}
