import { getProfile } from '../context/ProfileContext';

export const Groups = {
  Developers: '9e3bef21-174b-4ad0-b3af-57e714f1dc58',
  CustomerSuccess: '8ca1288d-858b-4aa6-88f6-0e8e83a2591a',
  Sales: '846fd698-9e25-449b-aa0d-e1fb70b7837a',
  Marketing: '1ed481c4-bb24-4b11-bc40-806340e011aa',
  TechSupport: 'e19b5e78-6f87-4e50-b719-fabedf699eb1',
  ProductOwners: 'bb9ba866-c5fd-46b0-87c1-504e1b73f6b0',
  ProServices: '6e220f75-2497-4588-98e2-e803bcc6d73d',
  Financial: '936a86df-46c5-4f25-897d-f4810d8f328d',
  HumanResources: '92c4622c-8647-4364-a98a-6e3fd509b908',
  RecurlyUsers: '12e18b54-f82e-4b7f-96c9-75f666f907f3',
  QA: '9a58dc5b-67d1-4f86-a148-4fefd4ae502a'
};

export const AppFeatures = {
  EditFeatureFlags: 'EditFeatureFlags',
  EditAccountControls: 'EditAccontControls',
  EditAllAccountSettings: 'EditAllAccountSettings',
  EditFinancialAccountSettings: 'EditFinancialAccountSettings',
  EditApps: 'EditApps',
  EditBillingEmail: 'EditBillingEmail',
  EditSubscriptions: 'EditSubscriptions',
  ReleaseManagement: 'ReleaseManagement',
};

export function canIUse(feature) {
  const profile = getProfile();
  const groupIds = profile.groupIds;

  switch (feature) {
    case AppFeatures.EditFeatureFlags:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.QA]);
    case AppFeatures.EditAccountControls:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.QA]);
    case AppFeatures.EditAllAccountSettings:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.QA]);
    case AppFeatures.EditFinancialAccountSettings:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.Financial, Groups.QA]);
    case AppFeatures.EditApps:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.QA]);
    case AppFeatures.EditBillingEmail:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.RecurlyUsers, Groups.QA]);
    case AppFeatures.EditSubscriptions:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.ProductOwners, Groups.QA]);
    case AppFeatures.ReleaseManagement:
      return hasGroupMatch(groupIds, [Groups.Developers, Groups.QA]);
  }

  return false;
}

function hasGroupMatch(profileGroupIds, allowedGroupIds) {
  return allowedGroupIds.some((x) => profileGroupIds.indexOf(x) > -1);
}
