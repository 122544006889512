import React, { useState } from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import { useMessages } from '../../context/MessagesContext';
import { useApi } from '../../hooks/useApi';
import { apiCall } from '../../utility/api';
import Loader from '../Loader';
import { AddOnsAddRow } from './AddOnsAddRow';
import { AddOnsSaveTableFooter } from './AddOnsSaveTableFooter';
import './AddOnsSubscriptionConfigurationTable.scss';
import { AddOnsSubscriptionConfigurationTableHeader } from './AddOnsSubscriptionConfigurationTableHeader';
import { AddOnsSubscriptionConfigurationTableRow } from './AddOnsSubscriptionConfigurationTableRow';
import { AddOnConfigurationDto, AddOnSubscriptionConfigurationDto } from './types';

export type AddOnsSubscriptionConfigurationTableProps = {
  readOnly: boolean;
  subscription?: string;
}

export function AddOnsSubscriptionConfigurationTable(props: AddOnsSubscriptionConfigurationTableProps) {
  const { readOnly, subscription } = props;
  const { showMessage } = useMessages();

  //global add ons
  const [addOnConfigurations, addOnConfigurationsLoading, _addOnConfigurationsLoaded, refreshAddOnConfigurations] = useApi<AddOnConfigurationDto[]>('api/addons/configurations');
  const addOnConfigurationsOrDefault = (addOnConfigurations || []);

  //subscriptions configurations 
  const [addOnSubscriptionConfigurations, addOnSubscriptionConfigurationsLoading, _addOnSubscriptionConfigurationsLoaded, refreshAddOnSubscriptionConfigurations] = useApi<AddOnSubscriptionConfigurationDto[]>('api/addons/configurations/subscriptions');
  const addOnSubscriptionConfigurationsOrDefault = (addOnSubscriptionConfigurations || []).filter(addOnSusbcriptionConfiguration => addOnSusbcriptionConfiguration.subscription === subscription);

  const [addOnSubscriptionConfigurationChanges, setAddOnSubscriptionConfigurationChanges] = useState<AddOnSubscriptionConfigurationDto[]>([]);
  const [newAddOnSubscriptionConfiguration, setNewAddOnSubscriptionConfiguration] = useState<AddOnSubscriptionConfigurationDto>(null);
  const [addOnSubscriptionConfigurationDeletions, setAddOnSubscriptionConfigurationDeletions] = useState<AddOnSubscriptionConfigurationDto[]>([]);
  
  const [comment, setComment] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);

  const addOnSubscriptionConfigurationsToDisplay = addOnSubscriptionConfigurationsOrDefault
    .filter((addOnSubscriptionConfiguration) => !addOnSubscriptionConfigurationDeletions.some((deletion) => deletion.addOnConfigurationId === addOnSubscriptionConfiguration.addOnConfigurationId))
    .map((addOnSubscriptionConfiguration) => 
      addOnSubscriptionConfigurationChanges.find((change) => change.subscription === addOnSubscriptionConfiguration.subscription) || addOnSubscriptionConfiguration);
  
  const unOverridenAddOns = addOnConfigurationsOrDefault.filter((addOn) => 
    !addOnSubscriptionConfigurationsToDisplay.some((x) => x.addOnConfigurationId === addOn.addOnConfigurationId));
  const nextAddOnOverride = unOverridenAddOns.length > 0 ? unOverridenAddOns[0] : null;
  
  const onAddOnSubscriptionConfigurationChange = (addOnSubscriptionConfiguration: AddOnSubscriptionConfigurationDto) => {
    const index = addOnSubscriptionConfigurationChanges.findIndex((x) => x.addOnConfigurationId === addOnSubscriptionConfiguration.addOnConfigurationId);
    const newChanges = [...addOnSubscriptionConfigurationChanges];
    if(index > -1 ) { 
      newChanges[index] = addOnSubscriptionConfiguration;
    } else { 
      newChanges.push(addOnSubscriptionConfiguration);
    }

    setAddOnSubscriptionConfigurationChanges(newChanges);
  }

  const onAddOnSubscriptionConfigurationDelete = (addOnSubscriptionConfiguration: AddOnSubscriptionConfigurationDto) => { 
    setAddOnSubscriptionConfigurationDeletions([...addOnSubscriptionConfigurationDeletions, addOnSubscriptionConfiguration]);
  }

  const onNewAddOnSubscriptionConfigurationChange = (addOnSubscriptionConfiguration: AddOnSubscriptionConfigurationDto) => {
    //check if the add-on was previously marked as deleted
    const isAddOnPreviouslyDeleted = addOnSubscriptionConfigurationDeletions.some((deletion) => deletion.addOnConfigurationId === addOnSubscriptionConfiguration.addOnConfigurationId);
    if(isAddOnPreviouslyDeleted && addOnSubscriptionConfigurationDeletions?.length > 0) { 
      setAddOnSubscriptionConfigurationDeletions(addOnSubscriptionConfigurationDeletions.filter((deletion) => deletion.addOnConfigurationId !== addOnSubscriptionConfiguration.addOnConfigurationId));
    }
    setNewAddOnSubscriptionConfiguration(addOnSubscriptionConfiguration);
  }

  const onNewAddOnSubscriptionConfigurationDelete = () => { 
    setNewAddOnSubscriptionConfiguration(null);
  }

  const onAddNewAddOnSubscriptionConfigurationClick = () => { 
    setNewAddOnSubscriptionConfiguration({
      subscription: props.subscription,
      addOnConfigurationId: nextAddOnOverride.addOnConfigurationId,
      pricingType: nextAddOnOverride.pricingType,
      monthlyPrice: nextAddOnOverride.monthlyPrice,
      yearlyPrice: nextAddOnOverride.yearlyPrice,
      userLimit: nextAddOnOverride.userLimit,
    });
  }

  const onSaveClick = async () => { 
    if(readOnly) {
      return;
    }
    setIsSaving(true);
    
    try {
      const result = await apiCall(`/api/addons/configurations/subscriptions`, 'POST', {
        upserts: [...addOnSubscriptionConfigurationChanges, newAddOnSubscriptionConfiguration].filter(obj => !!obj),
        deletions: addOnSubscriptionConfigurationDeletions,
        comment: comment
      });

      if(result.status !== 200) { 
        showMessage('Failed to save the subscriptions add-on configuration changes');
        return;
      }
    } catch(e) { 
      console.log(e);
      showMessage('Failed to save the subscriptions add-on configuration changes');
    }
      
    setIsSaving(false);
    setAddOnSubscriptionConfigurationChanges([]);
    setAddOnSubscriptionConfigurationDeletions([]);
    setNewAddOnSubscriptionConfiguration(null) 

    refreshAddOnConfigurations();
    refreshAddOnSubscriptionConfigurations();
  }

  const canSave = () => { 
    if(readOnly) { 
      return false;
    }
    
    if(comment == null || comment === '') { 
      return false;
    }

    if(!addOnSubscriptionConfigurationChanges.length && !newAddOnSubscriptionConfiguration && !addOnSubscriptionConfigurationDeletions) {
      return false;
    }

    const changes = [...addOnSubscriptionConfigurationChanges, newAddOnSubscriptionConfiguration].filter(obj => !!obj);
    const changesMissingRequiredProps = changes.some(change => { 
      return change.addOnConfigurationId == null || 
      change.pricingType == null ||
      change.monthlyPrice == null || 
      change.yearlyPrice == null
    });

    if(changesMissingRequiredProps) { 
      return false;
    }

    return true;
  }

  const isLoading = isSaving || addOnConfigurationsLoading || addOnSubscriptionConfigurationsLoading;
  const addButtonDisabled = nextAddOnOverride == null || readOnly;
  const saveButtonDisabled = !canSave();
  const isAddOnsLoaded = _addOnSubscriptionConfigurationsLoaded && _addOnConfigurationsLoaded;

  const columnCount = 7;
  return (
    <div className='add-ons-subscription-configuration-table'>
      <Loader isLoading={isLoading} />
      <Table singleLine>
        <AddOnsSubscriptionConfigurationTableHeader/>
        <TableBody className='add-ons-subscription-configuration-table-body'>
          {isAddOnsLoaded && addOnSubscriptionConfigurationsToDisplay.map((addOnSubscriptionConfiguration) => (
            <AddOnsSubscriptionConfigurationTableRow 
              addOnSubscriptionConfiguration={addOnSubscriptionConfiguration}
              addOns={[...unOverridenAddOns, addOnConfigurations.find(x => x.addOnConfigurationId === addOnSubscriptionConfiguration.addOnConfigurationId)]}
              key={`subscription-configurations-${addOnSubscriptionConfiguration?.addOnConfigurationId}`} 
              readOnly={readOnly} 
              onChange={onAddOnSubscriptionConfigurationChange}
              onDelete={onAddOnSubscriptionConfigurationDelete} />
          ))}
          {!newAddOnSubscriptionConfiguration && !props.readOnly &&  (
            <AddOnsAddRow colSpan={columnCount}
              text="Add Subscription Override"
              onClick={onAddNewAddOnSubscriptionConfigurationClick} 
              disabled={addButtonDisabled} />
          )}
          {newAddOnSubscriptionConfiguration && 
            <AddOnsSubscriptionConfigurationTableRow 
              addOnSubscriptionConfiguration={newAddOnSubscriptionConfiguration}
              addOns={unOverridenAddOns}
              readOnly={readOnly} 
              onChange={onNewAddOnSubscriptionConfigurationChange}
              onDelete={onNewAddOnSubscriptionConfigurationDelete} />
          }
        </TableBody>
        {!props.readOnly && 
          <AddOnsSaveTableFooter colSpan={columnCount} readOnly={readOnly} disableSave={saveButtonDisabled} comment={comment} onCommentChange={setComment} onSaveClick={onSaveClick} />
        }
      </Table>
    </div>
  );
}
