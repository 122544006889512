import React, { useLayoutEffect, useRef } from 'react';
import moment from 'moment';
import { useApi } from '../../hooks/useApi';
import Loader from '../Loader';
import Chart from 'chart.js';

import './Charts.scss';

export type ChangeLogChartProps = {
  id: string;
  title?: string;
  width?: number;
  height?: number;
};

export function ChangeLogChart(props: ChangeLogChartProps) {
  const { id } = props;
  const canvasEl = useRef<TypeMePlease>(null);
  const chart = useRef<TypeMePlease>(null);

  const [changeLog, changeLogLoading, changeLogLoaded] = useApi<TypeMePlease>(`/api/account/${id}/changelog?limit=5000`);

  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasEl.current, {
        type: 'line',
        options: {
          scales: {
            xAxes: [
              {
                type: 'time',
                distribution: 'series',
                offset: true,
                ticks: {
                  major: {
                    enabled: true,
                    fontStyle: 'bold',
                  },
                  source: 'data',
                  autoSkip: true,
                  autoSkipPadding: 75,
                  maxRotation: 0,
                  sampleSize: 100,
                },
              },
            ],
          },
        },
      });
    }

    chart.current.data.datasets = [];

    if (changeLogLoaded) {
      const reversedChangeLog = changeLog.reverse();
      chart.current.data.datasets = [
        {
          label: 'Net ACV',
          data: reversedChangeLog.map((x) => {
            return { t: moment.utc(x.timestamp).valueOf(), y: x.price * x.licenseCount * 12 };
          }),
        },
      ];
    }

    chart.current.update();
  }, [changeLogLoaded, changeLog]);

  return (
    <div className="chart" style={{ width: `${props.width || 500}px`, height: `${props.height || 300}px` }}>
      <Loader isLoading={changeLogLoading} />
      {props.title ? <div className="title">{props.title}</div> : ''}
      <canvas key="canvas" ref={canvasEl}></canvas>
    </div>
  );
}
