import React from 'react';
import { Button } from 'semantic-ui-react';
import './UndoAddOnDeleteButton.scss';


export type UndoAddOnDeleteButtonProps = { 
  onClick: () => void;
}

export function UndoAddOnDeleteButton(props: UndoAddOnDeleteButtonProps) {

  return <Button className='undo-add-on-delete-button transparent' title="Undo delete" circular color="red" icon='undo' onClick={props.onClick} />
}