import React from 'react';
import { useProfile } from '../context/ProfileContext';
import './NavMenu.scss';
import { canIUse, AppFeatures } from '../utility/SecurityHelper';
import { Link } from 'react-router-dom';
import { SuggestedSearchInput } from './search/SuggestedSearchInput';

export function NavMenu() {
  const { profile } = useProfile();

  const handleSearch = (term: string, condition: string) => {
    window.location.href = `/search?condition=${condition}&query=${encodeURIComponent(term)}`;
  };

  return (
    <div className="nav-menu ui blue inverted borderless menu top">
      <a href="/" className="item fitted center aligned" style={{ width: '60px' }}>
        <svg width="32" height="36" viewBox="0 0 98 120" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '14px' }}>
          <g fill="none" fillRule="evenodd">
            <path
              d="M0 6.99238C0 3.1306 3.1359 0 7.0086 0h83.9828C94.86216 0 98 3.1327 98 6.99238v106.01524C98 116.8694 94.8641 120 90.9914 120H7.0086C3.13784 120 0 116.8673 0 113.00762V6.99238zM45 113c0-2.20914 1.79536-4 4-4 2.20914 0 4 1.79536 4 4 0 2.20914-1.79536 4-4 4-2.20914 0-4-1.79536-4-4zM12 12h75v94H12V12z"
              fill="#FFF"
            />
            <path d="M25.50004 75.58596l16.36116 19.9943 30.0769-41.13297" stroke="#FFF" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.48 40h47.12808M25.48 26h47.12808" stroke="#FFF" strokeWidth="6" strokeLinecap="round" />
          </g>
        </svg>
      </a>

      <div className="ui simple dropdown item">
        <Link to="/">Dashboard</Link>
        <div className="menu">
          <Link className="item" to="/">
            Dashboard
          </Link>
          <Link className="item" to="/dashboard/metrics">
            Metrics
          </Link>
        </div>
      </div>
      <div className="ui simple dropdown item">
        Accounts
        <div className="menu">
          <a className="item" href="/Account/ChangeLog">
            Change Log
          </a>
          <a className="item" href="/Account/PendingChanges">
            Pending Changes
          </a>
          <a className="item" href="/Account/Recent">
            Recent Signups
          </a>
          <a className="item" href="/Account/SpecialAccess">
            Special Access
          </a>
          <a className="item" href="/Account/SpecialAccessLogins">
            Special Access Logins
          </a>
          <a className="item" href="/Account/CreditAuditLog">
            Public Forms Credits Log
          </a>
          <a className="item" href="/proservicelogs">
            Pro Services Log
          </a>
          <a className="item" href="/AccountsSearch">
            Accounts Search
          </a>
        </div>
      </div>
      <div className="ui simple dropdown item">
        Tools
        <div className="menu">
          <a className="item" href="/TemplateCopy">
            Template Copy
          </a>
          <a className="item" href="/DatasourceCopy">
            Datasource Copy
          </a>
          <a className="item" href="/Tools/WorkflowCopy">
            Workflow Copy
          </a>
          <a className="item" href="/PromoCode">
            Promo Codes
          </a>
          {canIUse(AppFeatures.EditFeatureFlags) ? (
            <a className="item" href="/featureflags">
              Feature Flags
            </a>
          ) : null}
          {canIUse(AppFeatures.EditFeatureFlags) ? (
            <a className="item" href="/subscriptions">
              Subscriptions
            </a>
          ) : null}
          {canIUse(AppFeatures.EditFeatureFlags) ? (
            <a className="item" href="/independentuser/merge">
              Merge Independent Users
            </a>
          ) : null}
        </div>
      </div>
      <div className="ui simple dropdown item">
        Connected Apps
        <div className="menu">
          <a className="item" href="/apps">
            Apps
          </a>
        </div>
      </div>
      <div className="ui simple dropdown item">
        SSO
        <div className="menu">
          <a className="item" href="/SsoIdp/">
            Identity Providers
          </a>
        </div>
      </div>
      <div className="ui simple dropdown item">
        Release Management
        <div className="menu">
          {canIUse(AppFeatures.ReleaseManagement) && ( 
            <a className="item" href="/releasemanagement/releases">
              Releases
            </a>
          )}
          <a className="item" href="/releasemanagement/tickets">
            Tickets
          </a>
        </div>
      </div>
      <div className="right menu">
        <div className="item">
          <SuggestedSearchInput onSearch={handleSearch} />
        </div>
        <div className="ui simple dropdown item">
          <img src={`https://www.gravatar.com/avatar/${profile.usernameHash}`} className="ui avatar image" alt="" />
          <div className="menu">
            <div className="item">{profile.username}</div>
            <a className="item" href="/Logout">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
