import React from 'react';
import './ActionMenu.scss';
import ActionMenuItem from './ActionMenuItem';
import { ActionMenuProps } from './actionMenu-types';

export function ActionMenu({ iconClass = "ellipsis vertical icon", options }: ActionMenuProps) {
  return (
    <div className="ui simple dropdown item gf-action-menu">
      <i className={iconClass}></i>
      <div className="open-left menu">
        {options?.map((option, index) => (
          <ActionMenuItem key={index} {...option} />
        ))
        }
      </div>
    </div>  
  );
}