import React, { useState } from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import { useMessages } from '../../context/MessagesContext';
import { useApi, useApim } from '../../hooks/useApi';
import { apiCall } from '../../utility/api';
import Loader from '../Loader';
import { FeatureFlag } from '../featureflags/types';
import { AddOnsAddRow } from './AddOnsAddRow';
import './AddOnsConfigurationTable.scss';
import { AddOnsConfigurationTableHeader } from './AddOnsConfigurationTableHeader';
import { AddOnsConfigurationTableRow } from './AddOnsConfigurationTableRow';
import { AddOnsSaveTableFooter } from './AddOnsSaveTableFooter';
import { AddOnConfigurationDto, AddOnPricingType } from './types';

export type AddOnsConfigurationTableProps = {
  readOnly: boolean;
}

export function AddOnsConfigurationTable(props: AddOnsConfigurationTableProps) {
  const { readOnly } = props;
  const { showMessage } = useMessages();
  //global add ons
  const [addOnConfigurations, addOnConfigurationsLoading, _addOnConfigurationsLoaded, refreshAddOnConfigurations] = useApi<AddOnConfigurationDto[]>('api/addons/configurations');
  const [featureFlags, featureFlagsLoading, _featureFlagsLoaded, refreshFeatureFlags] =  useApi<FeatureFlag[]>('/api/featureflags');

  const addOnConfigurationsOrDefault = addOnConfigurations || [];
  const [newAddOn, setNewAddOn] = useState<AddOnConfigurationDto>(null);
  const [comment, setComment] = useState<string>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [addOnConfigurationChanges, setAddOnConfigurationChanges] = useState<AddOnConfigurationDto[]>([]);

  const onAddOnChange = (addOn: AddOnConfigurationDto) => {
    const index = addOnConfigurationChanges.findIndex((x) => x.addOnConfigurationId === addOn.addOnConfigurationId);
    const newChanges = [...addOnConfigurationChanges];
    if(index > -1 ) { 
      newChanges[index] = addOn;
    } else { 
      newChanges.push(addOn);
    }

    setAddOnConfigurationChanges(newChanges);
  }

  const onNewAddOnChange = (addOn: AddOnConfigurationDto) => {
    setNewAddOn(addOn);
  }

  const onAddNewAddOnConfigurationClick = () => { 
      setNewAddOn({
        pricingType: AddOnPricingType.FixedPrice,
        monthlyPrice: 10,
        yearlyPrice: 120,
        userLimit: 10,
        userCanUnsubscribe: true,
      });
  }

  const onSaveClick = async () => {
    if(readOnly) {
      return;
    } 
    setIsSaving(true);
     
    try {
      const result = await apiCall(`/api/addons/configurations`, 'POST', {
        upserts: [...addOnConfigurationChanges, newAddOn].filter(obj => !!obj),
        comment: comment
      });

      if(result.status !== 200) { 
        showMessage('Failed to save the add-on configuration changes');
        return;
      }
    } catch(e) { 
      console.log(e);
      showMessage('Failed to save the add-on configuration changes');
    }
      
    setIsSaving(false);
    setAddOnConfigurationChanges([]);
    setNewAddOn(null) 
    refreshAddOnConfigurations();
  }

  const canSave = () => { 
    if(readOnly) { 
      return false;
    }
    if(comment == null || comment === '') { 
      return false;
    }
    if(!addOnConfigurationChanges.length && !newAddOn) {
      return false;
    }

    const changes = [...addOnConfigurationChanges, newAddOn].filter(obj => !!obj);
    const changesMissingRequiredProps = changes.some(change => { 
      return change.code == null || 
      change.pricingType == null || 
      change.title == null || 
      change.description == null || 
      change.monthlyPrice == null || 
      change.yearlyPrice == null
    });

    if(changesMissingRequiredProps) { 
      return false;
    }
    return true;
  }

  const isLoading = isSaving || addOnConfigurationsLoading;

  const saveButtonDisabled = !canSave();
  const addOnConfigurationsToDisplay = addOnConfigurationsOrDefault.map((addOn) => addOnConfigurationChanges.find((change) => change.addOnConfigurationId === addOn.addOnConfigurationId) || addOn);
  const columnCount = 10;

  return (
    <div className='add-ons-configuration-table'>
      <Loader isLoading={isLoading} />
      <Table singleLine>
        <AddOnsConfigurationTableHeader/>
        <TableBody className='add-ons-configuration-table-body'>
          {addOnConfigurationsToDisplay.map((addOnConfiguration) => (
            <AddOnsConfigurationTableRow 
              addOnConfiguration={addOnConfiguration}
              featureFlags={featureFlags}
              key={addOnConfiguration?.addOnConfigurationId}
              readOnly={readOnly} 
              onChange={onAddOnChange} />
          ))}
          {!newAddOn && !props.readOnly && (
            <AddOnsAddRow colSpan={columnCount}
              text="Add AddOn"
              onClick={onAddNewAddOnConfigurationClick} 
              disabled={readOnly} />
          )}
          {newAddOn && 
            <AddOnsConfigurationTableRow 
              addOnConfiguration={newAddOn}
              featureFlags={featureFlags}
              readOnly={readOnly}
              onChange={onNewAddOnChange} />}
        </TableBody>
        {!props.readOnly && 
          <AddOnsSaveTableFooter colSpan={columnCount} readOnly={readOnly} disableSave={saveButtonDisabled} comment={comment} onCommentChange={setComment} onSaveClick={onSaveClick} />
        }
      </Table>
    </div>
  );
}
