import React from 'react';
import { UserDevice } from '../uses-types';

export type DeviceTableProps = {
  devices: UserDevice[];
};

type DeviceRowProps = {
  device: UserDevice;
};

export function DeviceTable({ devices }: DeviceTableProps) {
  return (
    <table className="device-table ui celled striped table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Device Type</th>
          <th>Model</th>
          <th>Platform</th>
          <th>OS Version</th>
          <th>App Version</th>
          <th>Last Sync Time</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {devices.map((device) => (
          <DeviceRow key={device.id} device={device} />
        ))}
      </tbody>
    </table>
  );
}

function DeviceRow({ device }: DeviceRowProps) {
  const { deviceName, type, model, platform, osVersion, appVersion, lastSyncTime, isActive } = device;
  const color = isActive == 'True' ? 'positive' : 'negative';

  return (
    <tr className={`device-row ${color}`}>
      <td>{deviceName}</td>
      <td>{type}</td>
      <td>{model}</td>
      <td>{platform}</td>
      <td>{osVersion}</td>
      <td>{appVersion}</td>
      <td>{lastSyncTime}</td>
      <td>{isActive}</td>
    </tr>
  );
}
