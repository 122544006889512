import React from 'react';
import { Helmet } from 'react-helmet';
import { ChangeLogChart } from '../charts/ChangeLogChart';
import { Module } from '../Module';

export type ChangeLogProps = {
  id: string;
  account: TypeMePlease;
};

export function ChangeLog(props: ChangeLogProps) {
  const { id, account } = props;

  return (
    <div className="change-log">
      <Helmet>
        <title>{account.name} - Change Log</title>
      </Helmet>
      <Module title="Change Log">
        <ChangeLogChart id={id} width={700} height={350} />
      </Module>
    </div>
  );
}
