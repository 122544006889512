import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import { Module } from '../Module';
import moment from 'moment';
import { copyTextToClipboard } from '../../utility/clipboard';

import './ParentAccount.scss';

type ParentAccount = {
  id: string;
  name: string;
  clients: Client[];
};

export type Client = {
  clientId: string;
  name: string;
  trialEndDate?: string;
  subscriptionCode: string;
  acv: number;
  promoCode: string;
  lastUpdatedDate: string;
  settings: string;
  createdDate?: string;
  deletedDate?: string;
  companySize: string;
};

export function ParentAccount() {
  const { id } = useParams();
  const [results, resultsLoading, resultsLoaded] = useApim<ParentAccount>(`/accounts/parents/${id}`);

  if (resultsLoading) {
    return <Loader isLoading />;
  }

  const totalClients = results?.clients?.length ?? 0;

  let totalACV = 0;
  for (const client of results?.clients) {
    totalACV += client.acv;
  }

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  return (
    <div className="parent-account">
      <Helmet>
        <title>{results.name}</title>
      </Helmet>
      <div className="account-info">
        <h2 className="company-name">Parent Account Name: {results.name}</h2>
        <div className="clientId">
          Parent Account Id: {results.id}&nbsp;
          <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(results.id)}>
            <i className="copy outline icon"></i>
          </button>
        </div>
        <div className="stats">
          <div className="stat">
            <i className="ui icon user" />
            <div className="title">Clients</div>
            <div className="value">{totalClients}</div>
          </div>
          <div className="stat">
            <i className="ui icon money bill alternate outline" />
            <div className="title">Total ACV</div>
            <div className="value">{totalACV}</div>
          </div>
        </div>
      </div>
      <div className="modules">
        <Module title="Clients" className="details-module">
          <table className="ui very basic collapsing celled table">
            <thead>
              <tr>
                <th>ClientId</th>
                <th>Name</th>
                <th>Display Name</th>
                <th>Company Size</th>
                <th>ACV</th>
                <th>Promo Code</th>
                <th>Subscription</th>
                <th>Signup Date</th>
                <th>Deleted Date</th>
                <th>Trial End Date</th>
              </tr>
            </thead>
            <tbody>
              {resultsLoaded
                ? results.clients.map((client: Client) => (
                    <tr>
                      <td>
                        <a href={`/account/${client.clientId}`}>{client.clientId}</a>&nbsp;
                        <button className="ui compact mini icon button" onClick={() => copyTextToClipboard(client.clientId)}>
                          <i className="copy outline icon"></i>
                        </button>
                      </td>
                      <td>
                        <a href={`/account/${client.clientId}`}>{client.name}</a>
                      </td>
                      <td>{JSON.parse(client.settings).DisplayName}</td>
                      <td>{client.companySize}</td>
                      <td>{client.acv}</td>
                      <td>{client.promoCode}</td>
                      <td>{client.subscriptionCode}</td>
                      <td>{client.createdDate ? moment.utc(client.createdDate).local().calendar(null, calendarOptions) : null}</td>
                      <td>{client.deletedDate ? moment.utc(client.deletedDate).local().calendar(null, calendarOptions) : null}</td>
                      <td>{client.trialEndDate ? moment.utc(client.trialEndDate).local().calendar(null, calendarOptions) : null}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Module>
      </div>
    </div>
  );
}
