import React from 'react';
import { useApim } from '../../hooks/useApi';
import Loader from '../Loader';
import moment from 'moment';
import { Helmet } from 'react-helmet';

export type DevicesProps = {
  id: string;
  account: TypeMePlease;
};

export default function Devices(props: DevicesProps) {
  const { id, account } = props;
  const [deviceResult, devicesLoading, devicesLoaded] = useApim<TypeMePlease>('/devices/list', id, null, false, 'POST', {
    skip: 0,
    take: 1000,
  });

  const calendarOptions = { sameElse: 'MM/DD/YYYY h:mm:ss a' };

  return (
    <div className="devices">
      <Helmet>
        <title>{account.name} - Devices</title>
      </Helmet>

      <table className="ui table">
        <thead>
          <tr>
            <th>Device Name</th>
            <th>User Id</th>
            <th>Device Type</th>
            <th>Model</th>
            <th>Platform</th>
            <th>App Version</th>
            <th>Last Sync Time</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {devicesLoaded
            ? deviceResult.devices.map((device) => {
                return (
                  <tr>
                    <td>{device.name}</td>
                    <td>{device.userId}</td>
                    {(() => {
                      switch (device.deviceType) {
                        case 0:
                          return <td>Phone</td>;
                        case 1:
                          return <td>Tablet</td>;
                        default:
                          return <td>Unknown</td>;
                      }
                    })()}
                    <td>{device.model}</td>
                    <td>{device.platform}</td>
                    <td>{device.appVersion}</td>
                    <td>{moment.utc(device.lastSyncTime).local().calendar(null, calendarOptions)}</td>
                    <td>{device.isActive.toString()}</td>
                  </tr>
                );
              })
            : ''}
        </tbody>
      </table>
      <Loader isLoading={devicesLoading} />
    </div>
  );
}
