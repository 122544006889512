import React from 'react';
import { Select } from 'semantic-ui-react';
import { getFriendlyPermissionLevelName } from '../../account/connectedapps/helpers';
import { AppItemPermissionLevel } from '../apps-types.d';

export interface CommandPermissionDropdownProps {
  commandName: string;
  value: string;
  onPermissionChange: (key: string, value: any) => void;
}

export default function CommandPermissionDropdown(props: CommandPermissionDropdownProps) {
  const options = [
    {
      key: AppItemPermissionLevel.Trial,
      value: AppItemPermissionLevel.Trial,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Trial),
    },
    {
      key: AppItemPermissionLevel.Team,
      value: AppItemPermissionLevel.Team,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Team),
    },
    {
      key: AppItemPermissionLevel.Advanced,
      value: AppItemPermissionLevel.Advanced,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Advanced),
    },
    {
      key: AppItemPermissionLevel.Enterprise,
      value: AppItemPermissionLevel.Enterprise,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Enterprise),
    },
    {
      key: AppItemPermissionLevel.AddOn,
      value: AppItemPermissionLevel.AddOn,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.AddOn),
    },
    {
      key: AppItemPermissionLevel.Unlisted,
      value: AppItemPermissionLevel.Unlisted,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Unlisted),
    },
    {
      key: AppItemPermissionLevel.Deprecated,
      value: AppItemPermissionLevel.Deprecated,
      text: getFriendlyPermissionLevelName(AppItemPermissionLevel.Deprecated),
    },
  ];

  return <Select onChange={(e, d) => props.onPermissionChange(props.commandName, d.value)} value={props.value} options={options} />;
}
