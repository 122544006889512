import { useState } from 'react';
import { SubscriptionChangeTimeframe } from './types';

const useCancelConfirmModal = () => {
  const [isCancelConfirmModalOpen, setIsCancelConfirmModalOpen] = useState(false);
  const [cancelAt, setCancelAt] = useState(null);

  const openModal = (cancelTime) => {
    setCancelAt(cancelTime);
    setIsCancelConfirmModalOpen(true);
  };

  const closeModal = () => {
    setIsCancelConfirmModalOpen(false);
  };

  const onCancelNow = () => { 
    setCancelAt(SubscriptionChangeTimeframe.Now);
    setIsCancelConfirmModalOpen(true);
  }

  const onCancelAtRenewal = () => { 
    setCancelAt(SubscriptionChangeTimeframe.Renewal);
    setIsCancelConfirmModalOpen(true);
  }

  const onCloseCancelConfirmModal = () => {
    setIsCancelConfirmModalOpen(false)
  }

  return {
    isCancelConfirmModalOpen,
    cancelAt,
    openModal,
    closeModal,
    onCancelNow,
    onCancelAtRenewal,
    onCloseCancelConfirmModal,
  };
};

export default useCancelConfirmModal;