import React from 'react';
import momentTimezone from 'moment-timezone';

export type TimezoneDropdownProps = {
  value: string;
  disabled: boolean;
  onChange: (item: string) => void;
};

export default function TimezoneDropdown(props: TimezoneDropdownProps) {
  return (
    <select
      className="ui fluid dropdown"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif" }}
      disabled={props.disabled}
    >
      <option key="" value=""></option>
      {momentTimezone.tz.names().map((tz) => (
        <option key={tz} value={tz}>
          {tz}
        </option>
      ))}
    </select>
  );
}
